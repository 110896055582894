import { Progress as AntProgress, Spin, Col, Form } from "antd";
import Button from "atoms/Button";
import Table from "atoms/Table";
import Title from "atoms/Title";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import COLORS from "common/Colors";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import { useQueryState } from "hooks/useQueryState";
import { useScrollFetch } from "hooks/useScrollFetch";
import { useScrollFetch2 } from "hooks/useScrollFetch2";
import { find, sumBy, uniqBy, some, includes } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getUserList, getUserInfo, getLoadingRoute } from "redux/selectors";
import styled from "styled-components";
import {
  setTempEngagementFormData,
  setselectedengagementtab,
} from "redux/actions";
import { importantPeople } from "common/Constants";

import { useVT } from 'virtualizedtableforantd4';


const EngagementWrapper = styled.div`
  .title {
    display: flex;
    justify-content: space-between;
  }

  .search-box {
    max-width: 250px;
    margin-bottom: 8px;
  }

  .green-dot {
    height: 15px;
    content: "";
    width: 22px;
    background-color: #4caf50;
    border-radius: 9999px;
    display: inline-block;
  }
  .table-row {
    cursor: pointer;
  }

  .custom-filter-btn {
    margin-bottom: 0px !important;
  }

  .pagination-loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Progress = styled(AntProgress)`
  .ant-progress-inner {
    border-radius: 5px !important;
  }
  .ant-progress-bg {
    height: 16px !important;
    border-radius: 5px !important;
    position: relative;
    transition: none !important;  /* Disable the animation */
    
    &::before {
      content: ${({ percentContent }) => percentContent};
      /* content: "66.66%"; */
      position: absolute;
      top: -4px;
      padding-left: 5px;
    }
  }
`;

const StyledLoader = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Engagement = () => {
  const userList = useSelector((state) => getUserList(state));
  const userInfo = useSelector((state) => getUserInfo(state));
  const loadingRoute = useSelector((state) => getLoadingRoute(state));

  const [ vt, set_components ] = useVT(() => ({ scroll: { y: 600 } }), []);


  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "employee",
      className: "normal-column ",
      width: 180,
      render: (val) => {
        return <span className="text-primary">{val}</span>;
      },
      onCell: (record) => ({
        onClick: () => history.push(`/engagement/${record.engagementId}`),
        style: { cursor: "pointer" },
      }),
    },
    {
      title: "Client Name",
      dataIndex: "clientname",
      key: "clientname",
      className: "normal-column",
      width: 150,
    },
    // {
    //   title: "Overall",
    //   dataIndex: "overall",
    //   key: "overall",
    //   className: "normal-column",
    //   width: 85,
    //   align: "center",
    // },
    {
      title: "%CPL",
      dataIndex: "cpl",
      key: "cpl",
      className: "normal-column",
      width: 150,
      ellipsis: {
        showTitle: true,
      },
      align: "center",
    },
    {
      title: "Partner",
      dataIndex: "partner",
      key: "partner",
      className: "normal-column",
      width: 150,
    },
    {
      title: "Person In Charge",
      dataIndex: "manager",
      key: "manager",
      className: "normal-column",
      render: (val) => {
        return <span>{find(userList, { userid: val })?.fullname || "-"}</span>;
      },
      width: 150,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      className: "normal-column",
      width: 150,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      className: "normal-column",
      width: 120,
      render: (val) => {
        return <span className="text-primary">{val}</span>;
      },
      onCell: (record) => ({
        onClick: () =>
          history.push(`/engagement/${record.engagementId}/financialsummary`),
        style: { cursor: "pointer" },
      }),
    },
    // {
    //   title: "Created By",
    //   dataIndex: "createdBy",
    //   key: "createdBy",
    //   className: "normal-column",
    //   width: 150,
    // },
    {
      title: "Plan Start Date",
      dataIndex: "planstartdate",
      key: "planenddate",
      className: "normal-column",
      width: 150,
    },
    {
      title: "Plan End Date",
      dataIndex: "planenddate",
      key: "planenddate",
      className: "normal-column",
      width: 150,
    },
    // {
    //   title: "Date Updated",
    //   dataIndex: "dateupdated",
    //   key: "dateupdated",
    //   className: "normal-column",
    //   width: 140,
    // },
    // {
    //   title: "Upcoming Activities",
    //   dataIndex: "upcomingactivities",
    //   key: "upcomingactivities",
    //   className: "normal-column",
    //   width: 160,
    // },
    // {
    //   title: "Date Approved",
    //   dataIndex: "dateapproved",
    //   key: "dateapproved",
    //   className: "normal-column",
    //   width: 150,
    // },
    {
      title: "Engagement Status",
      dataIndex: "engagementStatus",
      key: "engagementStatus",
      className: "normal-column",
      width: 150,
    },
    {
      title: "PFA Status",
      dataIndex: "pfaStatus",
      key: "pfaStatus",
      className: "normal-column",
      width: 150,
    },
  ];

  const engagementStatusItems = [
    {
      id: 1,
      value: "Not Approved",
    },
    {
      id: 2,
      value: "Not Started",
    },
    {
      id: 3,
      value: "Active",
    },
    {
      id: 4,
      value: "Closed",
    },
  ];

  const pfaStatusItems = [
    {
      id: 1,
      value: "Not Applicable",
    },
    {
      id: 2,
      value: "Approved",
    },
    {
      id: 3,
      value: "Declined",
    },
  ];

  const history = useHistory();

  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  // Period, Staff Level, Organisation Level, Competency, Status

  const filteruser = userList?.filter(
    (item) => item?.userid === userInfo.userid
  );

  const { getEngagements } = queryKeys;

  const queryParams = new URLSearchParams();
  queryParams.append("managingoffice", filteruser[0]?.managingoffice);
  queryParams.append("userId", filteruser[0]?.userid);
  queryParams.append(
    "countryManagingPartner",
    filteruser[0]?.countryManagingPartner === "yes" ? "yes" : "no"
  );

  const queryString = queryParams.toString();

  const { key, url } = getEngagements(queryString);

  const { data, isLoading, isReloading, fetchNextPage, hasNextPage } = useScrollFetch2(
    key,
    {
      endPoint: url,
      queryParam: "LastEvaluatedKey",
    }
  );

  const engagements = useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      if (page?.data === undefined) return acc;
      return [...acc, ...page?.data];
    }, []);
  }, [data]);

  const [filteredEngagementList, setFilteredEngagementList] = useState([]);

  const { getAllClientFiltered, getEngagementCount } = queryKeys;

  const { isFetched: isClientsFetched } = useQueryState(
    getAllClientFiltered?.key
  );

  const { data: clientDataRes } = useFetch(
    getAllClientFiltered?.key,
    getAllClientFiltered?.url,
    {
      enabled: !isClientsFetched,
    }
  );

  const clients = clientDataRes?.data;

  const { isFetched: isCountFetched } = useQueryState(
    getEngagementCount?.key
  );

  const { data: engagementCountRes } = useFetch(
    getEngagementCount?.key,
    getEngagementCount?.url,
    {
      enabled: !isCountFetched,
    }
  );

  const count = engagementCountRes?.response;

  function capitalizeWords(string) {
    if (!string) return "-";
    return string
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join("-");
  }

  const engagementList = useMemo(() => {
    if (!engagements || !clients) {
      return [];
    }
    return engagements?.map((item) => {
      const percentage =
        (parseInt(item?.actualMandays) /
          (sumBy(item?.employeeList, "personDay") * 8)) *
        100;
      const formattedPercentage = percentage ? percentage.toFixed(2) : "0.00";

      const actualPercentage = formattedPercentage !== "Infinity" ? formattedPercentage : "0.00"

      const client = clients.find((client) => client.bdo_id === item.clientId);

      const partnerName = userList.find(
        (user) => user.userid === item.partner
      )?.fullname;

      const createdByName = userList.find(
        (user) => user.userid === item.createdBy
      )?.fullname;
      

      return {
        engagementId: item.engagementId,
        title: item.title || "-",
        clientname: item?.clientName || "-",
        overall: <div className="green-dot"></div>,
        cpl: (
          <Progress
            percent={actualPercentage}
            percentContent={`"${actualPercentage}"`}
            size={[300, 20]}
            strokeColor={COLORS.PRIMARY}
            showInfo={false}
          />
        ),
        manager: item.manager || "-",
        partner: partnerName || "-",
        country: item.country,
        type: item.type,
        planstartdate: item.planStartDate,
        planenddate: item.planEndDate,
        dateupdated: item.updatedDate || "-",
        upcomingactivities: item.upcomingactivities || "-",
        dateapproved: item.approvedDate,
        engagementStatus: item.engagementStatus,
        pfaStatus: item.pfaStatus,
        createBy: createdByName || "-",
      };
    });
  }, [engagements, clients]);

  useEffect(() => {
    dispatch(setTempEngagementFormData());
    dispatch(setselectedengagementtab());
  }, [engagementList]);

  useEffect(() => {
    if (loadingRoute) {
      if (search) {
        const filteredList = engagementList.filter((item) => {
          return item.title.toLowerCase().includes(search.toLowerCase());
        });
        setFilteredEngagementList(filteredList);
      } else {
        setFilteredEngagementList(engagementList);
      }
    }
  }, [loadingRoute, search, engagementList]);
  const [form] = Form.useForm();

  const selectedCountry = form?.getFieldValue('country') || [];
  const selectedStatus = form?.getFieldValue("engagementStatus") || [];
  const countryCount = selectedCountry?.length > 0
  ? count
      ?.filter(item => selectedCountry.includes(item?.countryname))
      ?.reduce((sum, item) => {
        let statusSum = 0;
        if (selectedStatus?.length > 0) {
          if (selectedStatus.includes('Active')) {
            statusSum += item?.active || 0;
          }
          if (selectedStatus.includes('Not Started')) {
            statusSum += item?.notstarted || 0;
          }
          if (selectedStatus.includes('Closed')) {
            statusSum += item?.closed || 0;
          }
          if (selectedStatus.includes('Not Approved')) {
            statusSum += item?.notapproved || 0;
          }
        } else {
          statusSum += (item?.active || 0) + (item?.notstarted || 0) + (item?.closed || 0);
        }
        return sum + statusSum;
      }, 0)
  : selectedStatus?.length > 0
    ? count?.reduce((sum, item) => {
        let statusSum = 0;
        if (selectedStatus.includes('Active')) {
          statusSum += item?.active || 0;
        }
        if (selectedStatus.includes('Not Started')) {
          statusSum += item?.notstarted || 0;
        }
        if (selectedStatus.includes('Closed')) {
          statusSum += item?.closed || 0;
        }
        if (selectedStatus.includes('Not Approved')) {
          statusSum += item?.notapproved || 0;
        }
        return sum + statusSum;
      }, 0)
    : count?.reduce(
        (sum, item) =>
          sum + (item?.active || 0) + (item?.notstarted || 0) + (item?.closed || 0) +  (item?.notapproved || 0),
        0
      );


  const getFilteredData = () => {
    const name = form.getFieldValue("title") || [];
    const manager = form.getFieldValue("manager") || [];
    const clientname = form.getFieldValue("clientname") || [];
    const country = form.getFieldValue("country") || [];
    const engagementStatus = form.getFieldValue("engagementStatus") || [];
    const pfaStatus = form.getFieldValue("pfaStatus") || [];

    if (
      name.length > 0 ||
      manager.length > 0 ||
      clientname.length > 0 ||
      country.length > 0 ||
      engagementStatus.length > 0 ||
      pfaStatus.length > 0
    ) {
      return engagementList?.filter((item) => {
        const nameMatch =
          name && name.length > 0 ? name.some((n) => item.title === n) : true;

        const managerMatch =
          manager && manager.length > 0 ? manager.includes(item.manager) : true;
        const clientNameMatch =
          clientname && clientname.length > 0
            ? clientname.includes(item?.clientname)
            : true;

        const countryMatch =
          country && country.length > 0
            ? country.includes(item?.country)
            : true;

        const convertToLowerCase = (item) => item.toLowerCase();

        const statusMatch =
          engagementStatus && engagementStatus.length > 0
            ? engagementStatus
                .map(convertToLowerCase)
                .includes(item?.engagementStatus?.toLowerCase())
            : true;

        const pfaStatusMatch =
          pfaStatus && pfaStatus.length > 0
            ? pfaStatus.includes(item?.pfaStatus)
            : true;

        return (
          nameMatch &&
          managerMatch &&
          clientNameMatch &&
          countryMatch &&
          statusMatch &&
          pfaStatusMatch
        );
      });
    } else {
      return engagementList;
    }
  };

  const filteredData = getFilteredData()

  useEffect(()=>{ //? Temporary Fix for Paginated API not calling when there is no data
    if (filteredData.length === 0 && hasNextPage && !isReloading && countryCount !== 0) fetchNextPage()
  }, [filteredData, fetchNextPage, hasNextPage, isReloading, countryCount])

  if (loadingRoute) {
    return (
      <StyledLoader>
        <div>
          <Spin size="large" />
        </div>
      </StyledLoader>
    );
  }


  return (
    <EngagementWrapper>
      <div className="title">
        <Title>Engagement </Title>
        <div>
          <Button
            type="primary"
            onClick={() => {
              history.push("/engagementCalendar");
            }}
          >
            Calendar View
          </Button>
          <Button
            type="primary"
            onClick={() => {
              history.push("/add-engagement");
            }}
          >
            Add New
          </Button>
        </div>
      </div>

      {/* <Input
        placeholder="Search"
        className="search-box"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        suffix={<SearchOutlined />}
      /> */}
      <Row>
        <Col sm={24}>
          <Form
            form={form}
            layout="vertical"
            className="mt-2"
            onValuesChange={() => form.submit()}
          >
            <Row gutter={12} className="g-3 mb-2">
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="title"
                  label="Title"
                  options={uniqBy(
                    engagementList?.map((item) => {
                      return { label: item?.title, value: item?.title };
                    }),
                    "label"
                  )}
                  form={form}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="clientname"
                  label="Client Name"
                  options={uniqBy(
                    engagementList?.map((item) => {
                      return {
                        label: item?.clientname,
                        value: item?.clientname,
                      };
                    }),
                    "value"
                  )}
                  form={form}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="manager"
                  label="Person In Charge"
                  options={uniqBy(
                    engagementList?.map((item) => {
                      return {
                        label: find(userList, { userid: item?.manager })
                          ?.fullname,
                        value: item?.manager,
                      };
                    }),
                    "value"
                  )}
                  form={form}
                />
              </Col>

              {/* {filteruser && filteruser[0]?.OfficeSupervisorId === "none" && ( */}
              {filteruser &&
                importantPeople.includes(filteruser[0]?.userid) && (
                  <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                    <DropdownCheckBox
                      name="country"
                      label="Country"
                      options={uniqBy(
                        engagementList?.map((item) => {
                          return { label: item?.country, value: item?.country };
                        }),
                        "value"
                      )}
                      form={form}
                    />
                  </Col>
                )}

              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="engagementStatus"
                  label="Engagement Status"
                  options={uniqBy(
                    engagementStatusItems?.map((item) => {
                      return { label: item?.value, value: item?.value };
                    }),
                    "value"
                  )}
                  form={form}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="pfaStatus"
                  label="PFA Status"
                  options={uniqBy(
                    pfaStatusItems?.map((item) => {
                      return { label: item?.value, value: item?.value };
                    }),
                    "value"
                  )}
                  form={form}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <div className="people_font_style mt-2">
          Engagement Count: {filteredData?.length}
      </div>

      <Spin spinning={isLoading}>
        <Table
          dataSource={filteredData}
          columns={columns}
          scroll={{ y: 400 }}
          components={vt}
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="task_id"
          pagination={false}
          onRow={(record, index) => {
          // console.log("index-->", index)
          // console.log("filteredData-->", filteredData)

            if (index === filteredData.length - 1 && hasNextPage && !isReloading) {
              fetchNextPage()
            }
            
          }}
        />
      </Spin>
      {!isLoading && (
        <div className="pagination-loader">
          <Spin spinning={isReloading} />
        </div>
      )}
    </EngagementWrapper>
  );
};

export default Engagement;