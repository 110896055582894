export const currencyAmount = (currency, amount) => {
  if (isNaN(amount)) {
    return `${currency}0`;
  }
  
  const roundedAmount = Math.round(amount);
  return `${currency}${roundedAmount.toLocaleString("en-US")}`;
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const allowOnlyNumbers = (e) => {
  if (
    !(
      (
        (e.keyCode > 95 && e.keyCode < 106) || // Numpad numbers
        (e.keyCode > 47 && e.keyCode < 58) || // Numbers
        e.keyCode === 8 || // Backspace
        (e.keyCode >= 37 && e.keyCode <= 40)
      ) // Arrow keys
    )
  ) {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
  }
};