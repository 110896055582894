import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Tabs,
  Upload,
  message,
  Switch,
} from "antd";
import {
  CompetencyRole,
  Grade,
  Industry,
  IndustryRole,
  NON_EMPTY_REGEX,
  STATUS_CODE,
  Sector,
  countryList,
  AwardList,
  secondaryIndustry,
  ContractingCompany,
  Greetings,
  RegionalCometencyRole,
} from "common/Constants";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import styled from "@emotion/styled";
import {
  getBandList,
  getClientid,
  getLocations,
  getRoleList,
  getSupervisorList,
  getUserList,
} from "redux/selectors";
import { connect } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { CALL_API, CALL_FEATCH_API } from "common/API";
import { setUserList } from "redux/actions";
import { bindActionCreators } from "redux";
import { chain, flatMap, isEmpty, filter, find, concat, get } from "lodash";
import TabPane from "antd/lib/tabs/TabPane";
import FormModal from "./FormModal";
import SelectTable from "../molecules/EditEmployeeRole";
import COLORS from "common/Colors";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { allowOnlyNumbers } from "utils/functions";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
`;

const StyleRow = styled(Row)`
  padding: 10px;
  box-shadow: 0px 3px 6px #00000015;
  .ant-form-item-label {
    text-align: left;
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background: #f2f2f2;
    }
    /* .anticon svg {
    color: #397FF4;
} */
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 40px;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }

  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #02b209 !important;
      border-color: #02b209 !important;
    }
  }
`;
const EditProfileBox = styled.div`
  .ant-tabs-nav {
    padding-left: 10px;
    border-radius: 5px;
    font-weight: 500;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .ant-tabs-tab {
    font-size: 16px;
    &:hover {
      color: ${COLORS.TEXT.PRIMARY};
    }
  }
  .ant-tabs-ink-bar {
    background: ${COLORS.TAB};
    font-weight: bold;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.TAB};
    font-weight: 500;
    font-size: 16px;
  }
`;

function generateUniqueId() {
  // Generate a random number and convert it to base 36 (numbers + letters)
  const randomNumber = Math.random().toString(36).substr(2, 9);

  // Get the current timestamp
  const timestamp = Date.now();

  // Combine the random number with the timestamp
  const uniqueId = randomNumber + timestamp;

  return uniqueId;
}

function EditProfile({
  updateState,
  setUpdateState,
  EmployeeDetails,
  userList,
  roleList,
  ClientId,
  bandList,
  updateUserList,
  locationList,
  pageName,
  supervisorList,
}) {
  const containerRef = useRef(null);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});

  const [showLoader, toggleLoader] = useState(false);
  const [employeeStatusId, setEmployeeStatusId] = useState("1");

  const employeesList = userList?.map((user) => user.userid);
  const [activeTab, setActiveTab] = useState("0");
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [baseLocation, setBaseLocation] = useState("");
  const [selectAllRoleChecked, setSelectAllRoleChecked] = useState(false);

  const filteredUserName = userList
    ?.filter((item) => item.roleid.includes("4"))
    ?.map((item) => item.fullname);

  const filteredLocation = locationList?.filter(
    (item) => item.login_from_anywhere !== 1
  );

  useEffect(() => {
    const {
      firstname,
      lastname,
      salutation,
      othernames,
      dateofbirth,
      gender,
      dependent,
      maritalstatus,
      phoneno,
      userid,
      postaladdress,
      physicaladdress,
      password,
      country,
      greetings,
      national_id,
      nationality,
      workpermitno,
      workpermitexpirydate,
      emergencycontactname,
      emergencycontactnumber,
      nextofkinrelationship,
      nextofkinpostaladdress,
      educationdetails,
      practicaltrainings,
      professionalcertifications,
      workexperience,
      accountname,
      accountno,
      bankname,
      bankbranch,
      bankcountry,
      bankcode,
      bankbranchcode,
      bankswiftcode,
      nssf,
      krapin,
      nhif,
      helpmonthlybilling,
      managingoffice,
      competencyQC,
      serviceline,
      subserviceline,
      leveldepartmentname,
      competencyrole,
      levellocationid,
      oversight,
      supervisor,
      companyname,
      industry,
      secondaryindustry,
      sector,
      expertise,
      industryrole,
      regionalsupervisor,
      regionalcompetencyrole,
      officesupervisor,
      engagementsupervisor,
      designation,
      grade,
      targetchargeability,
      chargeoutrate,
      lastpromotionlevel,
      lastpromotion,
      jobtitle,
      professionalsince,
      hireddate,
      employeesponser,
      employee_status,
      roleid,
      baselocationid,
      workinghoursfrom,
      workinghoursto,
      profile_url,
      employeeid,
      OfficeSupervisorId,
      RegionalSupervisorId,
      statusname,
      shiftstarttime,
      shiftendtime,
      grossSalary,
      housingAllowance,
      medicalInsurance,
      pension,
      groupLifeInsurance,
      socialSecurityContribution,
      bonus,
      otherCosts,
      totalEmploymentCost,
    } = EmployeeDetails;

    console.log("EmployeeDetails--->", EmployeeDetails);

    setFormValues({
      full_name: `${firstname} ${lastname}`,
      password: password,
      user_id: userid,
      role_id: roleid,
      // role_name: roleNames,
      start_date: hireddate ? moment(hireddate, "YYYY-MM-DD") : null,
      gender: gender,
      status_id: employeeStatusId,
      profile_url: profile_url,
      nssf: nssf,
      national_id: national_id,
      phoneno: phoneno,
      physicaladdress: physicaladdress,
      professionalsince: professionalsince
        ? moment(professionalsince, "YYYY-MM-DD")
        : null,
      hireddate: hireddate ? moment(hireddate, "YYYY-MM-DD") : null,
      employee_status: statusname,
      employeesponser: employeesponser,
      // age: agecalculte,
      maritalstatus: maritalstatus,
      country: country,
      nationality: nationality,
      companyname: companyname,
      jobtitle: jobtitle,
      dateofbirth: dateofbirth ? moment(dateofbirth, "YYYY-MM-DD") : null,
      emergencycontactname: emergencycontactname,
      emergencycontactnumber: emergencycontactnumber,
      baselocationid: baselocationid,
      employeeid: employeeid,
      firstname: firstname,
      lastname: lastname,
      salutation: salutation,
      competencyQC: competencyQC,
      serviceline: serviceline,
      subserviceline: subserviceline,
      competencyrole: competencyrole,
      industry: industry,
      sector: sector,
      industryrole: industryrole,
      expertise: expertise,
      designation: designation,
      grade: grade,
      targetchargeability: targetchargeability,
      chargeoutrate: chargeoutrate,
      greetings: greetings,
      lastpromotionlevel: lastpromotionlevel
        ? moment(lastpromotionlevel, "YYYY-MM-DD")
        : null,
      dependent: dependent,
      managingoffice: managingoffice,
      supervisor: supervisor,
      lastpromotion: lastpromotion,
      levellocationid: levellocationid,
      oversight: oversight ?? false,
      secondaryindustry: secondaryindustry,
      leveldepartmentname: leveldepartmentname,
      regionalsupervisor:
        regionalsupervisor !== "none"
          ? get(
              find(supervisorList, { employeeId: RegionalSupervisorId }),
              "name",
              null
            )
          : "none",
      officesupervisor:
        officesupervisor !== "none"
          ? get(
              find(supervisorList, { employeeId: OfficeSupervisorId }),
              "name",
              null
            )
          : "none",
      engagementsupervisor: get(
        find(supervisorList, { name: engagementsupervisor }),
        "name",
        null
      ),
      OfficeSupervisorId:
        officesupervisor !== "none"
          ? get(
              find(supervisorList, { employeeId: OfficeSupervisorId }),
              "employeeId",
              null
            )
          : "none",
      RegionalSupervisorId:
        regionalsupervisor !== "none"
          ? get(
              find(supervisorList, { employeeId: RegionalSupervisorId }),
              "employeeId",
              null
            )
          : "none",
      othernames: othernames,
      postaladdress: postaladdress,
      workpermitno: workpermitno,
      workpermitexpirydate: workpermitexpirydate
        ? moment(workpermitexpirydate, "YYYY-MM-DD")
        : null,
      nextofkinrelationship: nextofkinrelationship,
      nextofkinpostaladdress: nextofkinpostaladdress,
      accountno: accountno,
      accountname: accountname,
      bankname: bankname,
      bankbranch: bankbranch,
      bankcountry: bankcountry,
      bankcode: bankcode,
      bankbranchcode: bankbranchcode,
      bankswiftcode: bankswiftcode,
      krapin: krapin,
      nhif: nhif,
      helpmonthlybilling: helpmonthlybilling,
      shiftstarttime: shiftstarttime,
      shiftendtime: shiftendtime,
      regionalcompetencyrole: regionalcompetencyrole,
      educationdetails:
        educationdetails?.length !== 0
          ? educationdetails
          : [
              {
                institutename: "",
                fromdate: "",
                todate: "",
                exambody: "",
                awarded: "",
              },
            ],
      workexperience:
        workexperience?.length !== 0
          ? workexperience
          : [
              {
                companyname: "",
                fromdate: "",
                todate: "",
                jobtitle: "",
                relevnt: "",
                jobdescription: "",
              },
            ],
      professionalcertifications:
        professionalcertifications?.length !== 0
          ? professionalcertifications
          : [
              {
                certificationtype: "",
                fromdate: "",
                todate: "",
                remarks: "",
              },
            ],
      practicaltrainings:
        practicaltrainings.length !== 0
          ? practicaltrainings
          : [
              {
                organisationname: "",
                fromdate: "",
                todate: "",
                positionheld: "",
                tasksperformed: "",
              },
            ],
      grossSalary,
      housingAllowance,
      medicalInsurance,
      pension,
      groupLifeInsurance,
      socialSecurityContribution,
      bonus,
      otherCosts,
      totalEmploymentCost,
    });
    const transformedDetails = (data) =>
      data?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = fromdate ? moment(fromdate) : null;
        const formattedToDate = todate ? moment(todate) : null;
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });
    form.setFieldsValue({
      full_name: `${firstname} ${lastname}`,
      password: password,
      user_id: userid,
      role_id: roleid,
      // role_name: roleNames,
      start_date: hireddate ? moment(hireddate, "YYYY-MM-DD") : null,
      gender: gender,
      status_id: employeeStatusId,
      profile_url: profile_url,
      nssf: nssf,
      national_id: national_id,
      phoneno: phoneno,
      physicaladdress: physicaladdress,
      professionalsince: professionalsince
        ? moment(professionalsince, "YYYY-MM-DD")
        : null,
      hireddate: hireddate ? moment(hireddate, "YYYY-MM-DD") : null,
      employee_status: statusname,
      employeesponser: employeesponser,
      // age: agecalculte,
      maritalstatus: maritalstatus,
      country: country,
      nationality: nationality,
      companyname: companyname,
      jobtitle: jobtitle,
      dateofbirth: dateofbirth ? moment(dateofbirth, "YYYY-MM-DD") : null,
      emergencycontactname: emergencycontactname,
      emergencycontactnumber: emergencycontactnumber,
      baselocationid: baselocationid,
      employeeid: employeeid,
      firstname: firstname,
      lastname: lastname,
      salutation: salutation,
      competencyQC: competencyQC,
      serviceline: serviceline,
      subserviceline: subserviceline,
      competencyrole: competencyrole,
      industry: industry,
      sector: sector,
      industryrole: industryrole,
      expertise: expertise,
      designation: designation,
      grade: grade,
      targetchargeability: targetchargeability,
      chargeoutrate: chargeoutrate,
      greetings: greetings,
      lastpromotionlevel: lastpromotionlevel
        ? moment(lastpromotionlevel, "YYYY-MM-DD")
        : null,
      dependent: dependent,
      managingoffice: managingoffice,
      supervisor: get(
        find(supervisorList, { employeeId: supervisor }),
        "name",
        null
      ),
      lastpromotion: lastpromotion,
      levellocationid: levellocationid,
      oversight: oversight,
      secondaryindustry: secondaryindustry,
      leveldepartmentname: leveldepartmentname,
      regionalsupervisor:
        regionalsupervisor !== "none"
          ? get(
              find(supervisorList, { employeeId: RegionalSupervisorId }),
              "employeeId",
              null
            )
          : "none",
      officesupervisor:
        officesupervisor !== "none"
          ? get(
              find(supervisorList, { employeeId: OfficeSupervisorId }),
              "employeeId",
              null
            )
          : "none",
      engagementsupervisor: get(
        find(supervisorList, { name: engagementsupervisor }),
        "employeeId",
        null
      ),
      OfficeSupervisorId:
        officesupervisor !== "none"
          ? get(
              find(supervisorList, { employeeId: OfficeSupervisorId }),
              "employeeId",
              null
            )
          : "none",
      RegionalSupervisorId:
        regionalsupervisor !== "none"
          ? get(
              find(supervisorList, { employeeId: RegionalSupervisorId }),
              "employeeId",
              null
            )
          : "none",
      othernames: othernames,
      postaladdress: postaladdress,
      workpermitno: workpermitno,
      workpermitexpirydate: workpermitexpirydate
        ? moment(workpermitexpirydate, "YYYY-MM-DD")
        : null,
      nextofkinrelationship: nextofkinrelationship,
      nextofkinpostaladdress: nextofkinpostaladdress,
      accountno: accountno,
      accountname: accountname,
      bankname: bankname,
      bankbranch: bankbranch,
      bankcountry: bankcountry,
      bankcode: bankcode,
      bankbranchcode: bankbranchcode,
      bankswiftcode: bankswiftcode,
      krapin: krapin,
      nhif: nhif,
      helpmonthlybilling: helpmonthlybilling,
      // workinghoursfrom: moment.utc(workinghoursfrom).format("hh:mm:ss"),
      // workinghoursto: moment.utc(workinghoursto).format("hh:mm:ss"),
      regionalcompetencyrole: regionalcompetencyrole,
      educationdetails:
        educationdetails?.length !== 0
          ? transformedDetails(educationdetails)
          : [
              {
                institutename: "",
                fromdate: "",
                todate: "",
                exambody: "",
                awarded: "",
              },
            ],
      workexperience:
        workexperience?.length !== 0
          ? transformedDetails(workexperience)
          : [
              {
                companyname: "",
                fromdate: "",
                todate: "",
                jobtitle: "",
                relevnt: "",
                jobdescription: "",
              },
            ],
      professionalcertifications:
        professionalcertifications?.length !== 0
          ? transformedDetails(professionalcertifications)
          : [
              {
                certificationtype: "",
                fromdate: "",
                todate: "",
                remarks: "",
              },
            ],
      practicaltrainings:
        practicaltrainings.length !== 0
          ? transformedDetails(practicaltrainings)
          : [
              {
                organisationname: "",
                fromdate: "",
                todate: "",
                positionheld: "",
                tasksperformed: "",
              },
            ],
      grossSalary,
      housingAllowance,
      medicalInsurance,
      pension,
      groupLifeInsurance,
      socialSecurityContribution,
      bonus,
      otherCosts,
      totalEmploymentCost,
    });
  }, [EmployeeDetails]);

  const validateEmployeeId = (rule, value) => {
    if (EmployeeDetails?.userid === value) {
      return Promise.resolve();
    } else if (value && employeesList.includes(value)) {
      return Promise.reject("User ID already exist");
    }
    return Promise.resolve();
  };

  const onFinish = () => {
    if (updateState === "salaryInfo") {
      calculateTotal();
    }
    const currentFormValues = form.getFieldValue();
    const updatedFormValues = {
      ...currentFormValues,
      selectedRoleId,
    };

    setFormValues(updatedFormValues);
  };

  const uploadFile = async (value) => {
    const { file } = value;
    try {
      if (!isEmpty(file)) {
        await CALL_API(`upload-userdocuments/${ClientId}`, "post", {
          userId: EmployeeDetails?.userid,
          name: file.name,
          type: file.type,
          uniqueId: file.response.uniqueId,
          content: file.response.base64Data,
        });
      }
    } catch (error) {
      console.error("[handlesubmit] error --->", error);
    }
  };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      updateUserList(userList);
    }
  };

  const handlesubmit = async () => {
    try {
      toggleLoader(true);
      const {
        firstname,
        lastname,
        salutation,
        othernames,
        dateofbirth,
        gender,
        dependent,
        maritalstatus,
        phoneno,
        user_id,
        postaladdress,
        physicaladdress,
        country,
        greetings,
        national_id,
        nationality,
        workpermitno,
        workpermitexpirydate,
        emergencycontactname,
        emergencycontactnumber,
        nextofkinrelationship,
        nextofkinpostaladdress,
        educationdetails,
        practicaltrainings,
        professionalcertifications,
        workexperience,
        accountname,
        accountno,
        bankname,
        bankbranch,
        bankcountry,
        bankcode,
        bankbranchcode,
        bankswiftcode,
        nssf,
        krapin,
        nhif,
        helpmonthlybilling,
        managingoffice,
        competencyQC,
        serviceline,
        subserviceline,
        leveldepartmentname,
        competencyrole,
        levellocationid,
        oversight,
        supervisor,
        companyname,
        industry,
        secondaryIndustry,
        sector,
        expertise,
        industryrole,
        regionalsupervisor,
        regionalcompetencyrole,
        officesupervisor,
        engagementsupervisor,
        designation,
        grade,
        targetchargeability,
        chargeoutrate,
        lastpromotionlevel,
        lastpromotion,
        jobtitle,
        professionalsince,
        hireddate,
        employeesponser,
        employee_status,
        role_id,
        baselocationid,
        employeeid,
        grossSalary,
        housingAllowance,
        medicalInsurance,
        pension,
        groupLifeInsurance,
        socialSecurityContribution,
        bonus,
        otherCosts,
      } = formValues;

      const totalEmploymentCost = form.getFieldValue("totalEmploymentCost");

      const workexperienceDetails = workexperience
        ?.filter((item) => {
          const {
            companyname,
            fromdate,
            todate,
            jobtitle,
            relevnt,
            jobdescription,
          } = item;
          return (
            companyname?.trim() !== "" ||
            companyname === null ||
            companyname === undefined ||
            fromdate !== "" ||
            fromdate === null ||
            fromdate === undefined ||
            todate !== "" ||
            todate === null ||
            todate === undefined ||
            jobtitle?.trim() !== "" ||
            jobtitle === null ||
            jobtitle === undefined ||
            relevnt?.trim() !== "" ||
            relevnt === null ||
            relevnt === undefined ||
            jobdescription?.trim() !== "" ||
            jobdescription === null ||
            jobdescription === undefined
          );
        })
        .map((item) => {
          const { fromdate, todate, ...rest } = item;
          const formattedFromDate = fromdate
            ? moment(fromdate).format("YYYY-MM-DD")
            : null;
          const formattedToDate = todate
            ? moment(todate).format("YYYY-MM-DD")
            : null;
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
          };
        });

      const transformedEducationDetails = educationdetails
        ?.filter((item) => {
          const { institutename, fromdate, todate, exambody, awarded } = item;
          return (
            institutename?.trim() !== "" ||
            institutename === null ||
            institutename === undefined ||
            fromdate !== "" ||
            fromdate === null ||
            fromdate === undefined ||
            todate !== "" ||
            todate === null ||
            todate === undefined ||
            exambody?.trim() !== "" ||
            exambody === null ||
            exambody === undefined ||
            awarded?.trim() !== "" ||
            awarded === null ||
            awarded === undefined
          );
        })
        .map((item) => {
          const { attachment, fromdate, todate, ...rest } = item;
          const formattedFromDate = fromdate
            ? moment(fromdate).format("YYYY-MM-DD")
            : null;
          const formattedToDate = todate
            ? moment(todate).format("YYYY-MM-DD")
            : null;
          const updatedAttachment = attachment?.map((att) => ({
            name: att?.name,
            uniqueId: att?.response?.uniqueId
              ? att?.response?.uniqueId
              : att?.uniqueId,
          }));
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
            attachment: updatedAttachment,
          };
        });

      const practicaltrainingsDetails = practicaltrainings
        ?.filter((item) => {
          const {
            organisationname,
            fromdate,
            todate,
            positionheld,
            tasksperformed,
          } = item;
          return (
            organisationname?.trim() !== "" ||
            organisationname === null ||
            organisationname === undefined ||
            fromdate !== "" ||
            fromdate === null ||
            fromdate === undefined ||
            todate !== "" ||
            todate === null ||
            todate === undefined ||
            positionheld?.trim() !== "" ||
            positionheld === null ||
            positionheld === undefined ||
            tasksperformed?.trim() !== "" ||
            tasksperformed === null ||
            tasksperformed === undefined
          );
        })
        .map((item) => {
          const { fromdate, todate, ...rest } = item;
          const formattedFromDate = fromdate
            ? moment(fromdate).format("YYYY-MM-DD")
            : null;
          const formattedToDate = todate
            ? moment(todate).format("YYYY-MM-DD")
            : null;
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
          };
        });

      const professionalcertificationsDetails = professionalcertifications
        ?.filter((item) => {
          const { certificationtype, fromdate, todate, remarks } = item;
          // Check if all fields are empty
          return (
            certificationtype?.trim() !== "" ||
            certificationtype === null ||
            certificationtype === undefined ||
            fromdate !== "" ||
            fromdate === null ||
            fromdate === undefined ||
            todate !== "" ||
            todate === null ||
            todate === undefined ||
            remarks?.trim() !== "" ||
            remarks === null ||
            remarks === undefined
          );
        })
        .map((item) => {
          const { attachment, fromdate, todate, ...rest } = item;
          const formattedFromDate = fromdate
            ? moment(fromdate).format("YYYY-MM-DD")
            : null;
          const formattedToDate = todate
            ? moment(todate).format("YYYY-MM-DD")
            : null;
          const updatedAttachment = attachment?.map((att) => ({
            name: att?.name,
            uniqueId: att?.response?.uniqueId
              ? att?.response?.uniqueId
              : att?.uniqueId,
          }));
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
            attachment: updatedAttachment,
          };
        });

      const roleNames = roleList
        .filter((item) => selectedRoleId?.includes(item.roleId))
        .map((item) => item.roleName);

      const dobDate = new Date(dateofbirth);
      const currentDate = new Date();
      const agecalculte = currentDate.getFullYear() - dobDate.getFullYear();

      const payloadData = {
        full_name: `${firstname} ${lastname}`,
        employeeid: employeeid,
        user_id: user_id,
        role_id: selectedRoleId ? selectedRoleId : role_id,
        role_name: roleNames,
        profile_url: EmployeeDetails?.profile_url,
        start_date: hireddate ? moment(hireddate._d).format("YYYY-MM-DD") : "",
        gender: gender,
        status_id: employeeStatusId,
        nssf: nssf,
        national_id: national_id,
        phoneno: phoneno,
        physicaladdress: physicaladdress,
        professionalsince: professionalsince
          ? moment(professionalsince._d).format("YYYY-MM-DD")
          : "",
        hireddate: hireddate ? moment(hireddate._d).format("YYYY-MM-DD") : "",
        statusname: employee_status,
        employeesponser: employeesponser,
        age: agecalculte,
        maritalstatus: maritalstatus,
        country: country,
        nationality: nationality,
        companyname: companyname,
        jobtitle: jobtitle,
        dateofbirth: dateofbirth,
        emergencycontactname: emergencycontactname,
        emergencycontactnumber: emergencycontactnumber,
        baselocationid: baselocationid,
        firstname: firstname,
        lastname: lastname,
        salutation: salutation,
        competencyQC: competencyQC,
        serviceline: serviceline,
        subserviceline: subserviceline,
        competencyrole: competencyrole,
        industry: industry,
        sector: sector,
        industryrole: industryrole,
        expertise: expertise,
        designation: designation,
        grade: grade,
        targetchargeability: targetchargeability,
        chargeoutrate: chargeoutrate,
        greetings: greetings,
        lastpromotionlevel: lastpromotionlevel
          ? moment(lastpromotionlevel._d).format("YYYY-MM-DD")
          : "",
        dependent: dependent,
        educationdetails: transformedEducationDetails,
        workexperience: workexperienceDetails,
        professionalcertifications: professionalcertificationsDetails,
        practicaltrainings: practicaltrainingsDetails,
        managingoffice: managingoffice,
        supervisor: get(
          find(supervisorList, { employeeId: supervisor }),
          "name",
          null
        ),
        lastpromotion: lastpromotion,
        levellocationid: levellocationid,
        oversight: oversight,
        secondaryindustry: secondaryIndustry,
        leveldepartmentname: leveldepartmentname,
        regionalsupervisor:
          regionalsupervisor !== "none"
            ? get(
                find(supervisorList, { employeeId: regionalsupervisor }),
                "name",
                null
              )
            : "none",
        officesupervisor:
          officesupervisor !== "none"
            ? get(
                find(supervisorList, { employeeId: officesupervisor }),
                "name",
                null
              )
            : "none",
        engagementsupervisor: get(
          find(supervisorList, { employeeId: engagementsupervisor }),
          "name",
          null
        ),
        OfficeSupervisorId: officesupervisor,
        RegionalSupervisorId: regionalsupervisor,
        othernames: othernames,
        postaladdress: postaladdress,
        workpermitno: workpermitno,
        workpermitexpirydate: workpermitexpirydate
          ? moment(workpermitexpirydate._d).format("YYYY-MM-DD")
          : "",
        nextofkinrelationship: nextofkinrelationship,
        nextofkinpostaladdress: nextofkinpostaladdress,
        accountno: accountno,
        accountname: accountname,
        bankname: bankname,
        bankbranch: bankbranch,
        bankcountry: bankcountry,
        bankcode: bankcode,
        bankbranchcode: bankbranchcode,
        bankswiftcode: bankswiftcode,
        krapin: krapin,
        nhif: nhif,
        helpmonthlybilling: helpmonthlybilling,
        shiftstarttime: EmployeeDetails?.shiftstarttime,
        shiftendtime: EmployeeDetails?.shiftendtime,
        regionalcompetencyrole: regionalcompetencyrole,
        grossSalary,
        housingAllowance,
        medicalInsurance,
        pension,
        groupLifeInsurance,
        socialSecurityContribution,
        bonus,
        otherCosts,
        totalEmploymentCost,
      };

      console.log("payloadData--->", payloadData);

      const response = await CALL_API(
        `add-users/${ClientId}`,
        "patch",
        payloadData
      );

      toggleLoader(false);
      //   setFileName("");
      form.resetFields();
      //   closeModal(true);

      if (response.code === "SUCCESS") {
        if (response?.message === "Please Upgrade Your Plan") {
          message.error(response?.message);
        } else {
          message.success(response?.message);
          setUpdateState("");
        }
      }
      setUpdateState("");
      //   await getEmployeeList();
      await fetchMasterData(true);
    } catch (error) {
      message.error("Something went wrong");
      toggleLoader(false);
      console.error("[handlesubmit] error --->", error);
    }
  };

  const [managingOfficeData, setManagingOfficeData] = useState([]);
  const [organisationLevelData, setOrganisationLevelData] = useState([]);
  const [competencyQCData, setCompetencyQCfetchData] = useState([]);
  const [servicelineData, setServicelinefetchData] = useState([]);
  const [subservicelineData, setsubservicelineData] = useState([]);
  const [competencyroleData, setcompetencyroleData] = useState([]);
  const [departmentLevelData, setDepartmentLevelData] = useState([]);

  const fetchData = async () => {
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level1`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setManagingOfficeData(result);
    }
  };

  // const organisationLevelfetchData1= async () => {
  //   const filtervalue = managingOfficeData.filter((item)=>form.getFieldValue('managingoffice').includes(item.value));
  //   const mapdata = filtervalue.map((item)=>item?.name);
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`, "get");
  //   if (!isEmpty(response))  {
  //     const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
  //     setOrganisationLevelData(result);
  //   }
  // };

  const competencyQCfetchData1 = async () => {
    const filtervalue = managingOfficeData?.filter((item) =>
      form.getFieldValue("managingoffice").includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setCompetencyQCfetchData(result);
    }
  };

  const servicelinefetchData1 = async () => {
    const filtervalue = competencyQCData.filter((item) =>
      form.getFieldValue("competencyQC")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level3&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setServicelinefetchData(result);
    }
  };

  const subservicelinefetchData1 = async () => {
    const filtervalue = servicelineData?.filter((item) =>
      form.getFieldValue("serviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level4&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const serviceLines = form.getFieldValue("serviceline"); // e.g., ['Deal Advisory', 'Risk Advisory']
      // Map each service line to a cleaned-up string (remove spaces)
      const formValues = serviceLines?.map((line) => line.replace(/\s+/g, ""));
      // Filter the response data to include only items that match any cleaned-up service line
      const filterResponse = response?.data?.filter((item) =>
        formValues.some((formValue) => item?.name?.includes(formValue))
      );

      const result = flatMap(response?.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setsubservicelineData(result);
    }
  };

  const departmentfetchData1 = async () => {
    const filtervalue = subservicelineData?.filter((item) =>
      form.getFieldValue("subserviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level5&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setDepartmentLevelData(result);
    }
  };

  const competencyrolefetchData1 = async () => {
    const filtervalue = subservicelineData.filter((item) =>
      form.getFieldValue("subserviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level6&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setcompetencyroleData(result);
    }
  };

  // const organisationLevelfetchData = async () => {
  //   const filtervalue = managingOfficeData.filter((item) =>
  //     form.getFieldValue("managingoffice").includes(item.value)
  //   );
  //   const mapdata = filtervalue.map((item) => item?.name);
  //   const response = await CALL_FEATCH_API(
  //     `insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`,
  //     "get"
  //   );
  //   if (!isEmpty(response)) {
  //     const result = flatMap(response.data, (item) =>
  //       item.value.map((value) => ({
  //         name: item.name,
  //         value,
  //         parentLevel: item.parentLevel,
  //       }))
  //     );
  //     setOrganisationLevelData(result);
  //     if (isEmpty(result)) {
  //       form.setFieldValue("organisationlevel", []);
  //       form.setFieldValue("competencyQC", []);
  //       form.setFieldValue("serviceline", []);
  //       form.setFieldValue("subserviceline", []);
  //       form.setFieldValue("competencyrole", []);
  //     } else {
  //       // organisationlevel
  //       const organisationlevelList = filter(result, (item) =>
  //         form.getFieldValue("managingoffice").includes(item.parentLevel)
  //       );
  //       const organisationlevel = filter(
  //         form.getFieldValue("organisationlevel"),
  //         (value) => find(organisationlevelList, { value: value })
  //       );
  //       form.setFieldValue("organisationlevel", organisationlevel);
  //       // competencyQC

  //       const competencyQClevelList = filter(competencyQCData, (item) =>
  //         form.getFieldValue("organisationlevel").includes(item.parentLevel)
  //       );
  //       setCompetencyQCfetchData(competencyQClevelList);
  //       const competencyQClevel = filter(
  //         form.getFieldValue("competencyQC"),
  //         (value) => find(competencyQClevelList, { value: value })
  //       );
  //       form.setFieldValue("competencyQC", competencyQClevel);
  //       // serviceline
  //       const servicelinelevelList = filter(servicelineData, (item) =>
  //         form.getFieldValue("competencyQC").includes(item.parentLevel)
  //       );
  //       setServicelinefetchData(servicelinelevelList);
  //       const serviceline = filter(form.getFieldValue("serviceline"), (value) =>
  //         find(servicelinelevelList, { value: value })
  //       );
  //       form.setFieldValue("serviceline", serviceline);
  //       // subserviceline
  //       const subservicelinelevelList = filter(subservicelineData, (item) =>
  //         form.getFieldValue("serviceline").includes(item.parentLevel)
  //       );
  //       setsubservicelineData(subservicelinelevelList);
  //       const subserviceline = filter(
  //         form.getFieldValue("subserviceline"),
  //         (value) => find(subservicelinelevelList, { value: value })
  //       );
  //       form.setFieldValue("subserviceline", subserviceline);
  //       //  // competencyrole
  //       //  const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
  //       //  setcompetencyroleData(competencyrolelevelList)
  //       //  const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
  //       //  form.setFieldValue('competencyrole', competencyrole);
  //     }
  //   }
  // };

  const competencyQCfetchData = async () => {
    const filtervalue = managingOfficeData.filter((item) =>
      form.getFieldValue("managingoffice").includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setCompetencyQCfetchData(result);
      if (isEmpty(result)) {
        form.setFieldValue("competencyQC", []);
        form.setFieldValue("serviceline", []);
        form.setFieldValue("subserviceline", []);
        form.setFieldValue("competencyrole", []);
        form.setFieldValue("leveldepartmentname", []);

        setServicelinefetchData([]);
      } else {
        // competencyQC

        const competencyQClevelList = filter(result, (item) =>
          form.getFieldValue("managingoffice").includes(item.parentLevel)
        );
        const competencyQClevel = filter(
          form.getFieldValue("competencyQC"),
          (value) => find(competencyQClevelList, { value: value })
        );
        form.setFieldValue("competencyQC", competencyQClevel);
        // serviceline
        const servicelinelevelList = filter(servicelineData, (item) =>
          form.getFieldValue("competencyQC").includes(item.parentLevel)
        );
        setServicelinefetchData(servicelinelevelList);
        const serviceline = filter(form.getFieldValue("serviceline"), (value) =>
          find(servicelinelevelList, { value: value })
        );
        form.setFieldValue("serviceline", serviceline);
        // subserviceline
        const subservicelinelevelList = filter(subservicelineData, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        setsubservicelineData(subservicelinelevelList);
        const subserviceline = filter(
          form.getFieldValue("subserviceline"),
          (value) => find(subservicelinelevelList, { value: value })
        );
        form.setFieldValue("subserviceline", subserviceline);

        // const leveldepartmentnameList = filter(subservicelineData, (item) =>
        //   form.getFieldValue("serviceline").includes(item.parentLevel)
        // );
        // setsubservicelineData(leveldepartmentnameList);
        // const leveldepartmentname = filter(
        //   form.getFieldValue("leveldepartmentname"),
        //   (value) => find(leveldepartmentnameList, { value: value })
        // );
        // form.setFieldValue("leveldepartmentname", leveldepartmentname);
        // competencyrole
        // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        // setcompetencyroleData(competencyrolelevelList)
        // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        // form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };

  const servicelinefetchData = async () => {
    const filtervalue = competencyQCData.filter((item) =>
      form.getFieldValue("competencyQC")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level3&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setServicelinefetchData(result);
      if (isEmpty(result)) {
        form.setFieldValue("serviceline", []);
        form.setFieldValue("subserviceline", []);
        form.setFieldValue("competencyrole", []);
      } else {
        // serviceline
        const servicelinelevelList = filter(result, (item) =>
          form.getFieldValue("competencyQC").includes(item.parentLevel)
        );
        const serviceline = filter(form.getFieldValue("serviceline"), (value) =>
          find(servicelinelevelList, { value: value })
        );
        form.setFieldValue("serviceline", serviceline);
        // subserviceline
        const subservicelinelevelList = filter(subservicelineData, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        setsubservicelineData(subservicelinelevelList);
        const subserviceline = filter(
          form.getFieldValue("subserviceline"),
          (value) => find(subservicelinelevelList, { value: value })
        );
        form.setFieldValue("subserviceline", subserviceline);

        const leveldepartmentnameList = filter(subservicelineData, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        setsubservicelineData(leveldepartmentnameList);
        const leveldepartmentname = filter(
          form.getFieldValue("leveldepartmentname"),
          (value) => find(leveldepartmentnameList, { value: value })
        );
        form.setFieldValue("leveldepartmentname", leveldepartmentname);

        // // competencyrole
        // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        // setcompetencyroleData(competencyrolelevelList)
        // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        // form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };

  const subservicelinefetchData = async () => {
    const filtervalue = servicelineData?.filter((item) =>
      form.getFieldValue("serviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level4&parentLevel=[${mapdata}]`,
      "get"
    );

    if (!isEmpty(response)) {
      const serviceLines = form.getFieldValue("serviceline"); // e.g., ['Deal Advisory', 'Risk Advisory']
      // Map each service line to a cleaned-up string (remove spaces)
      const formValues = serviceLines?.map((line) => line.replace(/\s+/g, ""));

      // Filter the response data to include only items that match any cleaned-up service line
      const filterResponse = response?.data?.filter((item) =>
        formValues.some((formValue) => item?.name?.includes(formValue))
      );

      const result = filterResponse.flatMap((item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setsubservicelineData(result);
      if (isEmpty(result)) {
        form.setFieldValue("subserviceline", []);
      } else {
        // subserviceline
        const subservicelinelevelList = filter(result, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        const subserviceline = filter(
          form.getFieldValue("subserviceline"),
          (value) => find(subservicelinelevelList, { value: value })
        );

        form.setFieldValue("subserviceline", subserviceline);
        // // competencyrole
        // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        // setcompetencyroleData(competencyrolelevelList)
        // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        // form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };
  const departmentfetchData = async () => {
    const filtervalue = subservicelineData?.filter((item) =>
      form.getFieldValue("subserviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level5&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setDepartmentLevelData(result);
      if (isEmpty(result)) {
        // form.setFieldValue("subserviceline", []);
        // form.setFieldValue("competencyrole", []);
      } else {
        // subserviceline
        const subservicelinelevelList = filter(result, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        const subserviceline = filter(
          form.getFieldValue("subserviceline"),
          (value) => find(subservicelinelevelList, { value: value })
        );

        // form.setFieldValue("subserviceline", subserviceline);
        // // competencyrole
        // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        // setcompetencyroleData(competencyrolelevelList)
        // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        // form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };

  useEffect(() => {
    if (updateState === "basic") {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (competencyQCData?.length == 0 && updateState === "basic") {
      competencyQCfetchData1();
    }
  }, [managingOfficeData]);

  useEffect(() => {
    if (servicelineData?.length == 0 && updateState === "basic") {
      servicelinefetchData1();
    }
  }, [competencyQCData]);

  useEffect(() => {
    if (subservicelineData?.length == 0 && updateState === "basic") {
      subservicelinefetchData1();
    }
  }, [servicelineData]);

  useEffect(() => {
    if (departmentLevelData?.length == 0 && updateState === "basic") {
      departmentfetchData1();
    }
  }, [subservicelineData]);

  // useEffect(() => {
  //   if (competencyroleData?.length == 0 && updateState === "basic") {
  //     competencyrolefetchData1();
  //   }
  // }, [subservicelineData]);

  const tabTitles = ["Basic", "Role", "Location"];

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId) => {
        const check = selectedRoleId?.includes(roleId) ? true : false;
        return (
          <Switch
            size="small"
            defaultChecked
            checked={check}
            onChange={(checked) => {
              getRoleId(roleId, checked);
            }}
          />
        );
      },
    },
  ];

  const baseLocationColumn = [
    {
      title: "Select Base Location",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id) => {
        return (
          <Switch
            size="small"
            checked={baseLocation === location_id}
            onChange={(checked) => {
              setBaseLocation(checked ? location_id : "");
              onFinish();
            }}
          />
        );
      },
    },
  ];

  const getRoleId = (role_id, checked) => {
    const newRoleId = checked
      ? [...selectedRoleId, role_id]
      : selectedRoleId.filter((item) => item !== role_id);
    setSelectedRoleId(newRoleId);
    onFinish();
  };

  const handleSelectAllRole = (e) => {
    const checked = e.target.checked;
    setSelectAllRoleChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
    setFormValues((prev) => ({
      ...prev,
      selectedRoleId: selectedRoleId,
    }));
    onFinish();
  };

  const handleResetAllRole = (e) => {
    setSelectedRoleId([]);
    setFormValues((prev) => ({
      ...prev,
      selectedRoleId: selectedRoleId,
    }));
    onFinish();
  };

  useEffect(() => {
    console.log("EmployeeDetails.roleid -->", EmployeeDetails.roleid);
    setSelectedRoleId(EmployeeDetails.roleid);
    setBaseLocation(
      EmployeeDetails?.baselocationid ? EmployeeDetails?.baselocationid : ""
    );
  }, [EmployeeDetails]);

  const locationId = chain(roleList)
    .filter((role) => selectedRoleId?.includes(role.roleId))
    .flatMap((role) => role.locationId)
    .uniq()
    .value();

  const locationBasedOnRole = locationList?.filter((val) =>
    locationId.includes(val?.location_id)
  );

  const iconsize = { fontSize: "14px" };

  const cursorstyle = { cursor: "pointer" };

  const handleDateChange = (date, dateString, key, field) => {
    const updatedEducationDetails = [...formValues.educationdetails];
    updatedEducationDetails[key][field] = date;

    console.log("formValues", updatedEducationDetails[key], key);
    setFormValues({
      ...formValues,
      educationdetails: updatedEducationDetails,
    });

    onFinish();
  };

  const calculateTotal = () => {
    const values = form.getFieldsValue([
      "grossSalary",
      "housingAllowance",
      "medicalInsurance",
      "pension",
      "groupLifeInsurance",
      "socialSecurityContribution",
      "bonus",
      "otherCosts",
    ]);

    const total = Object.values(values).reduce((acc, val) => {
      const numericVal = parseFloat(val.replace(/,/g, "")); // Remove commas before parsing
      return acc + (isNaN(numericVal) ? 0 : numericVal);
    }, 0);

    const formattedTotal = total.toFixed(2); // Ensure maximum 5 digits after decimal

    form.setFieldsValue({ totalEmploymentCost: formattedTotal });
  };

  const formatNumber = (value) => {
    if (!value) return "";
    const [integer, fraction] = value.toString().split(".");
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return fraction !== undefined
      ? `${formattedInteger}.${fraction}`
      : formattedInteger;
  };

  const parseNumber = (value) => {
    return value.replace(/,/g, "");
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Tab",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Enter",
      ".",
    ];

    const isAllowedKey =
      allowedKeys.includes(e.key) ||
      ((e.ctrlKey || e.metaKey) &&
        (e.key === "v" || e.key === "c" || e.key === "x"));

    if (!/^[0-9]$/.test(e.key) && !isAllowedKey) {
      e.preventDefault();
    }
  };

  const { key, url } = queryKeys.getChargeoutRates;
  const { data: gradeDataRes } = useFetch(key, url);

  const gradeData = gradeDataRes?.response?.items;

  const gradeOptions = gradeData
    ? gradeData?.map((item) => ({
        label: item.jobgradecode,
        value: item.jobgradecode,
        rate: parseInt(item.DRC) || 0,
      }))
    : [];

  const setChargeout = () => {
    const selectedGrade = form.getFieldValue("grade");

    if (selectedGrade) {
      const rate = gradeOptions.find(
        (item) => item.value === selectedGrade
      )?.rate;
      form.setFieldsValue({ chargeoutrate: rate });
      setFormValues((prev) => ({
        ...prev,
        chargeoutrate: rate,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <EditProfileBox>
      {updateState === "personalinformation" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Personal Information:</b>
          <Form
            className=""
            form={form}
            name="addemployeeform"
            layout="vertical"
            initialValues={{
              user_id: "",
              department_id: "",
              gender: "",
              dateof_birth: "",
              maritalstatus: "",
              nationality: "",
              nssf: "",
              national_id: "",
              address: "",
              phoneno: "",
              overtime: "",
              start_date: "",
              emergencycontactname: "",
              emergencycontactnumber: "",
              employementtype: "",
              initial: "",
              first_name: "",
              last_name: "",
              salutation: "",
              dependent: "",
            }}
            onFinish={() => {
              handlesubmit();
            }}
          >
            <StyleRow className="m-1 mt-2" gutter={20}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="salutation"
                  label="Salutation"
                  placeholder="Select Salutation"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Salutation is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Salutation"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="Mr.">Mr.</Option>
                    <Option value="Dr.">Dr.</Option>
                    <Option value="Prof.">Prof.</Option>
                    <Option value="Ms.">Ms.</Option>
                    <Option value="Mrs.">Mrs.</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="initial"
                  label="Initial"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Initial is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Initial"
                    maxLength={5}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="firstname"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "First name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="First name"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="lastname"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Last name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Last name"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="employeeid"
                  label="Employee ID"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Employee ID is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Employee ID"
                    maxLength={15}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z0-9\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col> */}
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="user_id"
                  label="User ID"
                  rules={[
                    {
                      required: true,
                      message: "Email ID is required",
                    },
                    { validator: validateEmployeeId },
                  ]}
                >
                  <Input
                    placeholder="Email ID"
                    disabled={true}
                    onChange={onFinish}
                    maxLength={60}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item name="phoneno" label="Phone No">
                  <PhoneInput
                    type="text"
                    placeholder="Phone No"
                    maxLength={20}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const value = /^[0-9+]$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Gender is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select gender"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="They/ Them">They/ Them</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item name="dependent" label="No. of Dependants">
                  <Input
                    placeholder="No. of Dependants"
                    maxLength={10}
                    onKeyDown={(e) => {
                      const value = /^[0-9+]$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Date of Birth"
                  name="dateofbirth"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Date of Birth is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    onChange={() => {
                      onFinish();
                    }}
                    placeholder="Select Date of Birth"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="maritalstatus"
                  label="Marital Status"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="single">Single</Option>
                    <Option value="married">Married</Option>
                    <Option value="widowed">Widowed</Option>
                    <Option value="divorced">Divorced</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="greetings"
                  label="Greetings"
                  rules={[
                    { required: false, message: "Greetings is required" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                  >
                    {Greetings?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="nationality"
                  label="Country"
                  rules={[
                    {
                      required: true,
                      message: "Country is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {countryList?.map((country, index) => (
                      <Select.Option
                        key={index}
                        value={country}
                        label={country}
                      >
                        {country}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="emergencycontactname"
                  label="Emergency Contact Name"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Emergency Contact Name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Emergency Contact Name"
                    maxLength={30}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="emergencycontactnumber"
                  label="Emergency Contact Number"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Emergency Contact Name is required",
                    },
                  ]}
                >
                  <PhoneInput
                    placeholder="Emergency Contact Number"
                    maxLength={20}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const value = /^[0-9+]$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: true,
                      message: "Address is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Address"
                    onChange={onFinish}
                    maxLength={60}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9,/]$/.test(e.key) ||
                        /^[a-zA-Z\s,/]+$/.test(e.key);
                      if (!value && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </StyleRow>
            <div className="text-end mt-3">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "workinformation" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Work Information:</b>
          <Form
            className=""
            form={form}
            name="addemployeeidentityform"
            layout="vertical"
            initialValues={{
              company_Name: "",
              industry: [],
              sector: [],
              secondaryindustry: [],
              industryrole: "",
              expertise: "",
              designation: "",
              immediatesupervisor: "",
              grade: "",
              targetchargeability: "",
              chargeoutrate: "",
              jobtitle: "",
              professionalsince: "",
              hireddate: "",
              employeesponser: "",
              relevent: "",
              maritalstatus: "",
              description: "",
              lastpromotionlevel: "",
              overtime: "",
              lastpromotion: "",
              regionalsupervisor: "",
              regionalcompetencyrole: "",
              officesupervisor: "",
              engagementsupervisor: "",
            }}
            onFinish={() => handlesubmit()}
          >
            <StyleRow className="m-1 mt-2" gutter={20}>
              {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                name="overtime"
                                label="Overtime Eligible"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "This field is required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  <Option value="yes">Yes</Option>
                                  <Option value="no">No</Option>
                                </Select>
                              </Form.Item>
                            </Col> */}
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="companyname"
                  label="Contracting Company"
                  rules={[
                    {
                      required: false,
                      message: "Contracting Company is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {ContractingCompany?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="industry"
                  label="Primary Industry"
                  rules={[
                    {
                      required: false,
                      message: "Primary Industry is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {Industry?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="secondaryindustry"
                  label="Secondary Industry"
                  rules={[
                    {
                      required: false,
                      message: "Secondary Industry is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {secondaryIndustry?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="sector"
                  label="Sector"
                  rules={[
                    {
                      required: false,
                      message: "Sector is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {Sector?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="industryrole"
                  label="Industry Role"
                  rules={[
                    {
                      required: false,
                      message: "Industry Role is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={onFinish}
                    getPopupContainer={() => containerRef.current}
                  >
                    {IndustryRole?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="regionalsupervisor"
                  label="Regional Competency Supervisor"
                  rules={[
                    {
                      required: false,
                      message: "Regional Competency Supervisor is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    // options={managingOfficeData}
                    allowClear
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                    options={supervisorList}
                    fieldNames={{
                      label: "name",
                      value: "employeeId",
                    }}
                    filterOption={(input, option) =>
                      option.name.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="regionalcompetencyrole"
                  label="Regional Competency Role"
                  rules={[
                    {
                      required: false,
                      message: "Regional Competency Role is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                  >
                    {RegionalCometencyRole.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="officesupervisor"
                  label="Office Competency Supervisor"
                  rules={[
                    {
                      required: false,
                      message: "Office Competency Supervisor is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                    options={supervisorList?.concat([
                      {
                        name: "Null",
                        employeeId: null,
                      },
                    ])}
                    allowClear
                    fieldNames={{
                      label: "name",
                      value: "employeeId",
                    }}
                    filterOption={(input, option) =>
                      option.name.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="engagementsupervisor"
                  label="Secondary Supervisor"
                  rules={[
                    {
                      required: false,
                      message: "Secondary Supervisor is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                    options={supervisorList}
                    fieldNames={{
                      label: "name",
                      value: "employeeId",
                    }}
                    filterOption={(input, option) =>
                      option.name.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="expertise"
                  label="Expertise"
                  rules={[
                    {
                      required: false,
                      message: "Expertise is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Expertise"
                    // maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="designation"
                  label="Designation"
                  rules={[
                    {
                      required: false,
                      message: "Designation is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Designation"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z0-9\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>

              {/* <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="immediatesupervisor"
                  label="Immediate Supervisor"
                  rules={[
                    {
                      required: false,
                      message: "Immediate Supervisor is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Immediate Supervisor"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col> */}

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="grade"
                  label="Grade"
                  rules={[
                    {
                      required: true,
                      message: "Grade is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={() => {
                      onFinish();
                      setChargeout();
                    }}
                    getPopupContainer={() => containerRef.current}
                    options={gradeOptions}
                  />
                  {/* {Grade?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select> */}
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="chargeoutrate"
                  label="Charge Out Rate"
                  rules={[
                    {
                      required: false,
                      message: "Charge Out Rate is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Charge Out Rate"
                    // maxLength={50}
                    onChange={onFinish}
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="targetchargeability"
                  label="Target Chargeability %"
                  rules={[
                    {
                      required: false,
                      message: "Target Chargeability % is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Target Chargeability %"
                    maxLength={50}
                    onChange={onFinish}
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Last Promotion Date"
                  name="lastpromotionlevel"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Last Promotion Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    placeholder="Select Last Promotion Date"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="lastpromotion"
                  label="Last Promotion Level"
                  rules={[
                    {
                      required: false,
                      message: "Last Promotion is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Last Promotion"
                    maxLength={100}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="jobtitle"
                  label="Job Title"
                  rules={[
                    {
                      required: true,
                      message: "Job title is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Job Title"
                    maxLength={50}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z0-9\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Professional Since"
                  name="professionalsince"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Professional Since is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    onChange={onFinish}
                    placeholder="Professional Since"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Hired Date"
                  name="hireddate"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Hired Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    onChange={onFinish}
                    placeholder="Select Hired Date"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="employeesponser"
                  label="Employee Sponsor"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="status_id"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={(value) => {
                      setEmployeeStatusId(value === "active" ? "1" : "0");
                      onFinish();
                    }}
                  >
                    <Option value="active">Active</Option>
                    <Option value="in active">In active</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="relevent"
                  label="Relevant"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item name="description" label="Description">
                  <Input.TextArea
                    onChange={onFinish}
                    placeholder="description"
                  />
                </Form.Item>
              </Col>
            </StyleRow>
            <div className="text-end mt-3">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "level" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            <ArrowForwardIosIcon style={iconsize} /> Back
          </button>
          <b>Level:</b>
          <Form
            className=""
            form={form}
            name="addemployeelevelform"
            layout="vertical"
            initialValues={{
              competencyQC: [],
              serviceline: [],
              subserviceline: [],
              competencyrole: [],
              managingoffice: [],
              organisationlevel: [],
            }}
            onFinish={() => handlesubmit()}
          >
            <StyleRow gutter={20}>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="managingoffice"
                  label="Country"
                  rules={[
                    {
                      required: true,
                      message: "Country is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    options={managingOfficeData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    getPopupContainer={() => containerRef.current}
                    onChange={() => {
                      onFinish();
                      competencyQCfetchData();
                    }}
                  />
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="organisationlevel"
                  label="Organisation Level"
                  rules={[
                    {
                      required: true,
                      message: "Organisation Level is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    getPopupContainer={() => containerRef.current}
                    disabled={form.getFieldValue('managingoffice')?.length == 0}
                    options={organisationLevelData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    onChange={() => {
                      onFinish();
                      competencyQCfetchData();
                    }}
                  />
                </Form.Item>
              </Col> */}
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="competencyQC"
                  label="Competency/ QC"
                  rules={[
                    {
                      required: true,
                      message: "Competency/ QC is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    getPopupContainer={() => containerRef.current}
                    disabled={competencyQCData?.length == 0}
                    options={competencyQCData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    onChange={() => {
                      onFinish();
                      servicelinefetchData();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="serviceline"
                  label="Service Line"
                  rules={[
                    {
                      required: true,
                      message: "Service Line is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    getPopupContainer={() => containerRef.current}
                    disabled={servicelineData?.length === 0}
                    options={servicelineData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    onChange={() => {
                      onFinish();
                      subservicelinefetchData();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="subserviceline"
                  label="Sub Serviceline"
                  rules={[
                    {
                      required: true,
                      message: "Sub Serviceline is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    getPopupContainer={() => containerRef.current}
                    disabled={subservicelineData?.length == 0}
                    options={subservicelineData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    onChange={() => {
                      onFinish();
                      departmentfetchData();
                      // competencyrolefetchData();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="leveldepartmentname"
                  label="Department"
                  rules={[
                    {
                      required: true,
                      message: "Department is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    mode="multiple"
                    getPopupContainer={() => containerRef.current}
                    disabled={departmentLevelData?.length == 0}
                    options={departmentLevelData}
                    fieldNames={{
                      label: "value",
                      value: "value",
                    }}
                    onChange={() => {
                      // competencyrolefetchData();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="competencyrole"
                  label="Office Competency Role"
                  rules={[
                    {
                      required: false,
                      message: "Office Competency Role is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    {CompetencyRole?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="supervisor"
                  label="Supervisor / Manager"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Supervisor/Manager is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                    options={supervisorList}
                    fieldNames={{
                      label: "name",
                      value: "employeeId",
                    }}
                    filterOption={(input, option) =>
                      option.name.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col> */}

              {/* <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="manager"
                                label="Manager"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Manager is required",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                >
                                  {filteredUserName?.map((value, index) => (
                                    <Option key={index} value={value} label={value}>
                                      {value}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col> */}
            </StyleRow>
            <div className="text-end mt-3">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "basic" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {" "}
            {pageName} /
          </button>{" "}
          <b>Edit Employee</b>
          <Tabs
            defaultActiveKey={activeTab}
            className="mt-3"
            onChange={handleTabChange}
          >
            {tabTitles.map((title, index) => (
              <TabPane tab={title} key={index.toString()}>
                {activeTab === "0" && (
                  <>
                    <Form
                      className=""
                      form={form}
                      name="addemployeeform"
                      layout="vertical"
                      initialValues={{
                        managingoffice: [],
                        competencyQC: [],
                        serviceline: [],
                        subserviceline: [],
                        leveldepartmentname: [],
                        competencyrole: [],
                        levellocationid: "",
                        oversight: false,
                        supervisor: "",
                        companyname: "",
                        industry: [],
                        secondaryindustry: [],
                        sector: [],
                        expertise: "",
                        industryrole: [],
                        regionalsupervisor: "",
                        regionalcompetencyrole: "",
                        officesupervisor: "",
                        engagementsupervisor: "",
                        designation: "",
                        grade: "",
                        targetchargeability: "",
                        chargeoutrate: "",
                        lastpromotionlevel: "",
                        lastpromotion: "",
                        jobtitle: "",
                        professionalsince: "",
                        hireddate: "",
                        employeesponser: "",
                        employee_status: "",
                        firstname: "",
                        lastname: "",
                        salutation: "",
                        othernames: "",
                        dateofbirth: "",
                        gender: "",
                        dependent: "",
                        maritalstatus: "",
                        phoneno: "",
                        user_id: "",
                        postaladdress: "",
                        physicaladdress: "",
                        password: "",
                        country: "",
                        greetings: "",
                        national_id: "",
                        nationality: "",
                        workpermitno: "",
                        workpermitexpirydate: "",
                        emergencycontactname: "",
                        emergencycontactnumber: "",
                        nextofkinrelationship: "",
                        nextofkinpostaladdress: "",
                      }}
                      onFinish={() => {
                        handlesubmit();
                      }}
                    >
                      <b className="mt-2">Edit Level Information:</b>
                      <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="managingoffice"
                            label="Country"
                            rules={[
                              {
                                required: true,
                                message: "Country is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              options={managingOfficeData}
                              fieldNames={{
                                label: "value",
                                value: "value",
                              }}
                              getPopupContainer={() => containerRef.current}
                              onChange={() => {
                                onFinish();
                                // setDepartmentLevelData([]);
                                competencyQCfetchData();
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="competencyQC"
                            label="Competency/ QC"
                            rules={[
                              {
                                required: true,
                                message: "Competency/ QC is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              getPopupContainer={() => containerRef.current}
                              disabled={competencyQCData?.length === 0}
                              options={competencyQCData}
                              fieldNames={{
                                label: "value",
                                value: "value",
                              }}
                              onChange={() => {
                                onFinish();
                                servicelinefetchData();
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="serviceline"
                            label="Service Line"
                            rules={[
                              {
                                required: true,
                                message: "Service Line is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              getPopupContainer={() => containerRef.current}
                              disabled={servicelineData?.length === 0}
                              options={servicelineData}
                              fieldNames={{
                                label: "value",
                                value: "value",
                              }}
                              onChange={() => {
                                onFinish();
                                subservicelinefetchData();
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="subserviceline"
                            label="Sub Serviceline"
                            rules={[
                              {
                                required:
                                  subservicelineData &&
                                  subservicelineData.length > 0,
                                message: "Sub Serviceline is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              getPopupContainer={() => containerRef.current}
                              disabled={subservicelineData?.length === 0}
                              options={subservicelineData}
                              fieldNames={{
                                label: "value",
                                value: "value",
                              }}
                              onChange={() => {
                                onFinish();
                                departmentfetchData();
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="leveldepartmentname"
                            label="Department"
                            rules={[
                              {
                                required: true,
                                message: "Department is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Department"
                              optionFilterProp="children"
                              mode="multiple"
                              getPopupContainer={() => containerRef.current}
                              disabled={departmentLevelData?.length === 0}
                              options={departmentLevelData}
                              fieldNames={{
                                label: "value",
                                value: "value",
                              }}
                              onChange={() => {
                                onFinish();
                                // competencyrolefetchData();
                              }}
                            />
                          </Form.Item>
                        </Col>
                        {/* )} */}

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="competencyrole"
                            label="Competency Role"
                            rules={[
                              {
                                required: false,
                                message: "Competency Role is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              mode="multiple"
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              {CompetencyRole?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="levellocationid"
                            label="Location"
                            rules={[
                              {
                                required: true,
                                message: "Location is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              {filteredLocation?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value?.location_id}
                                  label={value?.location_name}
                                >
                                  {value?.location_name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item name="oversight" label="Over Sight">
                            <Checkbox
                              checked={formValues.oversight}
                              onChange={(e) => {
                                setFormValues({
                                  ...formValues,
                                  oversight: e.target.checked,
                                });
                              }}
                            />
                          </Form.Item>
                        </Col>

                        {/* <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="supervisor"
                            label="Supervisor / Manager"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Supervisor / Manager is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                              options={supervisorList}
                              fieldNames={{
                                label: "name",
                                value: "employeeId",
                              }}
                              filterOption={(input, option) =>
                                option.name
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                          </Form.Item>
                        </Col> */}
                      </StyleRow>
                      <b className="my-2">Edit Work Information:</b>
                      <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="companyname"
                            label="Contracting Company"
                            rules={[
                              {
                                required: false,
                                message: "Contracting Company is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              {ContractingCompany?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="industry"
                            label="Primary Industry"
                            rules={[
                              {
                                required: false,
                                message: "Primary Industry is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              {Industry?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="secondaryindustry"
                            label="Secondry Industry"
                            rules={[
                              {
                                required: false,
                                message: "Secondry Industry is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {secondaryIndustry?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="sector"
                            label="Sector"
                            rules={[
                              {
                                required: false,
                                message: "Sector is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              mode="multiple"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {Sector?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="expertise"
                            label="Expertise"
                            rules={[
                              {
                                required: false,
                                message: "Expertise is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Expertise"
                              // maxLength={500}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="industryrole"
                            label="Industry Role"
                            rules={[
                              {
                                required: false,
                                message: "Industry Role is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {IndustryRole?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="regionalsupervisor"
                            label="Regional Competency Supervisor"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Regional Competency Supervisor is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              allowClear
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              options={supervisorList}
                              fieldNames={{
                                label: "name",
                                value: "employeeId",
                              }}
                              onChange={onFinish}
                              filterOption={(input, option) =>
                                option.name
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="regionalcompetencyrole"
                            label="Regional Competency Role"
                            rules={[
                              {
                                required: false,
                                message: "Regional Competency Role is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {RegionalCometencyRole.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="officesupervisor"
                            label="Office Competency Supervisor"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Office Competency Supervisor is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              allowClear
                              getPopupContainer={() => containerRef.current}
                              options={supervisorList?.concat([
                                {
                                  name: "Null",
                                  employeeId: null,
                                },
                              ])}
                              fieldNames={{
                                label: "name",
                                value: "employeeId",
                              }}
                              onChange={onFinish}
                              filterOption={(input, option) =>
                                option.name
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="engagementsupervisor"
                            label="Secondary Supervisor"
                            rules={[
                              {
                                required: false,
                                message: "Secondary Supervisor is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              options={supervisorList}
                              fieldNames={{
                                label: "name",
                                value: "employeeId",
                              }}
                              onChange={onFinish}
                              filterOption={(input, option) =>
                                option.name
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="designation"
                            label="Designation"
                            rules={[
                              {
                                required: false,
                                message: "Designation is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Designation"
                              maxLength={50}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="grade"
                            label="Grade"
                            rules={[
                              { required: true, message: "Grade is required" },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              onChange={() => {
                                onFinish();
                                setChargeout();
                              }}
                            >
                              {Grade?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="chargeoutrate"
                            label="Charge Out Rate"
                            rules={[
                              {
                                required: false,
                                message: "Charge Out Rate is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Charge Out Rate"
                              maxLength={50}
                              onChange={onFinish}
                              onKeyDown={allowOnlyNumbers}
                              disabled={true}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="targetchargeability"
                            label="Target Chargeability %"
                            rules={[
                              {
                                required: false,
                                message: "Target Chargeability % is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Target Chargeability %"
                              maxLength={50}
                              onChange={onFinish}
                              disabled={true}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Last Promotion Date"
                            name="lastpromotionlevel"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Last Promotion Date is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              placeholder="Select Last Promotion Date"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="lastpromotion"
                            label="Last Promotional Level"
                            rules={[
                              {
                                required: false,
                                message: "Last Promotion is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Last Promotion"
                              maxLength={100}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="jobtitle"
                            label="Job Title"
                            rules={[
                              {
                                required: false,
                                message: "Job title is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Job Title"
                              maxLength={50}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Professional Since"
                            name="professionalsince"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Professional Since is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              onChange={onFinish}
                              placeholder="Professional Since"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Hired Date"
                            name="hireddate"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Hired Date is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              onChange={onFinish}
                              placeholder="Select Hired Date"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="employeesponser"
                            label="Employee Sponsor"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="employee_status"
                            label="Status"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={(value) =>
                                setEmployeeStatusId(
                                  value === "active" ? "1" : "0"
                                )
                              }
                            >
                              <Option value="active">Active</Option>
                              <Option value="in active">In active</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </StyleRow>
                      <b className="my-2">Edit Personal Information:</b>
                      <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="firstname"
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "First name is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="First name"
                              maxLength={50}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="lastname"
                            label="Last Name"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Last name is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Last name"
                              maxLength={50}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="salutation"
                            label="Salutation"
                            placeholder="Select Salutation"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Salutation is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Salutation"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              <Option value="Mr.">Mr.</Option>
                              <Option value="Dr.">Dr.</Option>
                              <Option value="Prof.">Prof.</Option>
                              <Option value="Ms.">Ms.</Option>
                              <Option value="Mrs.">Mrs.</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="othernames"
                            label="Other Names"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Other names is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Other name"
                              maxLength={50}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Date of Birth"
                            name="dateofbirth"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Date of Birth is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              onChange={() => {
                                onFinish();
                              }}
                              placeholder="Select Date of Birth"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Gender is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select gender"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              <Option value="male">Male</Option>
                              <Option value="female">Female</Option>
                              <Option value="They/ Them">They/ Them</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="dependent" label="No. of Dependants">
                            <Input
                              placeholder="No. of Dependants"
                              maxLength={10}
                              onKeyDown={(e) => {
                                const value = /^[0-9+]$/?.test(e.key);
                                if (
                                  !value &&
                                  e.key !== "Backspace" &&
                                  /^[0-9+]$/
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="maritalstatus"
                            label="Marital Status"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              <Option value="single">Single</Option>
                              <Option value="married">Married</Option>
                              <Option value="widowed">Widowed</Option>
                              <Option value="divorced">Divorced</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="phoneno" label="Mobile Number">
                            <PhoneInput
                              placeholder="Mobile Number"
                              maxLength={20}
                              onKeyDown={(e) => {
                                const value = /^[0-9+]$/?.test(e.key);
                                if (
                                  !value &&
                                  e.key !== "Backspace" &&
                                  /^[0-9+]$/
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="postaladdress"
                            label="Postal addres"
                            rules={[
                              {
                                required: false,
                                message: "Postal addres is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Postal addres"
                              maxLength={60}
                              onKeyDown={(e) => {
                                const value =
                                  /^[0-9,/]$/.test(e.key) ||
                                  /^[a-zA-Z\s,/]+$/.test(e.key);
                                if (!value && e.key !== "Backspace") {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="physicaladdress"
                            label="Physical address"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Physical Physical Address is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Physical address"
                              maxLength={60}
                              onKeyDown={(e) => {
                                const value =
                                  /^[0-9,/]$/.test(e.key) ||
                                  /^[a-zA-Z\s,/]+$/.test(e.key);
                                if (!value && e.key !== "Backspace") {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="country"
                            label="Country"
                            rules={[
                              {
                                required: false,
                                message: "Country is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {countryList?.map((country, index) => (
                                <Select.Option
                                  key={index}
                                  value={country}
                                  label={country}
                                >
                                  {country}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="greetings"
                            label="Greetings"
                            rules={[
                              {
                                required: false,
                                message: "Greetings is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {Greetings?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="national_id"
                            label="National ID/Passport Number"
                          >
                            <Input
                              placeholder="National ID/Passport Number"
                              maxLength={20}
                              onKeyDown={(e) => {
                                const value =
                                  /^[0-9]$/?.test(e.key) ||
                                  /^[a-zA-Z\s]+$/?.test(e.key);
                                if (
                                  !value &&
                                  e.key !== "Backspace" &&
                                  /^[0-9]$/
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="nationality"
                            label="Nationality"
                            rules={[
                              {
                                required: true,
                                message: "Nationality is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            >
                              {countryList?.map((country, index) => (
                                <Select.Option
                                  key={index}
                                  value={country}
                                  label={country}
                                >
                                  {country}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="workpermitno"
                            label="Work Permit No:"
                          >
                            <Input
                              placeholder="Work Permit No:"
                              type="text"
                              maxLength={10}
                              onKeyDown={(e) => {
                                const value = /^[0-9]$/?.test(e.key);
                                if (
                                  !value &&
                                  e.key !== "Backspace" &&
                                  /^[0-9]$/
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Work permit expiry date"
                            name="workpermitexpirydate"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Work permit expiry date is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              placeholder="Select Work permit expiry date"
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                      </StyleRow>
                      <b className="my-2 mx-1">Next Of Kin Details:</b>
                      <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="emergencycontactname"
                            label="Emergency Contact Name"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Emergency Contact Name is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Emergency Contact Name"
                              maxLength={30}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="emergencycontactnumber"
                            label="Emergency Contact Number"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Emergency Contact Name is required",
                              },
                            ]}
                          >
                            <PhoneInput
                              placeholder="Emergency Contact Number"
                              maxLength={20}
                              onKeyDown={(e) => {
                                const value = /^[0-9+]$/?.test(e.key);
                                if (
                                  !value &&
                                  e.key !== "Backspace" &&
                                  /^[0-9+]$/
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="nextofkinrelationship"
                            label="Relationship"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Relationship is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Relationship"
                              maxLength={15}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="nextofkinpostaladdress"
                            label="Postal addres"
                            rules={[
                              {
                                required: false,
                                message: "Postal addres is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Postal addres"
                              maxLength={60}
                              onKeyDown={(e) => {
                                const value =
                                  /^[0-9,/]$/.test(e.key) ||
                                  /^[a-zA-Z\s,/]+$/.test(e.key);
                                if (!value && e.key !== "Backspace") {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                      </StyleRow>

                      <div className="text-end mt-3">
                        <StyledButton
                          loading={showLoader}
                          type="primary"
                          htmlType="submit"
                        >
                          {showLoader ? "Update" : "Update"}
                        </StyledButton>
                      </div>
                    </Form>
                  </>
                )}
                {activeTab === "1" && (
                  <>
                    <div className="styled-card mb-5">
                      {roleList.length === 0 && (
                        <StyledButton
                          className="mt-2"
                          // onClick={() => setOpenModal(true)}
                        >
                          Add Role
                        </StyledButton>
                      )}
                      {roleList?.length > 0 && (
                        <Checkbox
                          className="mt-3"
                          checked={selectAllRoleChecked}
                          onChange={handleSelectAllRole}
                        >
                          Select All
                        </Checkbox>
                      )}
                      <SelectTable
                        // step={step}
                        // setStep={setStep}
                        columns={rolecolumns}
                        tabledata={roleList}
                        handleClickRole={handlesubmit}
                        handleReset={handleResetAllRole}
                        selectedRoleId={selectedRoleId}
                        showLoader={showLoader}
                      />
                      {/* <FormModal
                      isModalVisible={openModal}
                      closeModal={() => setOpenModal(false)}
                      Name="Role"
                    /> */}
                    </div>
                  </>
                )}

                {activeTab === "2" && (
                  <>
                    <div className="styled-card mb-5">
                      <SelectTable
                        // step={step}
                        // setStep={setStep}
                        columns={baseLocationColumn}
                        tabledata={locationBasedOnRole}
                        editRole="edit"
                        handleClickRole={handlesubmit}
                        selectedBaseLocation={baseLocation}
                        showLoader={showLoader}
                      />
                    </div>
                  </>
                )}
              </TabPane>
            ))}
          </Tabs>
        </>
      )}
      {updateState === "educationdetails" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Education Details:</b>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              handlesubmit();
            }}
            autoComplete="off"
            initialValues={{
              educationdetails: [],
            }}
            labelCol={{ xs: 24, sm: 24, md: 24 }}
            style={{ textAlign: "left" }}
          >
            <Form.List name="educationdetails">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    const fromDate = formValues.educationdetails[key]?.fromdate;
                    const toDate = formValues.educationdetails[key]?.todate;

                    return (
                      <StyleRow gutter={16} className="mt-2">
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Institute Name"
                            name={[name, "institutename"]}
                            rules={[
                              {
                                required: false,
                                message: "Institute Name required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Institute Name"
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            // {...restField}
                            label="From:"
                            // name={[name, "fromdate"]}
                            // rules={[
                            //   { required: false, message: "Date required" },
                            // ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              placeholder="From"
                              getPopupContainer={() => containerRef.current}
                              onChange={(date, dateString) =>
                                handleDateChange(
                                  date,
                                  dateString,
                                  key,
                                  "fromdate"
                                )
                              }
                              value={
                                fromDate
                                  ? moment(fromDate, "YYYY-MM-DD").isValid()
                                    ? moment(fromDate, "YYYY-MM-DD")
                                    : null
                                  : null
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            // {...restField}
                            label="To:"
                            // name={[name, "todate"]}
                            // rules={[
                            //   { required: false, message: "Date required" },
                            // ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              placeholder="To"
                              getPopupContainer={() => containerRef.current}
                              onChange={(date, dateString) =>
                                handleDateChange(
                                  date,
                                  dateString,
                                  key,
                                  "todate"
                                )
                              }
                              value={
                                toDate
                                  ? moment(toDate, "YYYY-MM-DD").isValid()
                                    ? moment(toDate, "YYYY-MM-DD")
                                    : null
                                  : null
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Exam Body:"
                            name={[name, "exambody"]}
                            rules={[
                              {
                                required: false,
                                message: "Exam Body required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Exam Body"
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Awarded:"
                            name={[name, "awarded"]}
                            rules={[
                              { required: false, message: "Awarded required" },
                            ]}
                          >
                            {/* <Input placeholder="Awarded" onChange={onFinish}/> */}
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="Doctorate">Doctorate</Option>
                              <Option value="Master">Master</Option>
                              <Option value="Bachelor">Bachelor</Option>
                              <Option value="Diploma">Diploma</Option>
                              <Option value="Certificate">Certificate</Option>
                            </Select>
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Attachment"
                            name={[name, "attachment"]}
                            valuePropName="fileList"
                            rules={[
                              {
                                required: false,
                                message: "file is required",
                              },
                            ]}
                            getValueFromEvent={(e) => {
                              if (Array.isArray(e)) {
                                return e;
                              }
                              return e && e.fileList;
                            }}
                          >
                            <Upload
                              customRequest={({ file, onSuccess }) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);

                                reader.onload = () => {
                                  const base64Data =
                                    reader.result.split(",")[1];
                                  // Pass both the file and base64Data to onSuccess
                                  const uniqueId = generateUniqueId();
                                  onSuccess({ file, base64Data, uniqueId });
                                };

                                reader.onerror = (error) => {
                                  console.error("File read error:", error);
                                };
                              }}
                              name="logo"
                              listType="picture"
                              style={{ width: "100%" }}
                              onChange={(value) => {
                                onFinish();
                                uploadFile(value);
                              }}
                              maxCount={1}
                            >
                              <div style={{ width: "100%" }}>
                                <Button
                                  className="w-100 text-start"
                                  size="large"
                                >
                                  Upload File
                                </Button>
                              </div>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </StyleRow>
                    );
                  })}
                  <Form.Item>
                    <u
                      className="bg-transparent border-0 text-start"
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={cursorstyle}
                    >
                      +Add Another
                    </u>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "practicaltrainings" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Practical Training:</b>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              handlesubmit();
            }}
            autoComplete="off"
            initialValues={{
              practicaltrainings: [],
            }}
            labelCol={{ xs: 24, sm: 24, md: 24 }}
            style={{ textAlign: "left" }}
          >
            <Form.List name="practicaltrainings">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <StyleRow gutter={16} className="mt-2">
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Organisation Name"
                          name={[name, "organisationname"]}
                          rules={[
                            {
                              required: false,
                              message: "Organisation Name required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Organisation Name"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="From:"
                          name={[name, "fromdate"]}
                          rules={[
                            { required: false, message: "Date required" },
                          ]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="From"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="To:"
                          name={[name, "todate"]}
                          rules={[
                            { required: false, message: "Date required" },
                          ]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="To"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Position Held"
                          name={[name, "positionheld"]}
                          rules={[
                            {
                              required: false,
                              message: "Position Held required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Position Held"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Nature of work & Tasks performed or completed"
                          name={[name, "tasksperformed"]}
                          rules={[
                            {
                              required: false,
                              message:
                                "Nature of work & Tasks performed or completed required",
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder="Nature of work & Tasks performed or completed"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                        {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                      </Col>
                    </StyleRow>
                  ))}
                  <Form.Item>
                    <u
                      className="bg-transparent border-0 text-start"
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={cursorstyle}
                    >
                      +Add Another
                    </u>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "workexperience" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Work Experience:</b>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              handlesubmit();
            }}
            autoComplete="off"
            initialValues={{
              workexperience: [],
            }}
            labelCol={{ xs: 24, sm: 24, md: 24 }}
            style={{ textAlign: "left" }}
          >
            <Form.List name="workexperience">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <StyleRow gutter={16} className="mt-2">
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          {...restField}
                          label="Company name"
                          name={[name, "companyname"]}
                          rules={[
                            {
                              required: false,
                              message: "Company name required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Company name"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          {...restField}
                          label="Job Title"
                          name={[name, "jobtitle"]}
                          rules={[
                            { required: false, message: "Job Title required" },
                          ]}
                        >
                          <Input
                            placeholder="Job Title"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="From:"
                          name={[name, "fromdate"]}
                          rules={[
                            { required: false, message: "Date required" },
                          ]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="From"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="To:"
                          name={[name, "todate"]}
                          rules={[
                            { required: false, message: "Date required" },
                          ]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="To"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Relevant"
                          name={[name, "relevant"]}
                          rules={[
                            { required: false, message: "Relevnt required" },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            getPopupContainer={() => containerRef.current}
                            onChange={onFinish}
                          >
                            <Option value="yes">Yes</Option>
                            <Option value="no">No</Option>
                          </Select>
                        </Form.Item>
                        {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Job Description"
                          name={[name, "jobdescription"]}
                          rules={[
                            {
                              required: false,
                              message: "Job Description required",
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder="Job Description"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                        {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                      </Col>
                    </StyleRow>
                  ))}
                  <Form.Item>
                    <u
                      className="bg-transparent border-0 text-start"
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={cursorstyle}
                    >
                      +Add Another
                    </u>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "professionalcertifications" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Professional Certification:</b>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              handlesubmit();
            }}
            autoComplete="off"
            initialValues={{
              professionalcertifications: [],
            }}
            labelCol={{ xs: 24, sm: 24, md: 24 }}
            style={{ textAlign: "left" }}
          >
            <Form.List name="professionalcertifications">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <StyleRow gutter={16} className="mt-2">
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Certification Type"
                          name={[name, "certificationtype"]}
                          rules={[
                            {
                              required: true,
                              message: "Certification Type required",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select"
                            optionFilterProp="children"
                            getPopupContainer={() => containerRef.current}
                            defaultValue={AwardList[0]}
                            // onChange={onFinish}
                            onChange={() => {
                              onFinish();
                            }}
                          >
                            {AwardList?.map((value, index) => (
                              <Select.Option
                                key={index}
                                value={value}
                                label={value}
                              >
                                {value}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="From:"
                          name={[name, "fromdate"]}
                          rules={[{ required: true, message: "Date required" }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="From"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="To:"
                          name={[name, "todate"]}
                          rules={[{ required: true, message: "Date required" }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="To"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Remarks"
                          name={[name, "remarks"]}
                          rules={[
                            { required: true, message: "Remarks required" },
                          ]}
                        >
                          <Input
                            placeholder="Remarks"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Attachment"
                          name={[name, "attachment"]}
                          valuePropName="fileList"
                          rules={[
                            {
                              required: true,
                              message: "file is required",
                            },
                          ]}
                          getValueFromEvent={(e) => {
                            if (Array.isArray(e)) {
                              return e;
                            }
                            return e && e.fileList;
                          }}
                        >
                          <Upload
                            customRequest={({ file, onSuccess }) => {
                              const reader = new FileReader();
                              reader.readAsDataURL(file);

                              reader.onload = () => {
                                const base64Data = reader.result.split(",")[1];
                                const uniqueId = generateUniqueId();
                                onSuccess({ file, base64Data, uniqueId });
                              };

                              reader.onerror = (error) => {
                                console.error("File read error:", error);
                              };
                            }}
                            name="logo"
                            // action="/upload.do"
                            onChange={(value) => {
                              onFinish();
                              uploadFile(value);
                            }}
                            listType="picture"
                            style={{ width: "100%" }}
                          >
                            <div style={{ width: "100%" }}>
                              <Button className="w-100 text-start" size="large">
                                Upload File
                              </Button>
                            </div>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </StyleRow>
                  ))}
                  <Form.Item>
                    <u
                      className="bg-transparent border-0 text-start"
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={cursorstyle}
                    >
                      +Add Another
                    </u>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}

      {updateState === "bankInfo" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {" "}
            {pageName} /
          </button>{" "}
          <b>Edit Bank Information:</b>
          <Form
            className=""
            form={form}
            name="addemployeeform"
            layout="vertical"
            initialValues={{
              accountname: "",
              accountno: "",
              bankname: "",
              bankbranch: "",
              bankcountry: "",
              bankcode: "",
              bankbranchcode: "",
              bankswiftcode: "",
              nssf: "",
              krapin: "",
              nhif: "",
              helpmonthlybilling: "",
            }}
            onFinish={(values) => {
              handlesubmit();
            }}
          >
            <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="accountname"
                  label="Account Name"
                  rules={[
                    {
                      required: false,
                      message: "Account Name is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Account Name"
                    // maxLength={500}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="accountno"
                  label="Account Number"
                  rules={[
                    {
                      required: false,
                      message: "Account Number is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Account Number"
                    maxLength={25}
                    onChange={onFinish}
                    // onKeyDown={(e) => {
                    //   const allowedChars = /^[a-zA-Z\s]+$/;
                    //   if (!allowedChars.test(e.key)) {
                    //     e.preventDefault();
                    //   }
                    // }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="bankname"
                  label="Bank Name"
                  rules={[
                    {
                      required: false,
                      message: "Bank Name is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Bank Name"
                    onChange={onFinish}
                    // maxLength={500}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="bankbranch"
                  label="Branch"
                  rules={[
                    {
                      required: false,
                      message: "Branch is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Branch"
                    onChange={onFinish}
                    // maxLength={500}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="bankcountry"
                  label="Country"
                  rules={[{ required: false, message: "Country is required" }]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    {countryList?.map((country, index) => (
                      <Select.Option
                        key={index}
                        value={country}
                        label={country}
                      >
                        {country}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="bankcode"
                  label="Bank code"
                  rules={[
                    {
                      required: false,
                      message: "Bank code is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Bank code"
                    maxLength={25}
                    onChange={onFinish}
                    // onKeyDown={(e) => {
                    //   const allowedChars = /^[a-zA-Z\s]+$/;
                    //   if (!allowedChars.test(e.key)) {
                    //     e.preventDefault();
                    //   }
                    // }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="bankbranchcode"
                  label="Branch Code"
                  rules={[
                    {
                      required: false,
                      message: "Branch Code is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Branch Code"
                    maxLength={25}
                    onChange={onFinish}
                    // onKeyDown={(e) => {
                    //   const allowedChars = /^[a-zA-Z\s]+$/;
                    //   if (!allowedChars.test(e.key)) {
                    //     e.preventDefault();
                    //   }
                    // }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="bankswiftcode"
                  label="Swiftcode"
                  rules={[
                    {
                      required: false,
                      message: "Swiftcode is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Swiftcode"
                    maxLength={25}
                    onChange={onFinish}
                    // onKeyDown={(e) => {
                    //   const allowedChars = /^[a-zA-Z\s]+$/;
                    //   if (!allowedChars.test(e.key)) {
                    //     e.preventDefault();
                    //   }
                    // }}
                  />
                </Form.Item>
              </Col>
            </StyleRow>

            <b className="my-2 mx-1">Banking Information</b>
            <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="krapin" label="KRA PIN Number">
                  <Input
                    placeholder="KRA PIN Number"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="nssf" label="NSSF Number">
                  <Input
                    placeholder="NSSF Number"
                    maxLength={20}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="nhif" label="NHIF Number">
                  <Input
                    placeholder="NHIF Number"
                    maxLength={20}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="helpmonthlybilling"
                  label="HELB monthly billing (KES)"
                >
                  <Input
                    placeholder="HELB monthly billing (KES)"
                    maxLength={20}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </StyleRow>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}

      {updateState === "salaryInfo" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {" "}
            {pageName} /
          </button>{" "}
          <b>Edit Salary Information:</b>
          <Form
            className=""
            form={form}
            name="addemployeeform"
            layout="vertical"
            initialValues={{
              grossSalary: "",
              housingAllowance: "",
              medicalInsurance: "",
              pension: "",
              groupLifeInsurance: "",
              socialSecurityContribution: "",
              bonus: "",
              otherCosts: "",
              TotalEmploymentCost: "",
            }}
            onFinish={(values) => {
              handlesubmit();
            }}
          >
            <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="grossSalary"
                  label="Gross Salary"
                  normalize={(value) => formatNumber(parseNumber(value))}
                  rules={[
                    {
                      required: false,
                      message: "Gross Salary is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Gross Salary"
                    maxLength={10}
                    onKeyDown={handleKeyDown}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="housingAllowance"
                  label="Housing Allowance"
                  normalize={(value) => formatNumber(parseNumber(value))}
                  rules={[
                    {
                      required: false,
                      message: "Housing Allowance is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Housing Allowance"
                    maxLength={10}
                    onChange={onFinish}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="medicalInsurance"
                  label="Medical Insurance"
                  normalize={(value) => formatNumber(parseNumber(value))}
                  rules={[
                    {
                      required: false,
                      message: "Medical Insurance is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Medical Insurance"
                    onChange={onFinish}
                    maxLength={10}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="pension"
                  label="Pension"
                  normalize={(value) => formatNumber(parseNumber(value))}
                  rules={[
                    {
                      required: false,
                      message: "Pension is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Pension"
                    onChange={onFinish}
                    maxLength={10}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="groupLifeInsurance"
                  label="Group Life Insurance"
                  normalize={(value) => formatNumber(parseNumber(value))}
                  rules={[
                    {
                      required: false,
                      message: "Group Life Insurance is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Group Life Insurance"
                    maxLength={10}
                    onChange={onFinish}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="socialSecurityContribution"
                  label="Social Security contribution (employer)"
                  normalize={(value) => formatNumber(parseNumber(value))}
                  rules={[
                    {
                      required: false,
                      message: "Social Security contribution is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Social Security contribution"
                    maxLength={10}
                    onChange={onFinish}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="bonus"
                  label="Bonus"
                  normalize={(value) => formatNumber(parseNumber(value))}
                  rules={[
                    {
                      required: false,
                      message: "Bonus is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Bonus"
                    maxLength={10}
                    onChange={onFinish}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="otherCosts"
                  label="Other Costs"
                  normalize={(value) => formatNumber(parseNumber(value))}
                  rules={[
                    {
                      required: false,
                      message: "Other Costs is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Other Costs"
                    maxLength={10}
                    onChange={onFinish}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="totalEmploymentCost"
                  label="Total Employment cost"
                  normalize={(value) => formatNumber(parseNumber(value))}
                  rules={[
                    {
                      required: false,
                      message: "Total Employment cost is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Total Employment cost"
                    maxLength={15}
                    onChange={onFinish}
                    disabled
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </Col>
            </StyleRow>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {/* <button onClick={() => setUpdateState("")}>back</button> */}
    </EditProfileBox>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  roleList: getRoleList(state),
  ClientId: getClientid(state),
  bandList: getBandList(state),
  locationList: getLocations(state),
  supervisorList: getSupervisorList(state),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(EditProfile);
