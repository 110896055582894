import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
} from "antd";
import moment from "moment";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getManagingOffice, getUserInfo, getUserList } from "redux/selectors";
import { Required } from "./index.js";
import { useScrollFetch } from "hooks/useScrollFetch.js";
import queryKeys from "common/queryKey.js";
import { ButtonFilled } from "reusableComponent/UIButtons/index.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

const labelStyle = {
  width: "150px",
  display: "inline-block",
  textAlign: "start",
};

const StakeHolderFields = ({ update, engagementData, disabled }) => {
  const form = Form.useFormInstance();
  const history = useHistory();

  const userList = useSelector((state) => getUserList(state));
  const activeUserslist = userList.filter((item) => item.statusid === "1");
  const userInfo = useSelector(getUserInfo);

  const userListOptions = userList
    .filter(
      (user) =>
        user?.designation === "Managing Partner" ||
        user?.designation === "Partner" ||
        user?.designation === "Senior Partner"
    )
    .map((user) => ({
      label: user.fullname,
      value: user.userid,
    }));

  const allUserListOptions = activeUserslist.map((user) => {
    return {
      label: user.fullname,
      value: user.userid,
    };
  });
  const selectedPartner = form.getFieldValue("partner");

  const partnerCountry = activeUserslist?.find(
    (user) => user.userid === userInfo?.userid
  )?.managingoffice[0];

  const partnerGrade = ["P1", "P2", "P3", "P4", "P5"];

  const managerGrade = ["M1", "M2"];

  const partnerOptions = activeUserslist
    ?.filter((user) => {
      if (disabled) {
        return user;
      }

      return (
        user.managingoffice[0] === form.getFieldValue("managing_office") &&
        partnerGrade.some((grade) => grade === user.grade)
      );
    })
    .map((partner) => ({
      label: partner.fullname,
      value: partner.userid,
    }));

  const managerUserOptions = activeUserslist
    ?.filter((partner) => {
      if (disabled) {
        return partner;
      }
      return (
        partner.managingoffice[0] === form.getFieldValue("managing_office")
      );
      //  &&
      // managerGrade.includes(partner?.grade)
    })
    .map((partner) => ({
      label: partner.fullname,
      value: partner.userid,
    }));

  const countryBasedUserOptions = activeUserslist
    ?.filter(
      (partner) =>
        partner.managingoffice[0] === form.getFieldValue("managing_office") &&
        ![...managerGrade, ...partnerGrade].includes(partner?.grade)
    )
    .map((partner) => ({
      label: partner.fullname,
      value: partner.userid,
    }));

  const relationshipPartnerOptions = userListOptions.filter(
    (partner) => partner?.value !== selectedPartner
  );

  const manageoffice = useSelector((state) => getManagingOffice(state));
  const managinOfficeOptions = manageoffice.map((value) => {
    return {
      label: value,
      value: value,
    };
  });

  const { getAllClientsList } = queryKeys;

  const { key, url } = getAllClientsList;

  const {
    data: clientsDataRes,
    fetchNextPage,
    isLoading: loading,
    hasNextPage,
  } = useScrollFetch(key, {
    endPoint: url,
    queryParam: "LastEvaluatedKey",
  });

  const clients = useMemo(() => {
    return clientsDataRes?.pages?.reduce((acc, page) => {
      if (page?.data === undefined) return acc;
      return [...acc, ...page?.data];
    }, []);
  }, [clientsDataRes]);

  const clientOptions = clients
    ?.filter(
      (client) =>
        client?.bdo_id !== "NULL" && client?.bdo_strategicpartner === false
    )
    ?.map((client) => ({
      label: client?.registeredName,
      value: client?.clientId,
    }));

  const onScroll = async (event) => {
    const target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      console.log("Loading...");
      target.scrollTo(0, target.scrollHeight);
      hasNextPage && fetchNextPage();
    }
  };

  const enableIfActiveAndStakeHolder = () => {
    if (disabled) {
      const engagementStatus = engagementData?.jobCloser?.status;

      // const userDetails = userList.find(
      //   (val) => val?.userid === userInfo?.userid
      // );

      // const userManaginOffice = userDetails?.managingoffice?.[0];

      const isPartnerOrManagerOrHeadOfficeUser =
      userInfo?.userid === engagementData?.stakeHolder?.personIncharge ||
      userInfo?.userid === engagementData?.stakeHolder?.partner
      //|| userManaginOffice === "Head Office";

      
      const isEngagementActive = engagementStatus === "Active";

      return update ? !(isPartnerOrManagerOrHeadOfficeUser && isEngagementActive) : false;
    }
    return false;
  };

  const planEndDateDisable = enableIfActiveAndStakeHolder()

  const engagementStatus = engagementData?.jobCloser?.status;
  // TODO: conditionally disable the fields based on the status of the engagement

  return (
    <Card className="fields-card">
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label={
              <span style={labelStyle}>
                Partner <Required />
              </span>
            }
            name="partner"
            rules={[
              {
                required: true,
                message: "Please enter Partner!",
              },
            ]}
          >
            <Select
              placeholder="Select"
              filterOption={(input, option) =>
                (option?.label?.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              showSearch={true}
              options={partnerOptions}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            label={<span style={labelStyle}>QA Partner</span>}
            name="relationship_partner"
            rules={[
              {
                required: false,
                message: "Please enter QA Partner!",
              },
            ]}
          >
            <Select
              placeholder="Select"
              filterOption={(input, option) =>
                (option?.label?.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              showSearch={true}
              options={partnerOptions}
              disabled={disabled}
              allowClear={true}
            />
          </Form.Item>
          <Form.Item
            label={
              <span style={labelStyle}>
                Person Incharge <Required />
              </span>
            }
            name="person_incharge"
            rules={[
              {
                required: true,
                message: "Please enter Person Incharge!",
              },
            ]}
          >
            <Select
              placeholder="Select"
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label?.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              options={managerUserOptions}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            label={<span style={labelStyle}>IS Auditor</span>}
            name="isauditor"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter Person Incharge!",
            //   },
            // ]}
          >
            <Select
              placeholder="Select"
              // options={countryBasedUserOptions}
              allowClear={true}
              options={managerUserOptions}
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label?.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            label={<span style={labelStyle}>Referring BDO Firm</span>}
            name="referring_bdo_firm"
          >
            <Select
              placeholder="Select"
              options={clientOptions?.filter((item) =>
                item?.label?.includes("BDO")
              )}
              onPopupScroll={onScroll}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              allowClear={true}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            label={<span style={labelStyle}>Other side Entity Name</span>}
            name="other_side_entity_name"
          >
            <Select
              allowClear={true}
              id="client"
              placeholder="Select"
              options={clientOptions}
              onPopupScroll={onScroll}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              disabled={disabled}
            />
          </Form.Item>

          {/* <Form.Item name="sent_to_referaal" valuePropName="checked">
            <Checkbox>Deliverable to be sent to referral</Checkbox>
          </Form.Item> */}
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <span style={labelStyle}>
                Plan Start Date <Required />
              </span>
            }
            name="plan_start_date"
            rules={[
              {
                required: true,
                message: "Please enter Plan Start Date!",
              },
            ]}
          >
            <DatePicker
              placeholder="Select"
              // disabledDate={(current) => {
              // return current.isBefore(moment(), 'day'); // Disable all dates before today
              // }}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            label={
              <span style={labelStyle}>
                Plan End Date <Required />
              </span>
            }
            name="plan_end_date"
            rules={[
              {
                required: true,
                message: "Please enter Plan End Date!",
              },
            ]}
          >
            <DatePicker
              placeholder="Select"
              // disable the past days from Plan Start Date
              disabledDate={(current) => {
                const planStartDate = form.getFieldValue("plan_start_date");
                return current && current < moment(planStartDate);
              }}
              disabled={planEndDateDisable}
            />
          </Form.Item>
          {!update && (
            <div className="d-flex">
              <span style={labelStyle}></span>
              <Button
                className="ms-3"
                type="primary"
                onClick={() => {
                  history.push("/engagementCalendar");
                }}
              >
                Look Up
              </Button>
            </div>
          )}

          {/* <Form.Item
            label={<span style={labelStyle}>Client FY Start</span>}
            name="client_fy_start"
          >
            <DatePicker placeholder="Select" />
          </Form.Item>
          <Form.Item
            label={<span style={labelStyle}>Client FY End</span>}
            name="client_fy_end"
          >
            <DatePicker
              placeholder="Select"
              disabledDate={(current) => {
                const clientFyStart = form.getFieldValue("client_fy_start");
                return current && current < moment(clientFyStart);
              }}
            />
          </Form.Item> */}

          {/* <div class="text-center">
            <Button>Sub Engagement</Button>
          </div> */}
        </Col>
      </Row>
    </Card>
  );
};

export default StakeHolderFields;
