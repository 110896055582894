import { Breadcrumb, Form, Spin } from "antd";
import Title from "atoms/Title";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import moment from "moment";
import React, { useEffect } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import TimeSheetOverView from "./TimeSheetOverView";
import TimeSheet from "molecules/TimeSheet";
import { InitialTimeSheetDataFn as InitialTimeSheetFn } from "./InitialTimeSheetData";

const TimeSheetDetailed = () => {
  const history = useHistory();
  const { week, employeeId } = useParams();

  const currentWeek = moment(week?.split("-")?.[0], "YYYYMMMDD");

  const initialTimeSheet = InitialTimeSheetFn(currentWeek);

  const [timeSheet, setTimeSheet] = React.useState(initialTimeSheet);

  const { getTimesheet } = queryKeys;

  const { data: timesheetDataRes, isLoading } = useFetch(
    `${getTimesheet.key}-week:${week}`,
    `${getTimesheet.url}?week=${week}&employeeId=${employeeId}`,
    {
      enabled: !!week,
    }
  );

  const timeSheetData = timesheetDataRes?.response?.response || [];

  // const timeSheet =
  //   timeSheetData?.timeSheet || InitialTimeSheetDataFn(currentWeek);

  const [form] = Form.useForm();

  useEffect(() => {
    if (timesheetDataRes?.response?.response?.timeSheet) {
      setTimeSheet(timesheetDataRes?.response?.response?.timeSheet);
      form.setFieldsValue(timesheetDataRes?.response?.response?.timeSheet);
    } else {
      setTimeSheet(initialTimeSheet);
      form.setFieldsValue(initialTimeSheet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheetDataRes, form]);

  const startWeek = timeSheetData?.week?.split("-")[0];
  const endWeek = timeSheetData?.week?.split("-")[1];
  const formattedWeek = `${moment(startWeek, "YYYYMMMDD").format(
    "DD MMM YYYY"
  )} - ${moment(endWeek, "YYYYMMMDD").format("DD MMM YYYY")}`;

  return (
    <TimeSheetDetailedWrap>
      <Breadcrumb separator=">">
        <Breadcrumb.Item
          key={1}
          className="back"
          onClick={() => {
            history.push("/approvals", { tabId: "timesheet_approvals" });
          }}
        >
          Back
        </Breadcrumb.Item>

        <Breadcrumb.Item key={2}>
          {timeSheetData?.employeeName} Timesheet
        </Breadcrumb.Item>
      </Breadcrumb>

      <Spin spinning={isLoading}>
        <div className="header-nav">
          <div>Date: {formattedWeek}</div>
        </div>
        <div className="content">
          <Form form={form} initialValues={timeSheet} layout="vertical">
            <TimeSheetOverView timeSheet={timeSheet} />

            <Title className="mt-3">Details</Title>

            <TimeSheet editable={false} data={timeSheet} approval={true} employeeId={employeeId} />
          </Form>
        </div>
      </Spin>
    </TimeSheetDetailedWrap>
  );
};

export default TimeSheetDetailed;

const TimeSheetDetailedWrap = styled.div`
  .header-nav {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .action-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;

    svg {
      cursor: pointer;
    }
  }
  .content {
    margin-top: 1rem;
  }
  .ant-breadcrumb-link {
    font-weight: 600;
  }
  .back {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px !important;
    color: black !important;
    text-decoration: underline;
  }
  table {
    box-shadow: 0px 3px 6px #00000015;
  }
  .timesheet-day {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
  }
  .timesheet-date {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    opacity: 0.75;
  }
`;
