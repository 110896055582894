import React, { useEffect, useState } from "react";
import { Checkbox, Modal, Table } from "antd";
import styled from "styled-components";
import Button from "atoms/Button";
import moment from "moment";
import { generateNiceWeekFromWeek } from "pages/TimeSheet/useWeekData";

export const ViewClaims = ({
  isModalVisible,
  setIsModalVisible,
  addClaims,
  setaddClaims,
  viewClims,
  claimsDataSource,
  isViewClims,
  update
}) => {
  const [selectedKeys, setSelectedKeys] = useState(new Set());

  useEffect(() => {
    if (update) {
      const keysToSelect = new Set(
        addClaims?.map(claim => claim.key)
      );
      setSelectedKeys(keysToSelect);
    } 
  }, [update, addClaims]);

  const claimsColumns = [
    !isViewClims
      ? {
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (_, record) => (
            <Checkbox
              checked={selectedKeys.has(record.key)}
              onChange={(e) => handleCheckboxChange(e, record.key)}
            />
          ),
        }
      : null,
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "VAT/Non- VAT",
      dataIndex: "vat",
      key: "vat",
      render:(_,record) => (
        <>{record.vat === true ? "VAT" : "NON-VAT" }</>
      )
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => (
        <>${record.amount}</>
      )
    },
    {
      title: "Submitted By",
      dataIndex: "submittedby",
      key: "submittedby",
    },
    {
      title: "Timesheet Week",
      dataIndex: "week",
      key: "week",
      render:(_, record) => (
        <span style={{color:"#397EF3"}}>{record.week?generateNiceWeekFromWeek(record.week):"-"}</span>
        
      )
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (value) =>
        value ? moment(value).format("DD-MM-YYYY") : "",
    },
  ].filter(Boolean);

  const handleCheckboxChange = (e, key) => {
    setSelectedKeys((prevSelectedKeys) => {
      const newSelectedKeys = new Set(prevSelectedKeys);
      if (e.target.checked) {
        newSelectedKeys.add(key);
      } else {
        newSelectedKeys.delete(key);
      }
      return newSelectedKeys;
    });
  };

  const claimsData = claimsDataSource
  ?.filter((record) => record.type !== "Subcontract")
  ?.map((record, index) => ({
    ...record,
    key: index,
  }));

  const handleSave = () => {
    const selectedRecords = claimsData.filter((record) =>
      selectedKeys.has(record.key)
    );
    setaddClaims(selectedRecords);
    handleCancel();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <StyledClaimsModal
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={false}
    >
      <h6>Select Claims:</h6>
      <Table
        dataSource={claimsData}
        columns={claimsColumns}
        rowKey={(record) => record.key}
      />
      {!isViewClims && (
        <div className="text-end mt-2">
          <Button
            type="primary"
            className="cursor-pointer"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="cursor-pointer"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      )}
    </StyledClaimsModal>
  );
};

const StyledClaimsModal = styled(Modal)`
  width: 1500px !important;
  z-index: 10;
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
  .ant-modal-body {
    overflow-y: auto;
  }
  ul.ant-pagination {
    display: none;
  }
  .ant-modal-close-x {
    display: none;
  }
`;
