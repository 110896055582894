import { getUserInfo } from "redux/selectors";
import { useSelector } from "react-redux";
import { useUpdate } from "hooks/useUpdate";
import { notification, Spin } from "antd";
import queryKeys from "common/queryKey";
import moment from "moment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useQueryState } from "hooks/useQueryState";
import { useQueryClient } from "react-query";

const ApproveActionButton = ({ index }) => {
  const userInfo = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();

  const { updateTimesheet, getTimesheet } = queryKeys;

  const { week } = useParams();

  const { data: timesheetDataRes } = useQueryState(
    `${queryKeys.getTimesheet.key}-week:${week}`
  );

  const timesheetData = timesheetDataRes?.response?.response || {};
  
  const approveTimesheet = () => {
    approveUpdate(
      {
        ...timesheetData,
        updatedTimeSheet: timesheetData.timeSheet
          .filter((_item, idx) => idx === index)
          .map((ts) => {
            return {
              ...ts,
              status: "approved",
              approval: {
                by: userInfo.employer_id,
                date: moment().format("DD-MM-YYYY"),
              },
            };
          }),
        timeSheet: timesheetData.timeSheet.map((ts, idx) => {
          if (idx === index) {
            return {
              ...ts,
              status: "approved",
              approval: {
                by: userInfo.employer_id,
                date: moment().format("DD-MM-YYYY"),
              },
            };
          }
          return ts;
        }),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`${getTimesheet.key}-week:${week}`);
          notification.success({
            message: "Timesheet Approved Successfully",
          });
        },
      }
    );
  };

  const { mutate: approveUpdate, isLoading: isApproveUpdating } = useUpdate(
    updateTimesheet.key,
    updateTimesheet.url
  );

  return (
    <Spin spinning={isApproveUpdating}>
      <CheckCircleOutlineIcon
        onClick={(e) => {
          e.stopPropagation();
          approveTimesheet();
        }}
      />
    </Spin>
  );
};

export default ApproveActionButton;
