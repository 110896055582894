import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { Spin, Table } from "antd";
import queryKeys from "common/queryKey";
import { useScrollFetchPost2 } from "hooks/useScrollFetchPost2";
import { getUserInfo } from "redux/selectors";
import { useSelector } from "react-redux";
import { generateNiceWeekFromWeek } from "./useWeekData";
import { APPROVED, DECLINED, DRAFT, ONGOING, PENDING, WITHDRAWN } from ".";
import { sumBy } from "lodash";

const TimeSheetHistory = ({ handleClickTimeSheet }) => {
  const historyColumns = [
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
    },
    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
    },
    {
      title: "Rejected",
      dataIndex: "rejected",
      key: "rejected",
    },
    {
      title: "Pending for Approval",
      dataIndex: "pending_approval",
      key: "pending_approval",
    },
    {
      title: "Total Hours",
      dataIndex: "total_hours",
      key: "total_hours",
    },
    {
      title: "Total Claims",
      dataIndex: "total_claims",
      key: "total_claims",
    },
  ];

  const { getAdminApprovalTimesheet } = queryKeys;

  const { key, url } = getAdminApprovalTimesheet("all");

  const userInfo = useSelector(getUserInfo);

  const { data, isLoading: isHistoryLoading } = useScrollFetchPost2(key, {
    endPoint: url,
    queryParam: "LastEvaluatedKey",
    extra: {
      weeks: [],
      // statusArray: ["pending", "approved", "declined", "ongoing", "withdrawn"],
      statusArray: [PENDING, APPROVED, DECLINED, ONGOING, WITHDRAWN, DRAFT],
      employeeId: [userInfo.userid],
    },
  });

  const timesheetsData = useMemo(() => {
    if (!data) return [];
    return data?.pages?.reduce((acc, page) => {
      if (page?.response?.response === undefined) return acc;
      return [...acc, ...page?.response?.response];
    }, []);
  }, [data]);

  const [timeSheets, setTimeSheets] = React.useState([]);

  useEffect(() => {
    if (timesheetsData) {
      const data = timesheetsData
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      .map((timesheet) => {
        const timeSheet = timesheet?.timeSheet || [];

        const totalHours = timeSheet.reduce((acc, section) => {
          return (
            acc +
            (Number(section.mon) || 0) +
            (Number(section.tue) || 0) +
            (Number(section.wed) || 0) +
            (Number(section.thu) || 0) +
            (Number(section.fri) || 0) +
            (Number(section.sat) || 0) +
            (Number(section.sun) || 0)
          );
        }, 0);

        const totalClaims = timeSheet.reduce((acc, section) => {
          return acc + Number(
              sumBy(section.claims, (claim) => parseInt(claim.amount))
            );
        }, 0);

        const approved = timeSheet.filter(
          (section) => section.status === "approved"
        );

        const approvedHours = approved.reduce((acc, section) => {
          return (
            acc +
            (Number(section.mon) || 0) +
            (Number(section.tue) || 0) +
            (Number(section.wed) || 0) +
            (Number(section.thu) || 0) +
            (Number(section.fri) || 0) +
            (Number(section.sat) || 0) +
            (Number(section.sun) || 0)
          );
        }, 0);

        const rejected = timeSheet.filter(
          (section) => section.status === "declined"
        );

        const rejectedHours = rejected.reduce((acc, section) => {
          return (
            acc +
            (Number(section.mon) || 0) +
            (Number(section.tue) || 0) +
            (Number(section.wed) || 0) +
            (Number(section.thu) || 0) +
            (Number(section.fri) || 0) +
            (Number(section.sat) || 0) +
            (Number(section.sun) || 0)
          );
        }, 0);

        return {
          period: timesheet?.week
            ? generateNiceWeekFromWeek(timesheet?.week)
            : "-",
          approved: approvedHours,
          rejected: rejectedHours,
          pending_approval: totalHours - approvedHours - rejectedHours,
          total_hours: totalHours,
          total_claims: totalClaims || "0",
        };
      });
      setTimeSheets(data);
    }
  }, [timesheetsData]);

  return (
    <HistoryWrap>

      <Spin spinning={isHistoryLoading}>
        <HistoryTable
          columns={historyColumns}
          dataSource={timeSheets}
          bordered={true}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                handleClickTimeSheet(record?.period);
              },
            };
          }}
        />
      </Spin>
    </HistoryWrap>
  );
};

export default TimeSheetHistory;

const HistoryWrap = styled.div`
  .main-back {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    .backicon {
      font-size: 16px;
    }
  }
`;

const HistoryTable = styled(Table)`
  .ant-table-cell.nowrap {
    white-space: nowrap;
  }
  .ant-table-cell.border-right-none {
    border-right: none !important;
  }
  .ant-table-cell.border-bottom-none {
    border-bottom: none;
  }
  .ant-table-cell {
    padding: 0.5rem 0.75rem;
  }
  .ant-table-cell-row-hover {
    cursor: pointer;
    background-color: #efefef;
  }
`;
