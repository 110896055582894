import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Checkbox,
  Switch,
  Upload,
  Space,
  TimePicker,
} from "antd";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Button from "atoms/Button";
import { CALL_API, CALL_FEATCH_API } from "common/API";
import moment from "moment";
import {
  NON_EMPTY_REGEX,
  EMAIL_REGEX,
  STATUS_CODE,
  countryList,
  CompetencyQC,
  ServiceLine,
  SubServiceLine,
  CompetencyRole,
  IndustryRole,
  Industry,
  Grade,
  Greetings,
  Degree,
  ProfessionalCertification,
  CountryCompany,
  Sector,
  SUPERVISOR,
  AwardList,
  MANAGER,
  secondaryIndustry,
  ContractingCompany,
  RegionalCometencyRole,
} from "common/Constants";
import SelectTable from "../molecules/SelectTable";
import InReview from "../organisms/FormReview";
import { bindActionCreators } from "redux";
import {
  getAllDepartmentList,
  getLocations,
  getClientid,
  getLeaveCategoryList,
  getRoleList,
  getUserList,
  getBandList,
  getSupervisorList,
} from "redux/selectors";
import { setUserList } from "redux/actions";
import {
  chain,
  concat,
  filter,
  find,
  flatMap,
  get,
  includes,
  intersectionBy,
  isEmpty,
} from "lodash";
import { useDropzone } from "react-dropzone";
import StepsProgress from "./StepsProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormModal from "./FormModal";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { allowOnlyNumbers } from "utils/functions";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";

const StyledModal = styled(Form)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    color: #1b1b1b;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-close {
    margin-top: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #26a4ff;
    color: #ffffff;
  }
  .check-box {
    font-size: 14px;
    font-weight: 500;
  }
  .add_employee_col {
    padding: 5px;
  }
  .review_data_box_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: wrap;
  }
  .review_data_box {
    width: auto;
    padding: 1rem 1.4rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000015;
  }
  .review_datas {
    display: flex;
    justify-content: space-between;
    text-align: left;
    gap: 1rem;
  }
  .review_datas_key {
    text-align: left;
    font: normal normal bold 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
  .review_datas_value {
    text-align: left;
    font: normal normal normal 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
  .review_title {
    border-bottom: 1.5px solid #d9d9d9;
    width: fit-content;
    text-align: left;
    font: normal normal Bold 18px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
`;
const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
`;

const StyleRow = styled(Row)`
  padding: 10px;
  box-shadow: 0px 3px 6px #00000015;
  .ant-form-item-label {
    text-align: left;
  }
  .ant-picker {
    width: 100% !important;
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background: #f2f2f2;
    }
    /* .anticon svg {
    color: #397FF4;
} */
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 40px;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }

  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #02b209 !important;
      border-color: #02b209 !important;
    }
  }
`;

const StyledDropzone = styled.div`
  text-align: center;
  width: 100%;
  height: 40px;
  background: #e3e3e3;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    padding-top: 10px;
  }
`;

function generateUniqueId() {
  // Generate a random number and convert it to base 36 (numbers + letters)
  const randomNumber = Math.random().toString(36).substr(2, 9);

  // Get the current timestamp
  const timestamp = Date.now();

  // Combine the random number with the timestamp
  const uniqueId = randomNumber + timestamp;

  return uniqueId;
}

function AddEmployee({
  closeModal,
  getEmployeeList,
  EmployeeList,
  ClientId,
  updateUserList,
  roleList,
  bandList,
  locationList,
  userList,
  supervisorList,
  selectedDetails = {},
  defaultStep = 0,
}) {
  const filteredLocation = locationList?.filter(
    (item) => item.login_from_anywhere !== 1
  );

  const [showLoader, toggleLoader] = useState(false);
  const [filename, setFileName] = useState("");
  const [form] = Form.useForm();
  const { Option } = Select;
  const containerRef = useRef(null);
  const [profileUploaded, setProfileUploaded] = useState(false);
  const [uniqueId, setUniqueId] = useState(generateUniqueId());
  const [storeId, setStoreId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [baseLocation, setBaseLocation] = useState("");
  const [selectAllRoleChecked, setSelectAllRoleChecked] = useState(false);
  const [serialNo, setSerialNo] = useState("");

  const locationId = chain(roleList)
    .filter((role) => selectedRoleId.includes(role.roleId))
    .flatMap((role) => role.locationId)
    .uniq()
    .value();

  const locationBasedOnRole = locationList?.filter(
    (val) =>
      locationId.includes(val?.location_id) && val?.login_from_anywhere !== 1
  );

  const [employeeStatusId, setEmployeeStatusId] = useState("1");
  const filterDepartment = roleList?.find((item) =>
    form.getFieldValue("role_id")?.includes(item?.roleId)
  )?.department;

  const [emailsList, setEmailList] = useState([]);

  const getEmailList = async () => {
    const response = await CALL_API(`add-users/email-list`, "get");
    if (response.code === STATUS_CODE.SUCCESS) setEmailList(response.response);
  };

  const getSerialNo = async () => {
    const response = await CALL_API(`add-users/serialno`, "get");
    if (response.code === STATUS_CODE.SUCCESS) setSerialNo(response.serialno);
  };

  useEffect(() => {
    setSelectAllRoleChecked(selectedRoleId.length === roleList.length);
  }, [selectedRoleId, selectAllRoleChecked, roleList]);

  useEffect(() => {
    setProfileUploaded(false);
    getEmailList();
    // getSerialNo();
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedDetails)) {
      const educationdetails = selectedDetails?.educationdetails.map(
        (detail) => ({
          ...detail,
          todate: detail.todate ? moment(detail.todate) : null,
          fromdate: detail.fromdate ? moment(detail.fromdate) : null,
        })
      );

      const practicaltrainings = selectedDetails?.practicaltrainings.map(
        (detail) => ({
          ...detail,
          todate: detail.todate ? moment(detail.todate) : null,
          fromdate: detail.fromdate ? moment(detail.fromdate) : null,
        })
      );

      const professionalcertifications =
        selectedDetails?.professionalcertifications.map((detail) => ({
          ...detail,
          todate: detail.todate ? moment(detail.todate) : null,
          fromdate: detail.fromdate ? moment(detail.fromdate) : null,
        }));

      const workexperience = selectedDetails?.workexperience.map((detail) => ({
        ...detail,
        todate: detail.todate ? moment(detail.todate) : null,
        fromdate: detail.fromdate ? moment(detail.fromdate) : null,
      }));

      let values = {
        user_id: selectedDetails?.userid,
        gender: selectedDetails?.gender,
        nssf: selectedDetails?.nssf,
        national_id: selectedDetails?.national_id,
        phoneno: selectedDetails?.phoneno,
        physicaladdress: selectedDetails?.physicaladdress,
        maritalstatus: selectedDetails?.maritalstatus,
        country: selectedDetails?.country,
        nationality: selectedDetails?.nationality,
        dateofbirth: selectedDetails?.dateofbirth
          ? moment(selectedDetails?.dateofbirth, "YYYY-MM-DD")
          : null,
        emergencycontactname: selectedDetails?.emergencycontactname,
        emergencycontactnumber: selectedDetails?.emergencycontactnumber,
        firstname: selectedDetails?.firstname,
        lastname: selectedDetails?.lastname,
        salutation: selectedDetails?.salutation,
        greetings: selectedDetails?.greetings,
        dependent: selectedDetails?.dependent,
        educationdetails: educationdetails,
        workexperience: workexperience,
        professionalcertifications: professionalcertifications,
        practicaltrainings: practicaltrainings,
        othernames: selectedDetails?.othernames,
        postaladdress: selectedDetails?.postaladdress,
        workpermitno: selectedDetails?.workpermitno,
        workpermitexpirydate: selectedDetails?.workpermitexpirydate
          ? moment(selectedDetails?.workpermitexpirydate)
          : null,
        nextofkinrelationship: selectedDetails?.nextofkinrelationship,
        nextofkinpostaladdress: selectedDetails?.nextofkinpostaladdress,
        accountno: selectedDetails?.accountno,
        accountname: selectedDetails?.accountname,
        bankname: selectedDetails?.bankname,
        bankbranch: selectedDetails?.bankbranch,
        bankcountry: selectedDetails?.bankcountry,
        bankcode: selectedDetails?.bankcode,
        bankbranchcode: selectedDetails?.bankbranchcode,
        bankswiftcode: selectedDetails?.bankswiftcode,
        krapin: selectedDetails?.krapin,
        nhif: selectedDetails?.nhif,
        // onboardingdate: signdate
      };
      setFormValues(values);
      form.setFieldsValue(values);
    }
  }, [selectedDetails]);

  const emailList = EmployeeList?.map((user) => user.userid);
  const filteredUserName = userList
    ?.filter((item) => item.roleid.includes("4"))
    ?.map((item) => item.fullname);

  const [step, setStep] = useState(defaultStep);
  const [formValues, setFormValues] = useState({});

  const [reviewBasicData, setReviewBasicData] = useState({});
  const [reviewEducationData, setReviewEducationData] = useState({});
  const [reviewWorkData, setReviewWorkData] = useState({});
  const [managingOfficeData, setManagingOfficeData] = useState([]);
  const [organisationLevelData, setOrganisationLevelData] = useState([]);
  const [competencyQCData, setCompetencyQCfetchData] = useState([]);
  const [servicelineData, setServicelinefetchData] = useState([]);
  const [subservicelineData, setsubservicelineData] = useState([]);
  const [competencyroleData, setcompetencyroleData] = useState([]);
  const [departmentLevelData, setDepartmentLevelData] = useState([]);

  const fetchData = async () => {
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level1`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setManagingOfficeData(result);
    }
  };

  const organisationLevelfetchData = async () => {
    const filtervalue = managingOfficeData.filter((item) =>
      form.getFieldValue("managingoffice").includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setOrganisationLevelData(result);
      if (isEmpty(result)) {
        form.setFieldValue("organisationlevel", []);
        form.setFieldValue("competencyQC", []);
        form.setFieldValue("serviceline", []);
        form.setFieldValue("subserviceline", []);
        form.setFieldValue("leveldepartmentname", []);
      } else {
        // organisationlevel
        const organisationlevelList = filter(result, (item) =>
          form.getFieldValue("managingoffice").includes(item.parentLevel)
        );
        const organisationlevel = filter(
          form.getFieldValue("organisationlevel"),
          (value) => find(organisationlevelList, { value: value })
        );
        form.setFieldValue("organisationlevel", organisationlevel);
        // competencyQC

        const competencyQClevelList = filter(competencyQCData, (item) =>
          form.getFieldValue("organisationlevel").includes(item.parentLevel)
        );
        setCompetencyQCfetchData(competencyQClevelList);
        const competencyQClevel = filter(
          form.getFieldValue("competencyQC"),
          (value) => find(competencyQClevelList, { value: value })
        );
        form.setFieldValue("competencyQC", competencyQClevel);
        // serviceline
        const servicelinelevelList = filter(servicelineData, (item) =>
          form.getFieldValue("competencyQC").includes(item.parentLevel)
        );
        setServicelinefetchData(servicelinelevelList);
        const serviceline = filter(form.getFieldValue("serviceline"), (value) =>
          find(servicelinelevelList, { value: value })
        );
        form.setFieldValue("serviceline", serviceline);
        // subserviceline
        const subservicelinelevelList = filter(subservicelineData, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        setsubservicelineData(subservicelinelevelList);
        const subserviceline = filter(
          form.getFieldValue("subserviceline"),
          (value) => find(subservicelinelevelList, { value: value })
        );
        form.setFieldValue("subserviceline", subserviceline);
        //  // competencyrole
        //  const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        //  setcompetencyroleData(competencyrolelevelList)
        //  const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        //  form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };

  const competencyQCfetchData = async () => {
    const filtervalue = managingOfficeData.filter((item) =>
      form.getFieldValue("managingoffice").includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setCompetencyQCfetchData(result);
      if (isEmpty(result)) {
        form.setFieldValue("competencyQC", []);
        form.setFieldValue("serviceline", []);
        form.setFieldValue("subserviceline", []);
        form.setFieldValue("competencyrole", []);
        form.setFieldValue("leveldepartmentname", []);
      } else {
        // competencyQC

        const competencyQClevelList = filter(result, (item) =>
          form.getFieldValue("organisationlevel")?.includes(item.parentLevel)
        );
        const competencyQClevel = filter(
          form.getFieldValue("competencyQC"),
          (value) => find(competencyQClevelList, { value: value })
        );
        form.setFieldValue("competencyQC", competencyQClevel);
        // serviceline
        const servicelinelevelList = filter(servicelineData, (item) =>
          form.getFieldValue("competencyQC")?.includes(item.parentLevel)
        );
        setServicelinefetchData(servicelinelevelList);
        const serviceline = filter(form.getFieldValue("serviceline"), (value) =>
          find(servicelinelevelList, { value: value })
        );
        form.setFieldValue("serviceline", serviceline);
        // subserviceline
        const subservicelinelevelList = filter(subservicelineData, (item) =>
          form.getFieldValue("serviceline")?.includes(item.parentLevel)
        );
        setsubservicelineData(subservicelinelevelList);
        const subserviceline = filter(
          form.getFieldValue("subserviceline"),
          (value) => find(subservicelinelevelList, { value: value })
        );
        form.setFieldValue("subserviceline", subserviceline);
        // competencyrole
        // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        // setcompetencyroleData(competencyrolelevelList)
        // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        // form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };

  const servicelinefetchData = async () => {
    const filtervalue = competencyQCData.filter((item) =>
      form.getFieldValue("competencyQC")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level3&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setServicelinefetchData(result);
      if (isEmpty(result)) {
        form.setFieldValue("serviceline", []);
        form.setFieldValue("subserviceline", []);
        form.setFieldValue("leveldepartmentname", []);
      } else {
        // serviceline
        const servicelinelevelList = filter(result, (item) =>
          form.getFieldValue("competencyQC").includes(item.parentLevel)
        );
        const serviceline = filter(form.getFieldValue("serviceline"), (value) =>
          find(servicelinelevelList, { value: value })
        );
        form.setFieldValue("serviceline", serviceline);
        // subserviceline
        const subservicelinelevelList = filter(subservicelineData, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        setsubservicelineData(subservicelinelevelList);
        const subserviceline = filter(
          form.getFieldValue("subserviceline"),
          (value) => find(subservicelinelevelList, { value: value })
        );
        form.setFieldValue("subserviceline", subserviceline);
        // // competencyrole
        // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        // setcompetencyroleData(competencyrolelevelList)
        // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        // form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };

  const subservicelinefetchData = async () => {
    const filtervalue = servicelineData?.filter((item) =>
      form.getFieldValue("serviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level4&parentLevel=[${mapdata}]`,
      "get"
    );

    if (!isEmpty(response)) {
      const result = response?.data?.flatMap((item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setsubservicelineData(result);
      if (isEmpty(result)) {
        form.setFieldValue("leveldepartmentname", []);
      } else {
        // subserviceline
        const subservicelinelevelList = filter(result, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        // // competencyrole
        // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        // setcompetencyroleData(competencyrolelevelList)
        // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        // form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };
  const departmentfetchData = async () => {
    const filtervalue = subservicelineData?.filter((item) =>
      form.getFieldValue("subserviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level5&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setDepartmentLevelData(result);
      if (isEmpty(result)) {
        form.setFieldValue("competencyrole", []);
      } else {
        // subserviceline
        const subservicelinelevelList = filter(result, (item) =>
          form.getFieldValue("serviceline").includes(item.parentLevel)
        );
        // // competencyrole
        // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
        // setcompetencyroleData(competencyrolelevelList)
        // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
        // form.setFieldValue('competencyrole', competencyrole);
      }
    }
  };
  const competencyrolefetchData = async () => {
    const filtervalue = subservicelineData.filter((item) =>
      form.getFieldValue("subserviceline")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level6&parentLevel=[${mapdata}]`,
      "get"
    );
    // if (!isEmpty(response)) {
    //   const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value, parentLevel: item.parentLevel })));
    //   setcompetencyroleData(result)
    //   if (isEmpty(result)) {
    //     form.setFieldValue('competencyrole', []);
    //   } else {
    //     // competencyrole
    //     const competencyrolelevelList = filter(result, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
    //     const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
    //     form.setFieldValue('competencyrole', competencyrole);
    //   }
    // }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      updateUserList(userList);
    }
  };
  const validateEmployeeId = (rule, value) => {
    if (value && emailsList.includes(value) && isEmpty(selectedDetails)) {
      return Promise.reject("User ID already exist");
    }
    return Promise.resolve();
  };

  // Note: params needed
  const handlesubmit = async (values) => {
    try {
      toggleLoader(true);
      setUniqueId(generateUniqueId());
      const submitedValues = form.getFieldValue();
      const {
        firstname,
        lastname,
        salutation,
        othernames,
        dateofbirth,
        gender,
        dependent,
        maritalstatus,
        phoneno,
        user_id,
        postaladdress,
        physicaladdress,
        password,
        country,
        greetings,
        national_id,
        nationality,
        workpermitno,
        workpermitexpirydate,
        emergencycontactname,
        emergencycontactnumber,
        nextofkinrelationship,
        nextofkinpostaladdress,
        educationdetails,
        practicaltrainings,
        professionalcertifications,
        workexperience,
        accountname,
        accountno,
        bankname,
        bankbranch,
        bankcountry,
        bankcode,
        bankbranchcode,
        bankswiftcode,
        nssf,
        krapin,
        nhif,
        helpmonthlybilling,
        managingoffice,
        competencyQC,
        serviceline,
        subserviceline,
        leveldepartmentname,
        competencyrole,
        levellocationid,
        oversight,
        supervisor,
        companyname,
        industry,
        secondaryIndustry,
        sector,
        expertise,
        industryrole,
        regionalsupervisor,
        regionalcompetencyrole,
        officesupervisor,
        engagementsupervisor,
        designation,
        grade,
        targetchargeability,
        chargeoutrate,
        lastpromotionlevel,
        lastpromotion,
        jobtitle,
        professionalsince,
        hireddate,
        employeesponser,
        employee_status,
        role_id,
        baselocationid,
        shiftstarttime,
        shiftendtime,
      } = formValues;

      const roleNames = roleList
        .filter((item) => role_id?.includes(item.roleId))
        .map((item) => item.roleName);

      let userId = form.getFieldValue("user_id");
      let splitUserId = userId.split("@");
      let userIdWithoutDomain = splitUserId[0];
      let updatedUserId = userIdWithoutDomain + storeId;
      const dobDate = new Date(dateofbirth);
      const currentDate = new Date();
      const agecalculte = currentDate.getFullYear() - dobDate.getFullYear();
      // const bandNameList = bandList?.filter((item) => item?.bandId === bandid);

      const workexperienceDetails = workexperience?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = fromdate
          ? moment(fromdate).format("YYYY-MM-DD")
          : null;
        const formattedToDate = todate
          ? moment(todate).format("YYYY-MM-DD")
          : null;
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });

      const transformedEducationDetails = educationdetails?.map((item) => {
        const { attachment, fromdate, todate, ...rest } = item;
        const formattedFromDate = fromdate
          ? moment(fromdate).format("YYYY-MM-DD")
          : null;
        const formattedToDate = todate
          ? moment(todate).format("YYYY-MM-DD")
          : null;
        const updatedAttachment = attachment?.map((att) => ({
          name: att?.name,
          uniqueId: att?.response?.uniqueId
            ? att?.response?.uniqueId
            : att?.uniqueId,
        }));
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
          attachment: updatedAttachment,
        };
      });

      const practicaltrainingsDetails = practicaltrainings?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = fromdate
          ? moment(fromdate).format("YYYY-MM-DD")
          : null;
        const formattedToDate = todate
          ? moment(todate).format("YYYY-MM-DD")
          : null;
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });
      const professionalcertificationsDetails = professionalcertifications?.map(
        (item) => {
          const { attachment, fromdate, todate, ...rest } = item;
          const formattedFromDate = fromdate
            ? moment(fromdate).format("YYYY-MM-DD")
            : null;
          const formattedToDate = todate
            ? moment(todate).format("YYYY-MM-DD")
            : null;
          const updatedAttachment = attachment?.map((att) => ({
            name: att?.name,
            uniqueId: att?.response?.uniqueId
              ? att?.response?.uniqueId
              : att?.uniqueId,
          }));
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
            attachment: updatedAttachment,
          };
        }
      );

      const response = await CALL_API(`add-users/${ClientId}`, isEmpty(selectedDetails) ? "post" : "patch", {
        full_name: `${firstname} ${lastname}`,
        password: submitedValues?.password,
        user_id: user_id,
        role_id: role_id,
        role_name: roleNames,
        start_date: hireddate
          ? moment(hireddate._d).format("YYYY-MM-DD")
          : "",
        gender: gender,
        status_id: employeeStatusId,
        profile_url: profileUploaded
          ? `https://insyts-bdo-profiles-live.s3.ap-south-1.amazonaws.com/${ClientId}/${updatedUserId}/${storeId}.jpg`
          : null,
        nssf: nssf,
        national_id: national_id,
        phoneno: phoneno,
        physicaladdress: physicaladdress,
        professionalsince: professionalsince
          ? moment(professionalsince._d).format("YYYY-MM-DD")
          : "",
        hireddate: hireddate
          ? moment(hireddate._d).format("YYYY-MM-DD")
          : "",
        statusname: employee_status,
        employeesponser: employeesponser,
        age: agecalculte,
        maritalstatus: maritalstatus,
        country: country,
        nationality: nationality,
        companyname: companyname,
        jobtitle: jobtitle,
        dateofbirth: dateofbirth,
        emergencycontactname: emergencycontactname,
        emergencycontactnumber: emergencycontactnumber,
        baselocationid: baselocationid,
        employeeid: `EA-${formValues?.managingoffice[0]
          ?.slice(0, 2)
          ?.toUpperCase()}-${serialNo}`,
        firstname: firstname,
        lastname: lastname,
        salutation: salutation,
        competencyQC: competencyQC,
        serviceline: serviceline,
        subserviceline: subserviceline,
        competencyrole: competencyrole,
        industry: industry,
        sector: sector,
        industryrole: industryrole,
        expertise: expertise,
        designation: designation,
        grade: grade,
        targetchargeability: targetchargeability,
        chargeoutrate: chargeoutrate,
        greetings: greetings,
        lastpromotionlevel: lastpromotionlevel
          ? moment(lastpromotionlevel._d).format("YYYY-MM-DD")
          : "",
        dependent: dependent,
        educationdetails: transformedEducationDetails,
        workexperience: workexperienceDetails,
        professionalcertifications: professionalcertificationsDetails,
        practicaltrainings: practicaltrainingsDetails,
        managingoffice: managingoffice,
        supervisor: get(
          find(supervisorList, { employeeId: supervisor }),
          "name",
          null
        ),
        lastpromotion: lastpromotion,
        levellocationid: levellocationid,
        oversight: oversight,
        secondaryindustry: secondaryIndustry,
        leveldepartmentname: leveldepartmentname,
        regionalsupervisor:
          regionalsupervisor !== "none"
            ? get(
              find(supervisorList, { employeeId: regionalsupervisor }),
              "name",
              null
            )
            : "none",
        officesupervisor:
          officesupervisor !== "none"
            ? get(
              find(supervisorList, { employeeId: officesupervisor }),
              "name",
              null
            )
            : "none",
        engagementsupervisor: get(
          find(supervisorList, { employeeId: engagementsupervisor }),
          "name",
          null
        ),
        OfficeSupervisorId: officesupervisor,
        RegionalSupervisorId: regionalsupervisor,
        othernames: othernames,
        postaladdress: postaladdress,
        workpermitno: workpermitno,
        workpermitexpirydate: workpermitexpirydate
          ? moment(workpermitexpirydate._d).format("YYYY-MM-DD")
          : "",
        nextofkinrelationship: nextofkinrelationship,
        nextofkinpostaladdress: nextofkinpostaladdress,
        accountno: accountno,
        accountname: accountname,
        bankname: bankname,
        bankbranch: bankbranch,
        bankcountry: bankcountry,
        bankcode: bankcode,
        bankbranchcode: bankbranchcode,
        bankswiftcode: bankswiftcode,
        krapin: krapin,
        nhif: nhif,
        helpmonthlybilling: helpmonthlybilling,
        shiftstarttime: shiftstarttime ? moment(shiftstarttime).format("HH:mm") : "",
        shiftendtime: shiftendtime ? moment(shiftendtime).format("HH:mm") : "",
        regionalcompetencyrole: regionalcompetencyrole,
        onboardtype: true
      });

      toggleLoader(false);
      setFileName("");
      closeModal();

      if (response.code === "SUCCESS") {
        form.resetFields();
        if (response?.message === "Please Upgrade Your Plan") {
          message.error(response?.message);
        } else {
          message.success(response?.message);
        }
      }
      getEmployeeList();
      fetchMasterData(true);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  function generateUniqueId() {
    const usedIds = [];
    let uniqueId = "";
    while (uniqueId.length !== 6) {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      if (!usedIds.includes(randomNumber.toString())) {
        usedIds.push(randomNumber.toString());
        uniqueId += randomNumber.toString();
      }
    }
    return uniqueId;
  }

  const Reset = () => {
    form.resetFields();
    setFileName("");
  };

  const { key, url } = queryKeys.getChargeoutRates;
  const { data: gradeDataRes } = useFetch(key, url);

  const gradeData = gradeDataRes?.response?.items;

  const gradeOptions = gradeData
    ? gradeData?.map((item) => ({
        label: item.jobgradecode,
        value: item.jobgradecode,
        rate: parseInt(item.DRC) || 0,
      }))
    : [];

  const setChargeout = () => {
    const selectedGrade = form.getFieldValue("grade");

    if (selectedGrade) {
      const rate = gradeOptions.find(
        (item) => item.value === selectedGrade
      )?.rate;
      form.setFieldsValue({ chargeoutrate: rate });
      setFormValues((prev) => ({
        ...prev,
        chargeoutrate: rate,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onFinish = (step) => {
    const currentFormValues = form.getFieldValue();

    const updatedFormValues = {
      ...currentFormValues,
      selectedRoleId,
    };

    setFormValues(updatedFormValues);
    setStep(step);
    if (step === 5) {
      setReviewBasicData({
        salutation: formValues?.salutation,
        initial: formValues?.initial,
        firstname: formValues?.firstname,
        lastname: formValues?.lastname,
        employeeid: formValues?.employeeid,
        user_id: formValues?.user_id,
        date_of_birth: formValues?.date_of_birth
          ? moment(formValues?.date_of_birth?._d).format("YYYY-MMMM-DD")
          : "",
        password: formValues?.password
          ? "*".repeat(formValues.password.length - 2) +
            formValues.password.slice(-2)
          : "",
        gender: formValues?.gender,
        phone: formValues?.phone,
        marital_status: formValues?.marital_status,
        nationality: formValues?.nationality,
        nssf: formValues?.nssf,
        national_id: formValues?.national_id,
        address: formValues?.address,
        start_date: formValues?.start_date
          ? moment(formValues?.start_date?._d).format("YYYY-MMMM-DD")
          : "-",
        EmergencyContactName: formValues?.emergencycontactname,
        EmergencyContactNumber: formValues?.emergencycontactnumber,
        employementtype: formValues?.employementtype,
        competencyQC: formValues?.competencyQC,
        serviceline: formValues?.serviceline,
        subserviceline: formValues?.subserviceline,
        competencyrole: formValues?.competencyrole,
        organisationlevel: formValues?.organisationlevel,
        managingoffice: formValues?.managingoffice,
        supervisor: formValues?.supervisor,
        greetings: formValues?.greetings,
        dependent: formValues?.dependent,
      });

      setReviewEducationData({
        institute_Name: formValues?.institute_Name,
        degree: formValues?.degree,
        specialization: formValues?.specialization,
        professionalcertification: formValues?.professionalcertification,
        year_of_completion: formValues?.year_of_completion
          ? moment(formValues?.year_of_completion?._d).format("YYYY-MMMM-DD")
          : "-",
      });
    }
  };

  const uploadFile = async (value) => {
    const { file } = value;
    try {
      if (!isEmpty(file)) {
        const { user_id } = formValues;
        await CALL_API(`upload-userdocuments/${ClientId}`, "post", {
          userId: user_id?.toLowerCase(),
          name: file.name,
          type: file.type,
          uniqueId: file.response.uniqueId,
          content: file.response.base64Data,
        });
      }
    } catch (error) {
      console.error("[handlesubmit] error --->", error);
    }
  };

  const handleFileRemove = async (file) => {
    const userId = form.getFieldValue("user_id");
    let splitUserId = userId.split("@");
    let userIdWithoutDomain = splitUserId[0];
    let updatedUserId = userIdWithoutDomain;
    let fileKey = `${updatedUserId}_${file?.response?.uniqueId}_${file?.name
      .split(" ")
      .join("_")}`;
    try {
      await CALL_API(
        `delete-userdocuments?clientid=${ClientId}&filekey=${fileKey}`,
        "delete"
      );
      console.error(
        "[handleFileRemove] success -->",
        "File deleted successfully"
      );
    } catch (error) {
      console.error("[handleFileRemove] error -->", error);
    }
  };

  useEffect(() => {
    setReviewWorkData({
      company_Name: formValues?.company_Name,
      industry: formValues?.industry,
      secondaryIndustry: formValues?.secondaryIndustry,
      sector: formValues?.sector,
      industryrole: formValues?.industryrole,
      expertise: formValues?.expertise,
      designation: formValues?.designation,
      grade: formValues?.grade,
      targetchargeability: formValues?.targetchargeability,
      chargeoutrate: formValues?.chargeoutrate,
      job_Title: formValues?.job_Title,
      employee_sponser: formValues?.employee_sponser,
      description: formValues?.description,
      relevent: formValues?.relevent,
      professional_since: formValues?.professional_since
        ? moment(formValues?.professional_since?._d).format("YYYY")
        : "",
      hired_date: formValues?.hired_date
        ? moment(formValues?.hired_date?._d).format("YYYY-MMMM-DD")
        : "",
      employee_status: formValues?.employee_status,
      lastpromotionlevel: moment(formValues?.lastpromotionlevel?._d).format(
        "YYYY-MMMM-DD"
      ),
      lastpromotion: formValues?.lastpromotion,
    });
  }, [step]);

  //Note: need to future

  // const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  // useEffect(() => {
  //   console.log('form.getFieldValue("user_id")',form.getFieldValue("user_id"))
  //   if (form.getFieldValue("user_id")) {
  //     acceptedFiles.map((file) => {
  //       const reader = new FileReader();
  //       reader.onload = async function (e) {
  //         if (
  //           includes(
  //             [
  //               "image/jpeg",
  //               "image/jpg",
  //               "image/jpeg",
  //               "image/png",
  //               "image/tif",
  //               "image/tiff",
  //               "png",
  //               "jpg",
  //               "tif",
  //             ],
  //             get(file, "type", "")
  //           )
  //         ) {
  //           setFileName(acceptedFiles[0].path);
  //           setProfileUploaded(true);
  //           setUniqueId(generateUniqueId());
  //           let userId = form.getFieldValue("user_id");
  //           let splitUserId = userId.split("@");
  //           let userIdWithoutDomain = splitUserId[0];
  //           let updatedUserId = userIdWithoutDomain + uniqueId;
  //           const { code } = await CALL_API(
  //             `profile-upload/${ClientId}`,
  //             "post",
  //             {
  //               userid: updatedUserId,
  //               filetype: get(file, "type", ""),
  //               content: e.target.result,
  //               filename: uniqueId,
  //               emailid: "",
  //             }
  //           );
  //           if (code === STATUS_CODE.SUCCESS) {
  //             setStoreId(uniqueId);
  //           }
  //         } else {
  //           setProfileUploaded(false);
  //           message.error("Please upload valid image file.");
  //         }
  //       };
  //       reader.readAsDataURL(file);
  //       return file;
  //     });
  //   }
  // }, [acceptedFiles]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      // Check if user ID is present
      const userId = form.getFieldValue("user_id");
      if (!userId) {
        message.error("User ID is required");
        return;
      }

      // Process accepted files
      acceptedFiles.forEach(async (file) => {
        const reader = new FileReader();
        reader.onload = async function (e) {
          if (
            includes(
              [
                "image/jpeg",
                "image/jpg",
                "image/jpeg",
                "image/png",
                "image/tif",
                "image/tiff",
                "png",
                "jpg",
                "tif",
              ],
              get(file, "type", "")
            )
          ) {
            setFileName(file.path);
            setProfileUploaded(true);
            setUniqueId(generateUniqueId());
            const splitUserId = userId.split("@");
            const userIdWithoutDomain = splitUserId[0];
            const updatedUserId = userIdWithoutDomain + uniqueId;
            const { code } = await CALL_API(
              `profile-upload/${ClientId}`,
              "post",
              {
                userid: updatedUserId,
                filetype: get(file, "type", ""),
                content: e.target.result,
                filename: uniqueId,
                emailid: "",
              }
            );
            if (code === STATUS_CODE.SUCCESS) {
              setStoreId(uniqueId);
            }
          } else {
            setProfileUploaded(false);
            message.error("Please upload a valid image file.");
          }
        };
        reader.readAsDataURL(file);
      });
    },
  });

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  //Note:  Email Id Validation required in future
  const validateEmail = (rule, value) => {
    if (value && emailList.includes(value.trim())) {
      return Promise.reject("This Email is already registered");
    }
    return Promise.resolve();
  };

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId) => {
        return (
          <Switch
            size="small"
            checked={selectAllRoleChecked || selectedRoleId.includes(roleId)}
            onChange={(checked) => getRoleId(roleId, checked)}
          />
        );
      },
    },
  ];

  const baseLocationColumn = [
    {
      title: "Select Base Location",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id) => {
        return (
          <Switch
            size="small"
            checked={baseLocation === location_id}
            onChange={(checked) => setBaseLocation(checked ? location_id : "")}
          />
        );
      },
    },
  ];

  const getRoleId = (role_id) => {
    setSelectedRoleId(role_id);
  };

  const handleSelectAllRole = (e) => {
    const checked = e.target.checked;
    setSelectAllRoleChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
  };

  const stepdata = [
    { id: 1, title: "" },
    { id: 2, title: "" },
    { id: 3, title: "" },
    { id: 4, title: "" },
    { id: 5, title: "" },
    { id: 6, title: "" },
  ];

  return (
    <>
      {isEmpty(selectedDetails) && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => closeModal(false)}
          >
            Employee <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className=" pb-0 pt-1">Add Employee</b>
        </>
      )}
      <StepsProgress stepdata={stepdata} title="" step={step} />
      {/* <div className="styled-card mb-5"> */}
      {step === 0 && (
        <StyledModal>
          <Form
            className=""
            form={form}
            name="addemployeeform"
            layout="vertical"
            initialValues={{
              firstname: "",
              lastname: "",
              salutation: "",
              othernames: "",
              dateofbirth: "",
              gender: "",
              dependent: "",
              maritalstatus: "",
              phoneno: "",
              user_id: "",
              postaladdress: "",
              physicaladdress: "",
              password: "",
              country: "",
              greetings: "",
              national_id: "",
              nationality: "",
              workpermitno: "",
              workpermitexpirydate: "",
              emergencycontactname: "",
              emergencycontactnumber: "",
              nextofkinrelationship: "",
              nextofkinpostaladdress: "",
            }}
            onFinish={() => onFinish(1)}
          >
            <b className="my-2 mx-1">Personal Information:</b>
            <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="firstname"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "First name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="First name"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="lastname"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Last name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Last name"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="salutation"
                  label="Salutation"
                  placeholder="Select Salutation"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Salutation is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Salutation"
                    getPopupContainer={() => containerRef.current}
                  >
                    <Option value="Mr.">Mr.</Option>
                    <Option value="Dr.">Dr.</Option>
                    <Option value="Prof.">Prof.</Option>
                    <Option value="Ms.">Ms.</Option>
                    <Option value="Mrs.">Mrs.</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="othernames"
                  label="Other Names"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Other names is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Other name"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Date of Birth"
                  name="dateofbirth"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Date of Birth is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    placeholder="Select Date of Birth"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Gender is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select gender"
                    getPopupContainer={() => containerRef.current}
                  >
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="They/ Them">They/ Them</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item name="dependent" label="No. of Dependants">
                  <Input
                    placeholder="No. of Dependants"
                    maxLength={10}
                    onKeyDown={(e) => {
                      const value = /^[0-9+]$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="maritalstatus"
                  label="Marital Status"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                  >
                    <Option value="single">Single</Option>
                    <Option value="married">Married</Option>
                    <Option value="widowed">Widowed</Option>
                    <Option value="divorced">Divorced</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item name="phoneno" label="Mobile Number">
                  <PhoneInput
                    placeholder="Mobile Number"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value = /^[0-9+]$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="user_id"
                  label="Email address"
                  rules={[
                    {
                      required: true,
                      pattern: EMAIL_REGEX,
                      message: "Email ID is required",
                    },
                    { validator: validateEmployeeId },
                  ]}
                >
                  <Input
                    placeholder="Email ID"
                    maxLength={60}
                    disabled={!isEmpty(selectedDetails)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="postaladdress"
                  label="Postal addres"
                  rules={[
                    {
                      required: false,
                      message: "Postal addres is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Postal addres"
                    maxLength={60}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9,/]$/.test(e.key) ||
                        /^[a-zA-Z\s,/]+$/.test(e.key);
                      if (!value && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="physicaladdress"
                  label="Physical address"
                  rules={[
                    {
                      required: false,
                      message: "Physical Physical Address is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Physical address"
                    maxLength={60}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9,/]$/.test(e.key) ||
                        /^[a-zA-Z\s,/]+$/.test(e.key);
                      if (!value && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      validator: (_, value) => {
                        if (value && value.length < 8) {
                          return Promise.reject(
                            "Password should be min 8 to max 15 characters."
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    autoComplete="new-password"
                    maxLength={15}
                  />
                </Form.Item>
              </Col> */}
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[{ required: false, message: "Country is required" }]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                  >
                    {countryList?.map((country, index) => (
                      <Select.Option
                        key={index}
                        value={country}
                        label={country}
                      >
                        {country}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="greetings"
                  label="Greetings"
                  rules={[
                    { required: false, message: "Greetings is required" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                  >
                    {Greetings?.map((value, index) => (
                      <Select.Option key={index} value={value} label={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="national_id"
                  label="National ID/Passport Number"
                >
                  <Input
                    placeholder="National ID/Passport Number"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="nationality"
                  label="Nationality"
                  rules={[
                    { required: false, message: "Nationality is required" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    getPopupContainer={() => containerRef.current}
                  >
                    {countryList?.map((country, index) => (
                      <Select.Option
                        key={index}
                        value={country}
                        label={country}
                      >
                        {country}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item name="workpermitno" label="Work Permit No:">
                  <Input
                    placeholder="Work Permit No:"
                    type="text"
                    maxLength={10}
                    onKeyDown={(e) => {
                      const value = /^[0-9]$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Work permit expiry date"
                  name="workpermitexpirydate"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Work permit expiry date is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    placeholder="Select Work permit expiry date"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>

              {/* <Col xs={24} sm={24} md={24}>
                <Form.Item name="profile" label="Profile Pic">
                  <StyledDropzone {...getRootProps()}>
                    <input {...getInputProps()} disabled />
                    <p>
                      {filename !== "" ? <>{filename}</> : <> choose file</>}
                    </p>
                  </StyledDropzone>
                </Form.Item>
              </Col> */}
            </StyleRow>
            <b className="my-2 mx-1">Next Of Kin Details:</b>
            <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="emergencycontactname"
                  label="Emergency Contact Name"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Emergency Contact Name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Emergency Contact Name"
                    maxLength={30}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="emergencycontactnumber"
                  label="Emergency Contact Number"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Emergency Contact Number is required",
                    },
                  ]}
                >
                  <PhoneInput
                    placeholder="Emergency Contact Number"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value = /^[0-9+]$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nextofkinrelationship"
                  label="Relationship"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Relationship is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Relationship"
                    maxLength={15}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nextofkinpostaladdress"
                  label="Postal addres"
                  rules={[
                    {
                      required: false,
                      message: "Postal addres is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Postal addres"
                    maxLength={60}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9,/]$/.test(e.key) ||
                        /^[a-zA-Z\s,/]+$/.test(e.key);
                      if (!value && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </StyleRow>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                Next
              </StyledButton>
            </div>
          </Form>
        </StyledModal>
      )}
      {/* {step === 1 && (
        <>
          {roleList.length === 0 && (
            <StyledButton className="mt-2" onClick={() => setOpenModal(true)}>
              Add Role
            </StyledButton>
          )}
          {roleList?.length > 0 && (
            <Checkbox
              className="mt-3"
              checked={selectAllRoleChecked}
              onChange={handleSelectAllRole}
            >
              Select All
            </Checkbox>
          )}
          <SelectTable
            step={step}
            setStep={setStep}
            columns={rolecolumns}
            tabledata={roleList}
            editRole="edit"
            selectedRoleId={selectedRoleId}
          />
          <FormModal
            isModalVisible={openModal}
            closeModal={() => setOpenModal(false)}
            Name="Role"
          />
        </>
      )}
      {step === 2 && (
        <SelectTable
          step={step}
          setStep={setStep}
          columns={baseLocationColumn}
          tabledata={locationBasedOnRole}
          editRole="edit"
          selectedBaseLocation={baseLocation}
        />
      )} */}
      {step === 1 && (
        <>
          <StyledModal>
            <Form
              form={form}
              name="dynamic_form_nest_item"
              onFinish={() => onFinish(2)}
              autoComplete="off"
              initialValues={{
                educationdetails: [
                  {
                    institutename: "",
                    fromdate: "",
                    todate: "",
                    exambody: "",
                    awarded: "",
                    attachment: [],
                  },
                ],
                practicaltrainings: [
                  {
                    organisationname: "",
                    fromdate: "",
                    todate: "",
                    positionheld: "",
                    tasksperformed: "",
                  },
                ],
                professionalcertifications: [
                  {
                    certificationtype: "",
                    fromdate: "",
                    todate: "",
                    remarks: "",
                    attachment: [],
                  },
                ],
              }}
              labelCol={{ xs: 24, sm: 24, md: 24 }}
              style={{ textAlign: "left" }}
            >
              <b>Education Details:</b>
              <Form.List name="educationdetails">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <StyleRow gutter={16}>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Institute Name"
                            name={[name, "institutename"]}
                            rules={[
                              {
                                required: false,
                                message: "Institute Name required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Institute Name"
                              // onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="From:"
                            name={[name, "fromdate"]}
                            rules={[
                              { required: false, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="From"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="To:"
                            name={[name, "todate"]}
                            rules={[
                              { required: false, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="To"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Exam Body:"
                            name={[name, "exambody"]}
                            rules={[
                              {
                                required: false,
                                message: "Exam Body required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Exam Body"
                              // onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Awarded:"
                            name={[name, "awarded"]}
                            rules={[
                              { required: false, message: "Awarded required" },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                            >
                              <Option value="Doctorate">Doctorate</Option>
                              <Option value="Master">Master</Option>
                              <Option value="Bachelor">Bachelor</Option>
                              <Option value="Diploma">Diploma</Option>
                              <Option value="Certificate">Certificate</Option>
                            </Select>
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Attachment"
                            name={[name, "attachment"]}
                            valuePropName="fileList"
                            rules={[
                              {
                                required: false,
                                message: "file is required",
                              },
                            ]}
                            getValueFromEvent={(e) => {
                              if (Array.isArray(e)) {
                                return e;
                              }
                              return e && e.fileList;
                            }}
                          >
                            <Upload
                              customRequest={({ file, onSuccess }) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);

                                reader.onload = () => {
                                  const base64Data =
                                    reader.result.split(",")[1];
                                  const uniqueId = generateUniqueId();
                                  onSuccess({ file, base64Data, uniqueId });
                                };

                                reader.onerror = (error) => {
                                  console.error("File read error:", error);
                                };
                              }}
                              name="logo"
                              // action="/upload.do"
                              onChange={(value) => {
                                //  onFinish();
                                uploadFile(value);
                              }}
                              onRemove={(file) => {
                                handleFileRemove(file);
                              }}
                              listType="picture"
                              style={{ width: "100%" }}
                            >
                              <div style={{ width: "100%" }}>
                                <Button
                                  className="w-100 text-start"
                                  size="large"
                                >
                                  Upload File
                                </Button>
                              </div>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </StyleRow>
                    ))}
                    <Form.Item>
                      <u
                        className="bg-transparent border-0 text-start"
                        type="dashed"
                        onClick={() => add()}
                        block
                      >
                        +Add Another
                      </u>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <b>Practical Trainings:</b>
              <Form.List name="practicaltrainings">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <StyleRow gutter={16} className="mt-2">
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Organisation Name"
                            name={[name, "organisationname"]}
                            rules={[
                              {
                                required: false,
                                message: "Organisation Name required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Organisation Name"
                              // onChange={() => {
                              //   // onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="From:"
                            name={[name, "fromdate"]}
                            rules={[
                              { required: false, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   // onFinish();
                              // }}
                              placeholder="From"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="To:"
                            name={[name, "todate"]}
                            rules={[
                              { required: false, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   // onFinish();
                              // }}
                              placeholder="To"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Position Held"
                            name={[name, "positionheld"]}
                            rules={[
                              {
                                required: false,
                                message: "Position Held required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Position Held"
                              // onChange={() => {
                              //   // onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Nature of work & Tasks performed or completed"
                            name={[name, "tasksperformed"]}
                            rules={[
                              {
                                required: false,
                                message:
                                  "Nature of work & Tasks performed or completed required",
                              },
                            ]}
                          >
                            <Input.TextArea
                              placeholder="Nature of work & Tasks performed or completed"
                              // onChange={() => {
                              //   // onFinish();
                              // }}
                            />
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </Col>
                      </StyleRow>
                    ))}
                    <Form.Item>
                      <u
                        className="bg-transparent border-0 text-start"
                        type="dashed"
                        onClick={() => add()}
                        block
                      >
                        +Add Another
                      </u>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <b>Professional Certifications:</b>
              <Form.List name="professionalcertifications">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <StyleRow gutter={16} className="mt-2">
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Certification Type"
                            name={[name, "certificationtype"]}
                            rules={[
                              {
                                required: false,
                                message: "Certification Type required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select"
                              optionFilterProp="children"
                              getPopupContainer={() => containerRef.current}
                              defaultValue={AwardList[0]}
                              // onChange={onFinish}
                            >
                              {AwardList?.map((value, index) => (
                                <Select.Option
                                  key={index}
                                  value={value}
                                  label={value}
                                >
                                  {value}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="From:"
                            name={[name, "fromdate"]}
                            rules={[
                              { required: false, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="From"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="To:"
                            name={[name, "todate"]}
                            rules={[
                              { required: false, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="To"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Remarks"
                            name={[name, "remarks"]}
                            rules={[
                              { required: false, message: "Remarks required" },
                            ]}
                          >
                            <Input
                              placeholder="Remarks"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Attachment"
                            name={[name, "attachment"]}
                            valuePropName="fileList"
                            rules={[
                              {
                                required: false,
                                message: "file is required",
                              },
                            ]}
                            getValueFromEvent={(e) => {
                              if (Array.isArray(e)) {
                                return e;
                              }
                              return e && e.fileList;
                            }}
                          >
                            <Upload
                              customRequest={({ file, onSuccess }) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);

                                reader.onload = () => {
                                  const base64Data =
                                    reader.result.split(",")[1];
                                  const uniqueId = generateUniqueId();
                                  onSuccess({ file, base64Data, uniqueId });
                                };

                                reader.onerror = (error) => {
                                  console.error("File read error:", error);
                                };
                              }}
                              name="logo"
                              // action="/upload.do"
                              onChange={(value) => {
                                //  onFinish();
                                uploadFile(value);
                              }}
                              onRemove={(file) => {
                                handleFileRemove(file);
                              }}
                              listType="picture"
                              style={{ width: "100%" }}
                            >
                              <div style={{ width: "100%" }}>
                                <Button
                                  className="w-100 text-start"
                                  size="large"
                                >
                                  Upload File
                                </Button>
                              </div>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </StyleRow>
                    ))}
                    <Form.Item>
                      <u
                        className="bg-transparent border-0 text-start"
                        type="dashed"
                        onClick={() => add()}
                        block
                      >
                        +Add Another
                      </u>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <div className="text-end">
                <StyledButton type="primary" onClick={() => setStep(step - 1)}>
                  Back
                </StyledButton>
                <StyledButton
                  loading={showLoader}
                  type="primary"
                  htmlType="submit"
                >
                  Next
                </StyledButton>
              </div>
            </Form>
            {/* <Form
                className=""
                form={form}
                name="addEmployeeEducationForm"
                layout="vertical"
                initialValues={{
                  institute_Name: "",
                  degree: [],
                  specialization: "",
                  professionalcertification: [],
                  year_of_completion: "",
                }}
                onFinish={() => onFinish(4)}
              >
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      name="institute_Name"
                      label="Institute Name"
                      rules={[
                        {
                          required: false,
                          message: "Institute Name is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="Institute Name"
                        maxLength={50}
                        onKeyDown={(e) => {
                          const allowedChars = /^[a-zA-Z\s]+$/;
                          if (!allowedChars.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      name="degree"
                      label="Educational Degree & Status"
                      rules={[
                        {
                          required: false,
                          message: "Educational Degree & Status is required",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        mode="multiple"
                        getPopupContainer={() => containerRef.current}
                      >
                        {Degree?.map((value, index) => (
                          <Select.Option
                            key={index}
                            value={value}
                            label={value}
                          >
                            {value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      name="professionalcertification"
                      label="Professional Certification & Status"
                      rules={[
                        {
                          required: false,
                          message:
                            "Professional Certification & Status is required",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        mode="multiple"
                        getPopupContainer={() => containerRef.current}
                      >
                        {ProfessionalCertification?.map((value, index) => (
                          <Select.Option
                            key={index}
                            value={value}
                            label={value}
                          >
                            {value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="specialization"
                      label="Specialization"
                      rules={[
                        {
                          required: false,
                          message: "Specialization is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="Specialization"
                        maxLength={50}
                        onKeyDown={(e) => {
                          const allowedChars = /^[a-zA-Z\s]+$/;
                          if (!allowedChars.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      label="Year of Completion"
                      name="year_of_completion"
                    >
                      <DatePicker
                        className="w-100"
                        onChange={validateDateRange}
                        format="DD-MM-YYYY"
                        placeholder="Select start date"
                        getPopupContainer={() => containerRef.current}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="text-end">
                  <StyledButton
                    type="primary"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </StyledButton>
                  <StyledButton
                    loading={showLoader}
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </StyledButton>
                </div>
              </Form> */}
          </StyledModal>
        </>
      )}
      {step === 2 && (
        <>
          <StyledModal>
            <Form
              className=""
              form={form}
              name="addemployeeidentityform"
              layout="vertical"
              initialValues={{
                workexperience: [
                  {
                    companyname: "",
                    fromdate: "",
                    todate: "",
                    jobtitle: "",
                    relevnt: "",
                    jobdescription: "",
                  },
                ],
              }}
              onFinish={() => onFinish(3)}
            >
              <b>Work Experience:</b>
              <Form.List name="workexperience">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <StyleRow gutter={16} className="mt-2">
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Company name"
                            name={[name, "companyname"]}
                            rules={[
                              {
                                required: false,
                                message: "Company name required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Company name"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Job Title"
                            name={[name, "jobtitle"]}
                            rules={[
                              {
                                required: false,
                                message: "Job Title required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Job Title"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="From:"
                            name={[name, "fromdate"]}
                            rules={[
                              { required: false, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="From"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="To:"
                            name={[name, "todate"]}
                            rules={[
                              { required: false, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="To"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Relevant"
                            name={[name, "relevant"]}
                            rules={[
                              { required: false, message: "Relevant required" },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              // onChange={onFinish}
                            >
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Job Description"
                            name={[name, "jobdescription"]}
                            rules={[
                              {
                                required: false,
                                message: "Job Description required",
                              },
                            ]}
                          >
                            <Input.TextArea
                              placeholder="Job Description"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                            />
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </Col>
                      </StyleRow>
                    ))}
                    <Form.Item>
                      <u
                        className="bg-transparent border-0 text-start"
                        type="dashed"
                        onClick={() => add()}
                        block
                      >
                        +Add Another
                      </u>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <div className="text-end">
                <StyledButton type="primary" onClick={() => setStep(step - 1)}>
                  Back
                </StyledButton>
                <StyledButton
                  loading={showLoader}
                  type="primary"
                  htmlType="submit"
                >
                  Next
                </StyledButton>
              </div>
            </Form>
          </StyledModal>
        </>
      )}
      {step === 3 && (
        <Form
          className=""
          form={form}
          name="addemployeeform"
          layout="vertical"
          initialValues={{
            accountname: "",
            accountno: "",
            bankname: "",
            bankbranch: "",
            bankcountry: "",
            bankcode: "",
            bankbranchcode: "",
            bankswiftcode: "",
            nssf: "",
            krapin: "",
            nhif: "",
            helpmonthlybilling: "",
          }}
          onFinish={() => onFinish(4)}
        >
          <b className="mt-2 mx-1">Banking Information</b>
          <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="accountname"
                label="Account Name"
                rules={[
                  {
                    required: false,
                    message: "Account Name is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Account Name"
                  // maxLength={500}
                  onKeyDown={(e) => {
                    const allowedChars = /^[a-zA-Z\s]+$/;
                    if (!allowedChars.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="accountno"
                label="Account Number"
                rules={[
                  {
                    required: false,
                    message: "Account Number is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Account Number"
                  maxLength={25}
                  // onKeyDown={(e) => {
                  //   const allowedChars = /^[a-zA-Z\s]+$/;
                  //   if (!allowedChars.test(e.key)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="bankname"
                label="Bank Name"
                rules={[
                  {
                    required: false,
                    message: "Bank Name is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Bank Name"
                  // maxLength={500}
                  onKeyDown={(e) => {
                    const allowedChars = /^[a-zA-Z\s]+$/;
                    if (!allowedChars.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="bankbranch"
                label="Branch"
                rules={[
                  {
                    required: false,
                    message: "Branch is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Branch"
                  // maxLength={500}
                  onKeyDown={(e) => {
                    const allowedChars = /^[a-zA-Z\s]+$/;
                    if (!allowedChars.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="bankcountry"
                label="Country"
                rules={[{ required: false, message: "Country is required" }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  getPopupContainer={() => containerRef.current}
                >
                  {countryList?.map((country, index) => (
                    <Select.Option key={index} value={country} label={country}>
                      {country}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="bankcode"
                label="Bank code"
                rules={[
                  {
                    required: false,
                    message: "Bank code is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Bank code"
                  maxLength={25}
                  // onKeyDown={(e) => {
                  //   const allowedChars = /^[a-zA-Z\s]+$/;
                  //   if (!allowedChars.test(e.key)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="bankbranchcode"
                label="Branch Code"
                rules={[
                  {
                    required: false,
                    message: "Branch Code is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Branch Code"
                  maxLength={25}
                  // onKeyDown={(e) => {
                  //   const allowedChars = /^[a-zA-Z\s]+$/;
                  //   if (!allowedChars.test(e.key)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="bankswiftcode"
                label="Swiftcode"
                rules={[
                  {
                    required: false,
                    message: "Swiftcode is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Swiftcode"
                  maxLength={25}
                  // onKeyDown={(e) => {
                  //   const allowedChars = /^[a-zA-Z\s]+$/;
                  //   if (!allowedChars.test(e.key)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
              </Form.Item>
            </Col>
          </StyleRow>

          <b className="my-2 mx-1">Banking Information</b>
          <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="krapin" label="KRA PIN Number">
                <Input
                  placeholder="KRA PIN Number"
                  maxLength={20}
                  onKeyDown={(e) => {
                    const value =
                      /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                    if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="nssf" label="NSSF Number">
                <Input
                  placeholder="NSSF Number"
                  maxLength={20}
                  onKeyDown={(e) => {
                    const value =
                      /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                    if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="nhif" label="NHIF Number">
                <Input
                  placeholder="NHIF Number"
                  maxLength={20}
                  onKeyDown={(e) => {
                    const value =
                      /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                    if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="helpmonthlybilling"
                label="HELB monthly billing (KES)"
              >
                <Input
                  placeholder="HELB monthly billing (KES)"
                  maxLength={20}
                  onKeyDown={(e) => {
                    const value =
                      /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                    if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </StyleRow>
          <div className="text-end">
            <StyledButton type="primary" onClick={() => setStep(step - 1)}>
              Back
            </StyledButton>
            <StyledButton loading={showLoader} type="primary" htmlType="submit">
              Next
            </StyledButton>
          </div>
        </Form>
      )}
      {step === 4 && (
        <Form
          className=""
          form={form}
          name="addemployeeform"
          layout="vertical"
          initialValues={{
            managingoffice: [],
            competencyQC: [],
            serviceline: [],
            subserviceline: [],
            leveldepartmentname: [],
            competencyrole: [],
            levellocationid: "",
            oversight: false,
            supervisor: "",
            companyname: "",
            industry: [],
            secondaryIndustry: [],
            sector: [],
            expertise: "",
            industryrole: [],
            regionalsupervisor: "",
            regionalcompetencyrole: "",
            officesupervisor: "",
            engagementsupervisor: "",
            designation: "",
            grade: "",
            targetchargeability: "",
            chargeoutrate: "",
            lastpromotionlevel: "",
            lastpromotion: "",
            jobtitle: "",
            professionalsince: "",
            hireddate: "",
            employeesponser: "",
            employee_status: "",
            // organisationlevel: [],
          }}
          onFinish={() => onFinish(5)}
        >
          <b className="mt-2 mx-1">Level Information:</b>
          <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="managingoffice"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Country is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  mode="multiple"
                  options={managingOfficeData}
                  fieldNames={{
                    label: "value",
                    value: "value",
                  }}
                  getPopupContainer={() => containerRef.current}
                  onChange={() => {
                    setDepartmentLevelData([]);
                    competencyQCfetchData();
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="competencyQC"
                label="Competency/ QC"
                rules={[
                  {
                    required: true,
                    message: "Competency/ QC is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  mode="multiple"
                  getPopupContainer={() => containerRef.current}
                  disabled={competencyQCData?.length == 0}
                  options={competencyQCData}
                  fieldNames={{
                    label: "value",
                    value: "value",
                  }}
                  onChange={() => {
                    setDepartmentLevelData([]);
                    servicelinefetchData();
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="serviceline"
                label="Service Line"
                rules={[
                  { required: true, message: "Service Line is required" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  mode="multiple"
                  getPopupContainer={() => containerRef.current}
                  disabled={
                    servicelineData?.length == 0 ||
                    form.getFieldValue("competencyQC")?.length == 0
                  }
                  options={servicelineData}
                  fieldNames={{
                    label: "value",
                    value: "value",
                  }}
                  onChange={() => {
                    subservicelinefetchData();
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="subserviceline"
                label="Sub-Service Line"
                rules={[
                  {
                    required:
                      subservicelineData && subservicelineData.length > 0,
                    message: "Sub-Service Line is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  mode="multiple"
                  getPopupContainer={() => containerRef.current}
                  disabled={subservicelineData?.length == 0}
                  options={subservicelineData}
                  fieldNames={{
                    label: "value",
                    value: "value",
                  }}
                  onChange={() => {
                    departmentfetchData();
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="leveldepartmentname"
                label="Department"
                rules={[
                  {
                    required: true,
                    message: "Department is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  mode="multiple"
                  getPopupContainer={() => containerRef.current}
                  disabled={departmentLevelData?.length == 0}
                  options={departmentLevelData}
                  fieldNames={{
                    label: "value",
                    value: "value",
                  }}
                  onChange={() => {
                    // competencyrolefetchData();
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="competencyrole"
                label="Office Competency Role"
                rules={[
                  {
                    required: false,
                    message: "Office Competency Role is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  getPopupContainer={() => containerRef.current}
                  // onChange={onFinish}
                >
                  {CompetencyRole?.map((value, index) => (
                    <Select.Option key={index} value={value} label={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="levellocationid"
                label="Location"
                rules={[
                  {
                    required: true,
                    message: "Location is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  getPopupContainer={() => containerRef.current}
                >
                  {filteredLocation?.map((value, index) => (
                    <Select.Option
                      key={index}
                      value={value?.location_id}
                      label={value?.location_name}
                    >
                      {value?.location_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item name="oversight" label="Over Sight">
                <Checkbox
                  checked={formValues.oversight}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      oversight: e.target.checked,
                    });
                  }}
                />
              </Form.Item>
            </Col>

            {/* <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="supervisor"
                label="Supervisor / Manager"
                rules={[
                  {
                    required: false,
                    pattern: NON_EMPTY_REGEX,
                    message: "Supervisor / Manager is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  getPopupContainer={() => containerRef.current}
                  options={supervisorList}
                  fieldNames={{
                    label: "name",
                    value: "employeeId",
                  }}
                  filterOption={(input, option) =>
                    option.name.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col> */}
          </StyleRow>

          <b className="my-2 mx-1">Work Information:</b>
          <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="companyname"
                label="Contracting Company"
                rules={[
                  {
                    required: false,
                    message: "Contracting Company is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  getPopupContainer={() => containerRef.current}
                >
                  {ContractingCompany?.map((value, index) => (
                    <Select.Option key={index} value={value} label={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="industry"
                label="Primary Industry"
                rules={[
                  {
                    required: false,
                    message: "Primary Industry is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  mode="multiple"
                  getPopupContainer={() => containerRef.current}
                >
                  {Industry?.map((value, index) => (
                    <Select.Option key={index} value={value} label={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="secondaryIndustry"
                label="Secondry Industry"
                rules={[
                  {
                    required: false,
                    message: "Secondry Industry is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  mode="multiple"
                  getPopupContainer={() => containerRef.current}
                >
                  {secondaryIndustry?.map((value, index) => (
                    <Select.Option key={index} value={value} label={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="sector"
                label="Sector"
                rules={[
                  {
                    required: false,
                    message: "Sector is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  mode="multiple"
                  getPopupContainer={() => containerRef.current}
                >
                  {Sector?.map((value, index) => (
                    <Select.Option key={index} value={value} label={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="expertise"
                label="Expertise"
                rules={[
                  {
                    required: false,
                    message: "Expertise is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Expertise"
                  // maxLength={500}
                  onKeyDown={(e) => {
                    const allowedChars = /^[a-zA-Z\s]+$/;
                    if (!allowedChars.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="industryrole"
                label="Industry Role"
                rules={[
                  {
                    required: false,
                    message: "Industry Role is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  getPopupContainer={() => containerRef.current}
                >
                  {IndustryRole?.map((value, index) => (
                    <Select.Option key={index} value={value} label={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="regionalsupervisor"
                label="Regional Competency Supervisor"
                rules={[
                  {
                    required: false,
                    message: "Regional Competency Supervisor is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  getPopupContainer={() => containerRef.current}
                  options={supervisorList}
                  fieldNames={{
                    label: "name",
                    value: "employeeId",
                  }}
                  filterOption={(input, option) =>
                    option.name.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="regionalcompetencyrole"
                label="Regional Competency Role"
                rules={[
                  {
                    required: false,
                    message: "Regional Competency Role is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  getPopupContainer={() => containerRef.current}
                >
                  {RegionalCometencyRole.map((value, index) => (
                    <Select.Option key={index} value={value} label={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="officesupervisor"
                label="Office Competency Supervisor"
                rules={[
                  {
                    required: false,
                    message: "Office Competency Supervisor is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  allowClear
                  getPopupContainer={() => containerRef.current}
                  options={supervisorList?.concat([
                    {
                      name: "Null",
                      employeeId: null,
                    },
                  ])}
                  fieldNames={{
                    label: "name",
                    value: "employeeId",
                  }}
                  filterOption={(input, option) =>
                    option.name.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="engagementsupervisor"
                label="Secondary Supervisor"
                rules={[
                  {
                    required: false,
                    message: "Secondary Supervisor is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  getPopupContainer={() => containerRef.current}
                  options={supervisorList}
                  fieldNames={{
                    label: "name",
                    value: "employeeId",
                  }}
                  filterOption={(input, option) =>
                    option.name.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="designation"
                label="Designation"
                rules={[
                  {
                    required: false,
                    message: "Designation is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Designation"
                  maxLength={50}
                  onKeyDown={(e) => {
                    const allowedChars = /^[a-zA-Z0-9\s]+$/;
                    if (!allowedChars.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="grade"
                label="Grade"
                rules={[{ required: true, message: "Grade is required" }]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  getPopupContainer={() => containerRef.current}
                  options={gradeOptions}
                  onChange={() => {
                    setChargeout();
                  }}
                />
                {/* {Grade?.map((value, index) => (
                    <Select.Option key={index} value={value} label={value}>
                      {value}
                    </Select.Option>
                  ))} */}
                {/* </Select> */}
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="chargeoutrate"
                label="Charge Out Rate"
                rules={[
                  {
                    required: false,
                    message: "Charge Out Rate is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Charge Out Rate"
                  // maxLength={50}
                  onKeyDown={allowOnlyNumbers}
                  disabled={true}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="targetchargeability"
                label="Target Chargeability %"
                rules={[
                  {
                    required: false,
                    message: "Target Chargeability % is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Target Chargeability %"
                  maxLength={50}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                label="Last Promotion Date"
                name="lastpromotionlevel"
                rules={[
                  {
                    required: false,
                    pattern: NON_EMPTY_REGEX,
                    message: "Last Promotion Date is required",
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  format="DD-MM-YYYY"
                  placeholder="Select Last Promotion Date"
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="lastpromotion"
                label="Last Promotional Level"
                rules={[
                  {
                    required: false,
                    message: "Last Promotion is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Last Promotion"
                  maxLength={100}
                  // onChange={onFinish}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="jobtitle"
                label="Job Title"
                rules={[
                  {
                    required: false,
                    message: "Job title is required",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Job Title"
                  maxLength={50}
                  onKeyDown={(e) => {
                    const allowedChars = /^[a-zA-Z0-9\s]+$/;
                    if (!allowedChars.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                label="Professional Since"
                name="professionalsince"
                rules={[
                  {
                    required: false,
                    pattern: NON_EMPTY_REGEX,
                    message: "Start date is required",
                  },
                  // () => ({
                  //   // Note : First parameter is Needed
                  //   validator(rule, value) {
                  //     const date = moment(value);
                  //     return date.isValid()
                  //       ? Promise.resolve()
                  //       : Promise.reject();
                  //   },
                  // }),
                ]}
              >
                <DatePicker
                  className="w-100"
                  format="DD-MM-YYYY"
                  onChange={validateDateRange}
                  placeholder="Select start date"
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                label="Hired Date"
                name="hireddate"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Start date is required",
                  },
                  () => ({
                    // Note : First parameter is Needed
                    validator(rule, value) {
                      const date = moment(value);
                      return date.isValid()
                        ? Promise.resolve()
                        : Promise.reject();
                    },
                  }),
                ]}
              >
                <DatePicker
                  className="w-100"
                  format="DD-MM-YYYY"
                  onChange={validateDateRange}
                  placeholder="Select start date"
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="employeesponser"
                label="Employee Sponsor"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "This field is required",
                  },
                ]}
              >
                <Select
                  placeholder="Select"
                  getPopupContainer={() => containerRef.current}
                >
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="employee_status"
                label="Status"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "This field is required",
                  },
                ]}
              >
                <Select
                  placeholder="Select"
                  getPopupContainer={() => containerRef.current}
                  onChange={(value) =>
                    setEmployeeStatusId(value === "active" ? "1" : "0")
                  }
                >
                  <Option value="active">Active</Option>
                  <Option value="in active">In active</Option>
                </Select>
              </Form.Item>
            </Col>
          </StyleRow>
          <div className="text-end">
            <StyledButton type="primary" onClick={() => setStep(step - 1)}>
              Back
            </StyledButton>
            <StyledButton loading={showLoader} type="primary" htmlType="submit">
              Next
            </StyledButton>
          </div>
        </Form>
      )}
      {step === 5 && (
        <Form
          className=""
          form={form}
          name="addemployeeform"
          layout="vertical"
          initialValues={{
            role_id: [],
            baselocationid: "",
            shiftstarttime: "",
            shiftendtime: "",
          }}
          onFinish={() => {
            onFinish(6);
            getSerialNo();
          }}
        >
          <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
            <Col xs={24} sm={24} md={24} ref={containerRef}>
              <Form.Item
                name="role_id"
                label="Select Role:"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "This field is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Search to Select"
                  optionFilterProp="roleName"
                  options={roleList}
                  fieldNames={{
                    label: "roleName",
                    value: "roleId",
                  }}
                  getPopupContainer={() => containerRef.current}
                  onChange={(val) => getRoleId(val)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} ref={containerRef}>
              <Form.Item
                name="baselocationid"
                label="Select Location"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "This field is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="location_name"
                  options={locationBasedOnRole}
                  fieldNames={{
                    label: "location_name",
                    value: "location_id",
                  }}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="shiftstarttime"
                label="Working Hours (From):"
                rules={[
                  {
                    required: false,
                    pattern: NON_EMPTY_REGEX,
                    message: "This field is required",
                  },
                ]}
              >
                {/* <Space.Compact> */}
                {/* <Input style={{ width: '20%', background: "#F1F2F2" }} defaultValue="From" /> */}
                <TimePicker format="HH:mm" />
                {/* </Space.Compact> */}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="shiftendtime"
                label="Working Hours (To):"
                rules={[
                  {
                    required: false,
                    pattern: NON_EMPTY_REGEX,
                    message: "This field is required",
                  },
                ]}
              >
                <TimePicker prefix="To" format="HH:mm" />
              </Form.Item>
            </Col>

           
          </StyleRow>
          <div className="text-end">
            <StyledButton type="primary" onClick={() => setStep(step - 1)}>
              Back
            </StyledButton>
            <StyledButton loading={showLoader} type="primary" htmlType="submit">
              Next
            </StyledButton>
          </div>
        </Form>
      )}
      {step === 6 && (
        <>
          <Form form={form} onFinish={handlesubmit}>
            <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
              <Col xs={24} sm={24} md={4}>
                User ID:
              </Col>
              <Col xs={24} sm={24} md={20}>
                {formValues?.user_id}
              </Col>
              <Col xs={24} sm={24} md={4}>
                Country:
              </Col>
              <Col xs={24} sm={24} md={20}>
                {formValues?.managingoffice}
              </Col>
              <Col xs={24} sm={24} md={4}>
                Employe serial no.:
              </Col>
              <Col xs={24} sm={24} md={20}>
                {serialNo}
              </Col>
              <Col xs={24} sm={24} md={4}>
                Employee ID:
              </Col>
              <Col xs={24} sm={24} md={20}>
                <b className="text-info">{`EA-${formValues?.managingoffice[0]
                  ?.slice(0, 2)
                  ?.toUpperCase()}-${serialNo}`}</b>
              </Col>
              <Col xs={24} sm={24} md={4}>
                Set Password:
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="password"
                  label=""
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      validator: (_, value) => {
                        if (value && value.length < 8) {
                          return Promise.reject(
                            "Password should be min 8 to max 15 characters."
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    autoComplete="new-password"
                    maxLength={15}
                  />
                </Form.Item>
              </Col>
            </StyleRow>
            <div className="text-end">
              <StyledButton
                type="primary"
                onClick={() => {
                  form.resetFields();
                  closeModal(true);
                }}
              >
                Cancel
              </StyledButton>
              <StyledButton type="primary" onClick={() => setStep(step - 1)}>
                Back
              </StyledButton>
              <StyledButton
                loading={showLoader}
                type="primary"
                // onClick={handlesubmit}
                // className="w-30"
                htmlType="submit"
              >
                Create
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {step === 7 && (
        <>
          <InReview
            title="Basic Information"
            setStep={setStep}
            step={step}
            BasicInformation={reviewBasicData}
            role={selectedRoleId}
            // EducationInformation={reviewEducationData}
            baseLocation={baseLocation}
            WorkInformation={reviewWorkData}
            handleCreate={handlesubmit}
            loading={showLoader}
          />
        </>
      )}
      {/* </div> */}
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  allDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  roleList: getRoleList(state),
  userList: getUserList(state),
  bandList: getBandList(state),
  supervisorList: getSupervisorList(state),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(AddEmployee);
