import React, { useEffect, useState, useRef } from "react";
import EmployeeCard from "molecules/EmployeeCard";
import {
  Row,
  Col,
  Button,
  Spin,
  Modal,
  Tabs,
  message,
  Empty,
  Dropdown,
  Pagination,
} from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduce } from "lodash";
import { CSVLink } from "react-csv";
import {
  getUserList,
  getUserRoleId,
  getAllDepartmentList,
  getDepartmentUserList,
  getSelectedDepartmentId,
  getClientid,
  getMenuList,
  getLocations,
  getlevelList,
  getUserInfo,
} from "redux/selectors";
import { setUserList } from "redux/actions";
import styled from "@emotion/styled";
import { CALL_API } from "common/API";
import { map } from "lodash";
import AddEmployee from "organisms/AddEmployee";
import Confirm from "../../src/assets/Confirm.svg";
import { STATUS_CODE } from "common/Constants";
import { bindActionCreators } from "redux";
import { ButtonFilled, ButtonOutlined } from "reusableComponent/UIButtons";
import moment from "moment";
import Organization from "molecules/Organization";
import { ReactComponent as IconLink } from "assets/Component 275 – 1.svg";
import useEmployeeFilter from "hooks/useEmployeeFilter";
import EmployeePerformance from "molecules/EmployeePerformance";

const StyledCol = styled(Col)`
  padding: 10px;
  .ant-card {
    border-radius: 10px !important;
  }
`;
const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
  .ant-row {
    display: flex;
    flex-direction: column;
  }
  .ant-tabs-ink-bar {
    background-color: #000;
  }
  #rc-tabs-1-tab-1:hover,
  #rc-tabs-1-tab-2:hover {
    color: #000;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000 !important;
  }
  .ant-tabs-tab {
    color: #b2beb5;
  }
`;

const HeaderRow = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 8px;

  .copy-icon {
    cursor: pointer;
  }
`;

const StyledContainerRow = styled(Row)`
  padding-top: 20px;
  .loader_container {
    width: 100%;
    text-align: center;
  }
  .noEmployee {
    width: 100%;
    text-align: center;
    color: grey;
    margin-top: 10%;
  }
`;

const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body {
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .page-title-head {
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-body {
    padding: 24px 0px 30px 0px;
  }
  .modal-body {
    justify-content: center;
    margin-top: 10px;
  }
  .ant-modal-close-x {
    color: #151515;
  }
  .modal-body-content {
    justify-content: center;
    font-size: 16px;
    color: #151515;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  width: 125px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;

function Employee({
  ClientId,
  updateUserList,
  menuList,
  userRoleId,
  departmentList,
  userList,
  locationList,
  levelList,
}) {

  const EmployeeList = userList;
  
  const [displayAddEmployeeModal, toggleAddEmployeeModal] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [displayEmployeeLoader, setdisplayEmployeeLoader] = useState(false);
  const [deactivateEmployee, toggledeactivateEmployee] = useState(false);
  const [confirmdeactivateEmployee, toggleconfirmdeactivateEmployee] =
    useState(false);
  const [empId, setEmpId] = useState(0);
  const [employeeCSVlist, setEmployeeCSVlist] = useState([]);
  const [btnLoader, togglebtnLoader] = useState(false);
  const [employeeFilterList, setEmployeeFilterList] = useState([]);
  const [employeeType, setEmployeeType] = useState("1");
  const containerRef = useRef(null);
  const csvLinkEl = useRef();

  const [managingOfficeData, setManagingOfficeData] = useState([]);
  const [competencyQCData, setCompetencyQCfetchData] = useState([]);
  const [servicelineData, setServicelinefetchData] = useState([]);
  const [subservicelineData, setsubservicelineData] = useState([]);
  const [departmentLevelData, setDepartmentLevelData] = useState([]);
  const headerstyle = { fontSize: "20px", fontWeight: "bold" };

  const items1 = [
    {
      key: 1,
      label: "Employee",
      children: <></>,
      id: "employee",
    },
    {
      key: 2,
      label: "Ex Employee",
      children: <></>,
      id: "exemployee",
    },
    {
      key: 3,
      label: "Office Org Chart",
      children: (
        <>
          <br />
          <Organization type="office" />
        </>
      ),
      id: "officechart",
    },
    {
      key: 4,
      label: "Regional Org Chart",
      children: (
        <>
          <br />
          <Organization type="regional" />
        </>
      ),
      id: "regionalchart",
    },
    {
      key: 5,
      label: "Employee Performance",
      children: (
        <>
          <br />
          <EmployeePerformance />
        </>
      ),
      id: "employeeperformance",
    },
  ];

  const CSVHeaders = [
    { label: "Salutation", key: "Salutation" },
    { label: "Initial", key: "Initial" },
    { label: "User Id", key: "User Id" },
    { label: "Name", key: "Name" },
    { label: "Employee Id", key: "Employee Id" },
    { label: "Start Date", key: "Start Date" },
    { label: "Date of Birth", key: "Date of Birth" },
    { label: "Gender", key: "Gender" },
    { label: "Age", key: "Age" },
    { label: "Marital Status", key: "Marital Status" },
    { label: "Greetings", key: "Greetings" },
    { label: "Nationality", key: "Nationality" },
    { label: "No Of Dependent", key: "No Of Dependent" },
    { label: "UAN", key: "UAN" },
    { label: "PAN", key: "PAN" },
    { label: "Address", key: "Address" },
    { label: "Phone No", key: "Phone No" },
    { label: "Country", key: "Country" },
    { label: "Competency/ QC", key: "Competency/ QC" },
    { label: "Service Line", key: "Service Line" },
    { label: "Sub Serviceline", key: "Sub Serviceline" },
    { label: "Competency Role", key: "Competency Role" },
    { label: "Location", key: "Location" },
    { label: "Supervisor / Manager", key: "Supervisor / Manager" },
    { label: "Contracting Company", key: "Contracting Company" },
    { label: "Primary Industry", key: "Primary Industry" },
    { label: "Secondary Industry", key: "Secondary Industry" },
    { label: "Sector", key: "Sector" },
    { label: "Expertise", key: "Expertise" },
    { label: "Industry Role", key: "Industry Role" },
    { label: "Designation", key: "Designation" },
    { label: "Grade", key: "Grade" },
    { label: "Target Chargeability %", key: "Target Chargeability %" },
    { label: "Charge Out Rate", key: "Charge Out Rate" },
    { label: "Last Promotional Level", key: "Last Promotional Level" },
    { label: "Last Promotional Date", key: "Last Promotional Date" },
    { label: "Job Title", key: "Job Title" },
    { label: "Professional Since", key: "Professional Since" },
    { label: "Hired Date", key: "Hired Date" },
    { label: "Relevant", key: "Relevant" },
    { label: "Employee Sponser", key: "Employee Sponser" },
    { label: "Job Description", key: "Job Description" },
    { label: "Emergency Contact Name", key: "Emergency Contact Name" },
    { label: "Emergency Contact Number", key: "Emergency Contact Number" },
    { label: "Regional Supervisor", key: "Regional Supervisor" },
    { label: "Office Supervisor", key: "Office Supervisor" },
    { label: "Engagement Supervisor", key: "Engagement Supervisor" },
  ];

  const prepareCSV = async () => {
    togglebtnLoader(true);
    if (getFilterList()?.length > 0) {
      setEmployeeCSVlist(
        reduce(
          getFilterList(),
          function (result, list) {
            const filterLevelLocationName = locationList?.find(
              (item) => item.location_id === list?.levellocationid
            );
            result.push({
              Salutation: list?.salutation || "",
              Initial: list?.initial || "",
              "User Id": list?.userid || "",
              Name: list?.fullname || "",
              "Employee Id": list?.employeeid || "",
              "Start Date":
                list?.startdate && moment(list.startdate).isValid()
                  ? moment(list?.startdate).format("DD/MM/YYYY")
                  : "-",
              "Date of Birth":
                list?.dateofbirth && moment(list.dateofbirth).isValid()
                  ? moment(list?.dateofbirth).format("DD/MM/YYYY")
                  : "-",
              Gender: list?.gender || "",
              Age: list?.age || "",
              "Marital Status": list?.maritalstatus || "",
              "No Of Dependent": list?.dependent || "",
              Greetings: list?.greetings || "",
              Nationality: list?.nationality || "",
              NSSF: list?.nssf || "",
              "National ID": list?.national_id || "",
              Address: list?.presentaddress || "",
              "Phone No": list?.phoneno || "",
              Country: list?.managingoffice || "",
              "Competency/ QC": list?.competencyQC || "",
              "Service Line": list?.serviceline || "",
              "Sub Serviceline": list?.subserviceline || "",
              Department: list?.leveldepartmentname || "",
              "Competency Role": list?.competencyrole || "",
              "Institute Name": list?.institutename || "",
              Location: filterLevelLocationName?.location_name || "",
              "Supervisor / Manager": list?.supervisor || "",
              "Contracting Company": list?.companyname || "",
              "Primary Industry": list?.industry || "",
              "Secondary Industry": list?.secondaryindustry || "",
              Sector: list?.sector || "",
              Expertise: list?.expertise || "",
              "Industry Role": list?.industryrole || "",
              Designation: list?.designation || "",
              Grade: list?.grade || "",
              "Target Chargeability %": list?.targetchargeability || "",
              "Charge Out Rate": list?.chargeoutrate || "",
              "Last Promotion Date":
                list?.lastpromotionlevel == null ||
                list?.lastpromotionlevel == ""
                  ? ""
                  : moment(list?.lastpromotionlevel).format("DD-MM-YYYY") || "",
              "Last Promotional Level": list?.lastpromotion || "",
              "Job Title": list?.jobtitle || "",
              "Professional Since":
                list?.professionalsince &&
                moment(list.professionalsince).isValid()
                  ? moment(list.professionalsince).format("DD/MM/YYYY")
                  : "-",
              "Hired Date":
                list?.hireddate && moment(list.hireddate).isValid()
                  ? moment(list.hireddate).format("DD/MM/YYYY")
                  : "-",
              Relevant: list?.relevant || "",
              "Employee Sponser": list?.employeesponser || "",
              "Job Description": list?.jobdescription || "",
              "Emergency Contact Name": list?.emergencycontactname || "",
              "Emergency Contact Number": list?.emergencycontactnumber || "",
              "Regional Supervisor": list?.regionalsupervisor || "",
              "Office Supervisor": list?.officesupervisor || "",
              "Engagement Supervisor": list?.engagementsupervisor || "",
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
    togglebtnLoader(false);
  };

  // const STAFF_LIST =
  //   employeeFilterList?.map((val) => {
  //     return {
  //       user_id: val?.userid,
  //       name: val?.fullname,
  //       type: val?.usertype,
  //       department: val?.userdepartmentid,
  //       managingoffice: val?.managingoffice,
  //     };
  //   }) || [];

  const { FilterJSX, getFilterList } = useEmployeeFilter({
    employeeFilterList,
    containerRef,
    managingOfficeData,
    competencyQCData,
    departmentLevelData,
    subservicelineData,
    servicelineData,
    // STAFF_LIST,
    menuName: "employee",
  });

  useEffect(() => {
    let result;
    if (employeeType) {
      result = EmployeeList?.filter((data) => data?.statusid == employeeType);
    }
    setEmployeeFilterList(result);
  }, [selectedDepartment, employeeType, EmployeeList]);


  // const getEmployeeList = async () => {
  //   try {
  //     setdisplayEmployeeLoader(true);
  //     const { response } = await CALL_API(`add-users/${ClientId}`, "get", {});
  //     setEmployeeList(response);
  //     setdisplayEmployeeLoader(false);
  //   } catch (error) {
  //     console.log("[getEmployeeList] Error-->", error);
  //   }
  // };
  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList, levelList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );

    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        let level1_values = [];
        let level2_values = [];
        let level3_values = [];
        let level4_values = [];
        let level5_values = [];

        updateUserList(userList);

        levelList.forEach((level) => {
          switch (level.levelName) {
            case "Level1":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level1_values.push(item);
                });
              });
              break;
            case "Level2":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level2_values.push(item);
                });
              });
              break;
            case "Level3":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level3_values.push(item);
                });
              });
              break;
            case "Level4":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level4_values.push(item);
                });
              });
              break;
            case "Level5":
              level.value.forEach((value) => {
                value.value.forEach((item) => {
                  level5_values.push(item);
                });
              });
              break;
            default:
              break;
          }
        });

        setManagingOfficeData(Array.from(level1_values));
        setCompetencyQCfetchData(Array.from(level2_values));
        setServicelinefetchData(Array.from(level3_values));
        setsubservicelineData(Array.from(level4_values));
        setDepartmentLevelData(Array.from(level5_values));
      }
    }
  };

  const deletedEmployee = (EmpId) => {
    toggledeactivateEmployee(true);
    setEmpId(EmpId);
  };
  const deleteEmployee = async (employee_id) => {
    togglebtnLoader(true);
    await CALL_API(`add-users/${ClientId}`, "delete", {
      user_id: employee_id,
      status_id: "0",
      status_name: "inactive",
    });
    togglebtnLoader(false);
    toggledeactivateEmployee(false);
    toggleconfirmdeactivateEmployee(true);
    fetchMasterData();
    // getEmployeeList();
  };

  const [activeTabKey, setActiveTabKey] = useState(1);

  const handleTabSelect = (key) => {
    if (key === 2) {
      setEmployeeType("0");
    } else {
      setEmployeeType("1");
    }
    setActiveTabKey(key);
  };

  const Menulist =
    menuList[userRoleId] || Object?.values(menuList)[0] || menuList;

  const filteredItems = items1.filter((item) => {
    const permissionObject = Menulist.find(
      (permission) => permission.key === item.id
    );
    return permissionObject ? permissionObject.permission.view : false;
  });

  const filtermenulist =
    Menulist &&
    Menulist?.filter(
      (data) =>
        data.key !== "managerdashboard" &&
        data.key !== "hrdashboard" &&
        data.key !== "teamcalendar" &&
        data.key !== "allcalendar"
    );

  useEffect(() => {
    if (levelList?.length > 0) {
      let level1_values = new Set();
      let level2_values = new Set();
      let level3_values = new Set();
      let level4_values = new Set();
      let level5_values = new Set();

      levelList.forEach((level) => {
        switch (level.levelName) {
          case "Level1":
            level.value.forEach((value) => {
              value.value.forEach((item) => {
                level1_values.add(item);
              });
            });
            break;
          case "Level2":
            level.value.forEach((value) => {
              value.value.forEach((item) => {
                level2_values.add(item);
              });
            });
            break;
          case "Level3":
            level.value.forEach((value) => {
              value.value.forEach((item) => {
                level3_values.add(item);
              });
            });
            break;
          case "Level4":
            level.value.forEach((value) => {
              value.value.forEach((item) => {
                level4_values.add(item);
              });
            });
            break;
          case "Level5":
            level.value.forEach((value) => {
              value.value.forEach((item) => {
                level5_values.add(item);
              });
            });
            break;
          default:
            break;
        }
      });

      setManagingOfficeData(Array.from(level1_values));
      setCompetencyQCfetchData(Array.from(level2_values));
      setServicelinefetchData(Array.from(level3_values));
      setsubservicelineData(Array.from(level4_values));
      setDepartmentLevelData(Array.from(level5_values));
    }
  }, [levelList]);

  const defaultPath =
    filtermenulist?.length > 0 ? filtermenulist[0]?.path : "/";

  const isEmployeeTab = activeTabKey === 1 || activeTabKey === 2;

  const items = [
    {
      key: "1",
      label: (
        <Link
          onClick={() => toggleAddEmployeeModal(true)}
          className="fw-semibold"
        >
          New Employee Form
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="pending-employee" className="fw-semibold">
          Pending Employee
        </Link>
      ),
    },
  ];

  const linkToCopy = "https://onboarding.bdo.insyts.co/";
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch(() => {
        message.error("Failed to copy link");
      });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 50;

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate start and end index based on current page and page size
  const startIndex =
    ((getFilterList().length > 50 ? currentPage : 1) - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // Function to slice the employee list based on current page
  const getDisplayList = () => {
    return getFilterList().slice(startIndex, endIndex);
  };

  useEffect(() => {
    if (filteredItems) {
      setActiveTabKey(filteredItems[0]?.key);
    }
  }, []);

  return (
    <>
      {!displayAddEmployeeModal && (
        <>
          <StyledTitle className="page-title-head">
            <Row>
              <Tabs
                activeKey={activeTabKey}
                items={filteredItems}
                onChange={handleTabSelect}
                indicator={{
                  size: (origin) => origin - 20,
                  align: "center",
                }}
              />
            </Row>
          </StyledTitle>

          {isEmployeeTab && FilterJSX}

          {isEmployeeTab && (
            <>
              <HeaderRow>
                <div className="people_font_style">
                  Total People: {getFilterList().length}
                </div>

                <CSVLink
                  header={CSVHeaders}
                  data={employeeCSVlist}
                  filename={"Employee-list.csv"}
                  asyncOnClick={true}
                  ref={csvLinkEl}
                />
                <ButtonOutlined className="m-0 ms-auto" onClick={prepareCSV}>
                  <span className="add-title">Export CSV File</span>
                </ButtonOutlined>

                {Menulist?.find(
                  (item) => item?.key === "employee" && item?.permission.create
                ) && (
                  <>
                    <IconLink className="copy-icon" onClick={handleCopyClick} />
                    <Dropdown
                      menu={{ items }}
                      placement="bottom"
                      trigger={["hover"]}
                    >
                      <ButtonFilled className="apply-leave-sm m-0">
                        Add People{" "}
                      </ButtonFilled>
                    </Dropdown>
                    {/* <ButtonFilled onClick={() => toggleAddEmployeeModal(true)}>
                    <span>+</span>
                    <span clas sName="add-title">Add People</span>
                  </ButtonFilled> */}
                  </>
                )}
              </HeaderRow>
              <StyledContainerRow gutter={16}>
                {displayEmployeeLoader ? (
                  <div className="loader_container">
                    <Spin spinning={displayEmployeeLoader} />
                  </div>
                ) : (
                  <>
                    {employeeFilterList?.length > 0 ? (
                      <>
                        {map(getDisplayList(), (data, index) => (
                          <>
                            <StyledCol
                              key={index}
                              xs={24}
                              sm={12}
                              md={12}
                              lg={8}
                              xl={6}
                            >
                              <Link
                                to={`/profile-details/${data.userid}/?type=manage`}
                              >
                                <EmployeeCard
                                  id={data.userid}
                                  name={data.fullname}
                                  Department={data.department_name}
                                  clientid={data.clientid}
                                  employeeid={data.employeeid}
                                  data={data}
                                  getEmployeeList={fetchMasterData}
                                  deletedEmployee={deletedEmployee}
                                  profile={data.profileurl}
                                  employeeType={employeeType}
                                  rolename={data.rolename}
                                />
                              </Link>
                            </StyledCol>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <p className="noEmployee">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </p>
                      </>
                    )}
                  </>
                )}
              </StyledContainerRow>
              {/* Pagination component */}
              {getFilterList().length > pageSize && (
                <Pagination
                  current={getFilterList().length > 50 ? currentPage : 1}
                  total={getFilterList().length}
                  pageSize={pageSize}
                  onChange={handlePageChange}
                  style={{ marginTop: "20px", textAlign: "center" }}
                />
              )}
            </>
          )}
        </>
      )}
      {displayAddEmployeeModal && (
        <AddEmployee
          isModalVisible={displayAddEmployeeModal}
          closeModal={() => toggleAddEmployeeModal(false)}
          getEmployeeList={fetchMasterData}
          setSelectedDepartment={setSelectedDepartment}
          EmployeeList={EmployeeList}
        />
      )}
      <StyleddeactivateModal
        title="Are you sure you want to terminate this Employee?"
        visible={deactivateEmployee}
        width={500}
        onCancel={() => toggledeactivateEmployee(false)}
        footer={[]}
        centered
      >
        <Row className="page-title-head" gutter={16}>
          <Col>
            <StyledButton
              type="primary"
              htmlType="submit"
              onClick={() => toggledeactivateEmployee(false)}
            >
              No
            </StyledButton>
          </Col>
          <Col>
            <StyledButton
              loading={btnLoader}
              type="primary"
              htmlType="submit"
              onClick={() => deleteEmployee(empId)}
            >
              {btnLoader ? "Yes" : "Yes"}
            </StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>
      <StyledModal
        visible={confirmdeactivateEmployee}
        width={500}
        onCancel={() => toggleconfirmdeactivateEmployee(false)}
        footer={[]}
        centered
      >
        <Row className="modal-body">
          <Col>
            <img src={Confirm} alt="confirm" height={45} width={45} />
          </Col>
        </Row>
        <Row className="modal-body-content">
          <Col>
            <p>This Employee has been terminated</p>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
}
const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  departmentUserList: getDepartmentUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  menuList: getMenuList(state),
  userInfo: getUserInfo(state),
  departmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  levelList: getlevelList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(Employee);
