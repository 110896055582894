import React, { useEffect, useMemo, useRef } from "react";
import {
  Select,
  Table,
  Input,
  Form,
  Spin,
  Col,
  Row,
  Button,
  Modal,
  Checkbox,
  DatePicker,
  Upload,
  notification,
} from "antd";
import styled from "styled-components";
import moment from "moment";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import { findWeekId } from "utils/Date";
import { useSelector } from "react-redux";
import { getUserInfo } from "redux/selectors";
import { useQueryState } from "hooks/useQueryState";
import useMultipleFetch from "hooks/useMultipleFetch";
import { sumBy } from "lodash";
// import SaveIcon from "@mui/icons-material/Save";
import { ReactComponent as ClearIcon } from "assets/clear.svg";
import { ReactComponent as SaveIcon } from "assets/save.svg";
import CloseIcon from "@mui/icons-material/Close";
import {
  DRAFT,
  MAX_WORKING_HOURS,
  MIN_WORKING_HOURS,
  TimeSheetStatus,
  TOTAL_WORKING_HOURS,
  WITHDRAWN,
} from "pages/TimeSheet";
import ApproveActionButton from "pages/TimeSheet/ApproveActionButton";
import DeclineTimesheetModal from "pages/TimeSheet/DeclineTimesheetModal";
import COLORS from "common/Colors";
import { initialClaim } from "pages/TimeSheet/InitialTimeSheetData";
import { CloseFullscreenOutlined, UploadOutlined } from "@mui/icons-material";
import { useMutate } from "hooks/useMutate";
import { allowOnlyNumbers, fileToBase64 } from "utils/functions";
import BillingResourceSummary from "pages/TimeSheet/BillingResourceSummary";
import { uniqBy } from "lodash";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const DayRender = (day, date, month) => {
  return (
    <div>
      <div className="timesheet-day">{day}</div>
      <div className="timesheet-date">
        {date} {month}
      </div>
    </div>
  );
};

const TimeSheet = ({
  editable,
  data,
  approval = false,
  extraActions,
  employeeId = false,
}) => {
  const monday = moment(data?.[0]?.currentWeek, "YYYY-MM-DD");
  const sunday = moment(data?.[0]?.currentWeek, "YYYY-MM-DD").add(6, "days");

  const weekId = findWeekId(monday, sunday);
  const userInfo = useSelector(getUserInfo);

  const { key, url } = queryKeys.getResourePlanningList(
    findWeekId(monday, sunday)
  );

  const { data: resourcesData } = useFetch(
    key,
    url,
    {
      enabled: true,
    },
    {
      startDate: new Date(monday),
      endDate: new Date(sunday),
      employeeIds: employeeId ? [employeeId] : [userInfo?.userid],
      engagementIds: [],
      weekId: [weekId],
    }
  );

  const resources = resourcesData?.response?.response;

  const uniqueEngagmentIDs = [
    ...new Set(resources?.map((resource) => resource.engagementId)),
  ];

  const engagementResult = useMultipleFetch(
    uniqueEngagmentIDs?.length > 0
      ? uniqueEngagmentIDs?.map((engagementId) => {
          const { key, url } = queryKeys.getEngagementById(engagementId);

          return {
            queryKey: key,
            url,
          };
        })
      : []
  );

  const unqiueClientIDs = [
    ...new Set(
      engagementResult
        .filter(
          (item) =>
            item?.status === "success" &&
            item?.data &&
            item?.data?.data?.financial_resourcePlan?.pfaStatus !==
              "Not Approved"
        )
        .map((item) => item?.data?.data?.clientId)
    ),
  ];

  const results = useMultipleFetch(
    unqiueClientIDs?.length > 0
      ? unqiueClientIDs?.map((clientId) => {
          const { key, url } = queryKeys.getClient(clientId);

          return {
            queryKey: key,
            url,
          };
        })
      : []
  );

  const clientOptions = results?.map((result) => {
    return {
      label: result?.data?.data[0]?.registeredName,
      value: result?.data?.data[0]?.clientId,
      isLoaded: result?.isFetched,
    };
  });

  const engagements = resources?.map((resource) => {
    return {
      clientId: resource.clientId,
      engagementId: resource.engagementId,
      name: resource.engagementName,
    };
  });

  const containerRef = useRef(null);

  const { columns, dataSource } = useTimeSheetDetails(
    data,
    editable,
    approval,
    clientOptions,
    engagements,
    extraActions,
    containerRef
  );

  return (
    <TimeSheetWrap ref={containerRef}>
      <TimeSheetDetailsTable
        columns={columns}
        dataSource={dataSource}
        expandable={{
          expandedRowRender: (record) => (
            <div className="extra-section">{record.extraSection}</div>
          ),
          // defaultExpandAllRows: true,
          // rowExpandable: (record) => true,
          showExpandColumn: false,
          expandedRowKeys: [0, 1, 2, 3, 4, 5, 6],
        }}
        bordered={true}
        pagination={false}
        scroll={{
          y: 400,
          x: "max-content",
        }}
      />
    </TimeSheetWrap>
  );
};

export default TimeSheet;

const ClientRender = ({
  editable,
  name,
  clientOptions,
  extraActions,
  containerRef,
}) => {
  const isSaturdayORSunday = name[1] === "sat" || name[1] === "sun";
  const form = Form.useFormInstance();

  const { handleRemoveClaim } = extraActions || {};

  const clientName = Form.useWatch([name[0], "client"], form);
  const engagementId = Form.useWatch([name[0], "engagement"], form);

  const [showModal, setShowModal] = React.useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowClientDetails = () => {
    //? Not Approved to show client details for now.
    //     if (clientName === "nc") {
    //       return;
    //     }
    //
    //     openModal();
  };

  const { key, url } = queryKeys.getEngagementById(engagementId);

  const { isFetched } = useQueryState(key);

  const { data: engagementDataRes } = useFetch(key, url, {
    enabled: !isFetched && !!engagementId,
  });

  const engagementData = engagementDataRes?.data;

  const selectedClient = clientName
    ? clientOptions?.find((client) => client?.value === clientName)
    : null;

    const isLoaded = selectedClient
    ? selectedClient?.isLoaded
    : editable
    ? true
    : false;

  useEffect(() => {
    if (clientName === "nc") {
      form.setFieldsValue({
        [name[0]]: {
          engagement: "nc",
        },
      });
    }

    if (clientName === "ap") {
      form.setFieldsValue({
        [name[0]]: {
          engagement: "ap",
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientName]);

  return (
    <>
      {/* {!editable ? (
        <>
          <span className="client-name" onClick={handleShowClientDetails}>
            {clientName}
          </span>

          <BillingResourceSummary
            show={showModal}
            onClose={handleCloseModal}
            engagementData={engagementData}
            clientName={clientName}
          />
        </>
      ) : ( */}
      <Spin size="small" spinning={!isLoaded}>
        <Form.Item
          name={name}
          rules={[
            {
              required: !isSaturdayORSunday,
              message: "Required",
            },
          ]}
        >
          <Select
            placeholder="Select"
            allowClear={true}
            style={{ flex: 1, width: "100%" }}
            getPopupContainer={() => containerRef.current}
            options={clientOptions?.map((client) => {
              return {
                label: client.isLoaded ? (
                  client.label
                ) : (
                  <>
                    <Spin /> Loading{" "}
                  </>
                ),
                value: client.value,
              };
            })}
            value={selectedClient?.value || undefined}
            onChange={(value) => {
              if (value === "nc" || value === "ap") {
                if (handleRemoveClaim) {
                  handleRemoveClaim(name[0]);
                }
              }
              form.setFieldValue([name[0], "engagement"], null);
            }}
            disabled={!editable}
          />
        </Form.Item>
      </Spin>
      {/* )} */}
    </>
  );
};

const EngagementRender = ({ editable, name, engagements, containerRef }) => {
  const isSaturdayORSunday = name[1] === "sat" || name[1] === "sun";

  const form = Form.useFormInstance();

  const client = Form.useWatch([name[0], "client"], form);

  const engagementId = Form.useWatch([name[0], "engagement"], form);

  const isClientSelected = !!client;

  let engagementOptions = engagements
    ?.filter((engagement) => {
      return engagement.clientId === client;
    })
    ?.map((engagement) => ({
      label: engagement.name,
      value: engagement.engagementId,
    }));

  if (client === "nc") {
    engagementOptions = [
      {
        label: "Non-Chargeable",
        value: "nc",
      },
    ];
  }

  if (client === "ap") {
    engagementOptions = [
      {
        label: "Approved Absence",
        value: "ap",
      },
    ];
  }

  engagementOptions = uniqBy(engagementOptions, "value");

  const selectedEngagement = engagementId
    ? engagementOptions?.find(
        (engagement) => engagement?.value === engagementId
      )
    : null;

  return (
    <Form.Item
      name={name}
      rules={[
        {
          required: !isSaturdayORSunday,
          message: "Required",
        },
      ]}
    >
      <Select
        placeholder="Select"
        allowClear={true}
        style={{ flex: 1, width: "100%" }}
        options={engagementOptions}
        value={selectedEngagement?.value || undefined}
        disabled={!editable || !isClientSelected}
        getPopupContainer={() => containerRef.current}
      />
    </Form.Item>
  );
};

const TaskRender = ({ editable, name, containerRef }) => {
  const form = Form.useFormInstance();

  const NCTaskOptions = [
    {
      label: "Accounting, Invoicing & Debtors",
      value: "Accounting, Invoicing & Debtors",
    },
    {
      label: "Administration Services",
      value: "Administration Services",
    },
    {
      label: "Administrative",
      value: "Administrative",
    },
    {
      label: "Benched Time",
      value: "Benched Time",
    },
    {
      label: "Business Development",
      value: "Business Development",
    },
    {
      label: "Business Travel",
      value: "Business Travel",
    },
    {
      label: "HR Activities & Events",
      value: "HR Activities & Events",
    },
    {
      label: "HR Operations",
      value: "HR Operations",
    },
    {
      label: "Independence and COI Checks",
      value: "Independence and COI Checks",
    },
    {
      label: "Internal Sharing",
      value: "Internal Sharing",
    },
    {
      label: "Local & Regional Reporting",
      value: "Local & Regional Reporting",
    },
    {
      label: "Meeting",
      value: "Meeting",
    },
    {
      label: "Office Non Chargeable",
      value: "Office Non Chargeable",
    },
    {
      label: "Others - Explain in Remark",
      value: "Others - Explain in Remark",
    },
    {
      label: "Proposal Writing",
      value: "Proposal Writing",
    },
    {
      label: "Timesheets & Claims Reviews",
      value: "Timesheets & Claims Reviews",
    },
    {
      label: "Training External",
      value: "Training External",
    },
    {
      label: "Training internal",
      value: "Training internal",
    },
  ];

  const COptions = [
    {
      label: "Planning",
      value: "Planning",
    },
    {
      label: "Execution",
      value: "Execution",
    },
    {
      label: "Reporting",
      value: "Reporting",
    },
    {
      label: "Follow up & Closure",
      value: "Follow up & Closure",
    },
  ];

  const client = Form.useWatch([name[0], "client"], form);

  return (
    <Form.Item
      name={name}
      rules={[
        {
          required: true,
          message: "Required",
        },
      ]}
    >
      <Select
        placeholder="Select Task"
        style={{ flex: 1, width: "100%" }}
        getPopupContainer={() => containerRef.current}
        allowClear={true}
        options={client === "nc" ? NCTaskOptions : COptions}
        disabled={!editable}
      />
    </Form.Item>
  );
};

const BudgetRender = ({ name }) => {
  const form = Form.useFormInstance();

  const engagementId = Form.useWatch([name[0], "engagement"], form);
  const budget = Form.useWatch([name[0], "budget"], form);

  const userInfo = useSelector(getUserInfo);

  const { key, url } = queryKeys.getEngagementById(engagementId);

  const { isFetched } = useQueryState(key);

  const { data: engagementDataRes, isLoading: isEngagmentLoading } = useFetch(
    key,
    url,
    {
      enabled: !isFetched && !!engagementId && !budget,
    }
  );

  const engagementData = engagementDataRes?.data;

  useEffect(() => {
    if (engagementData) {
      const totalEmployees = engagementData?.employeeList ?? [];

      const currentResource = totalEmployees.find(
        (employee) => employee?.userId === userInfo?.userid
      );

      // const total = sumBy(totalEmployees, "personDay");
      // const total = totalEmployees.reduce((acc, curr) => {
      //   return acc + parseInt(curr.personDay);
      // }, 0);

      form.setFieldsValue({
        [name[0]]: {
          budget:
            (parseFloat(currentResource?.personDay) || 0) * TOTAL_WORKING_HOURS,
        },
      });
    }
  }, [form, engagementData, name, userInfo]);

  return (
    <Spin spinning={isEngagmentLoading}>
      <Form.Item name={name}>
        <Input disabled placeholder="-" type="number" />
      </Form.Item>
    </Spin>
  );
};

const ActualToDateRender = ({ name, extraActions }) => {
  const form = Form.useFormInstance();

  const engagementId = Form.useWatch([name[0], "engagement"], form);
  const actualToDate = Form.useWatch([name[0], "actual_to_date"], form);

  const { key, url } = queryKeys.getEngagementById(engagementId);

  const { isFetched } = useQueryState(key);

  const { data: engagementDataRes, isLoading: isEngagmentLoading } = useFetch(
    key,
    url,
    {
      enabled: !isFetched && !!engagementId && !actualToDate,
    }
  );

  const engagementData = engagementDataRes?.data;

  const { handlePersonInCharge } = extraActions || {};

  useEffect(() => {
    if (engagementData) {
      if (handlePersonInCharge)
        handlePersonInCharge(
          name[0],
          engagementData?.stakeHolder?.personIncharge
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementData, handlePersonInCharge]);

  useEffect(() => {
    if (engagementData) {
      form.setFieldsValue({
        [name[0]]: {
          actual_to_date: engagementData?.actualMandays,
        },
      });
    }
  }, [form, engagementData, name]);

  return (
    <Spin spinning={isEngagmentLoading}>
      <Form.Item name={name}>
        <Input disabled placeholder="-" type="number" />
      </Form.Item>
    </Spin>
  );
};

const ChargeAbleRender = ({ editable, name }) => {
  const form = Form.useFormInstance();

  const saturdayORSunday = name[1] === "sat" || name[1] === "sun";

  const focusNextChargable = () => {
    const nextDay =
      name[1] === "mon"
        ? "tue"
        : name[1] === "tue"
        ? "wed"
        : name[1] === "wed"
        ? "thu"
        : name[1] === "thu"
        ? "fri"
        : name[1] === "fri"
        ? "sat"
        : name[1] === "sat"
        ? "sun"
        : "task";

    form.getFieldInstance([name[0], nextDay]).focus();
  };

  const focusPreviousChargable = () => {
    const previousDay =
      name[1] === "sun"
        ? "sat"
        : name[1] === "sat"
        ? "fri"
        : name[1] === "fri"
        ? "thu"
        : name[1] === "thu"
        ? "wed"
        : name[1] === "wed"
        ? "tue"
        : name[1] === "tue"
        ? "mon"
        : "engagement";

    form.getFieldInstance([name[0], previousDay]).focus();
  };

  const handleChargeAmountChange = (e) => {
    let value = e.target.value;

    // Convert the value to a number to remove leading zeros
    value = Number(value);

    if (value > 12) {
      value = 12;
    }

    form.setFieldsValue({
      [name[0]]: {
        [name[1]]: value,
      },
    });
  };

  return (
    <Form.Item
      name={name}
      rules={[
        {
          // required: isEngagementSelected && !saturdayORSunday,
          required: !saturdayORSunday,
          message: "Required",
        },
      ]}
    >
      <Input
        placeholder="0"
        // disabled={!editable || !isEngagementSelected}
        disabled={!editable}
        onChange={handleChargeAmountChange}
        onKeyDown={(e) => {
          // if pressed tab it should go to next input
          if (e.keyCode === 9 || e.keyCode === 13) {
            // TAB or ENTER
            focusNextChargable();
          }
          // if pressed shift + tab it should go to previous input
          if (e.shiftKey && e.keyCode === 9) {
            // SHIFT + TAB
            focusPreviousChargable();
          }
          allowOnlyNumbers(e);
        }}
        maxLength={2}
      />
    </Form.Item>
  );
};

const claimsColumns = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: 180,
  },
  {
    title: "VAT/NON-VAT",
    dataIndex: "vat",
    key: "vat",
    width: 110,
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: 120,
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
    width: 100,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: 100,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: 120,
  },
  {
    title: "Attachment",
    dataIndex: "attachment",
    key: "attachment",
    width: 120,
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
    width: 160,
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: 70,
  },
];

const ClaimDescriptionRender = ({ claimsIndex, timesheetIndex, editable }) => {
  return (
    <Form.Item
      name={[timesheetIndex, "claims", claimsIndex, "description"]}
      rules={[
        {
          required: true,
          message: "Required",
        },
      ]}
    >
      <Input placeholder="Enter" className="start" disabled={!editable} />
    </Form.Item>
  );
};

const ClaimVatRender = ({ claimsIndex, timesheetIndex, editable }) => {
  const form = Form.useFormInstance();

  // any one of the checkbox should be checked
  const validateVat = () => {
    const vat = form.getFieldValue([
      timesheetIndex,
      "claims",
      claimsIndex,
      "vat",
    ]);
    const nonVat = form.getFieldValue([
      timesheetIndex,
      "claims",
      claimsIndex,
      "non_vat",
    ]);

    if (!vat && !nonVat) {
      return Promise.reject("Required");
    }

    form.setFields([
      {
        name: [timesheetIndex, "claims", claimsIndex, "vat"],
        errors: [],
      },
      {
        name: [timesheetIndex, "claims", claimsIndex, "non_vat"],
        errors: [],
      },
    ]);
    return Promise.resolve();
  };

  return (
    <>
      <Form.Item
        name={[timesheetIndex, "claims", claimsIndex, "vat"]}
        className="mb-0"
        valuePropName="checked"
        rules={[
          {
            validator: validateVat,
          },
        ]}
      >
        <Checkbox
          disabled={!editable}
          onChange={(e) => {
            // if vat is checked then non-vat should be unchecked
            if (e.target.checked) {
              form.setFieldsValue({
                [timesheetIndex]: {
                  claims: {
                    [claimsIndex]: {
                      non_vat: false,
                    },
                  },
                },
              });
            }
          }}
        >
          VAT
        </Checkbox>
      </Form.Item>

      <Form.Item
        name={[timesheetIndex, "claims", claimsIndex, "non_vat"]}
        className="mb-0"
        valuePropName="checked"
        rules={[
          {
            validator: validateVat,
          },
        ]}
      >
        <Checkbox
          disabled={!editable}
          value="non-vat"
          className="ms-0"
          onChange={(e) => {
            // if non-vat is checked then vat should be unchecked
            if (e.target.checked) {
              form.setFieldsValue({
                [timesheetIndex]: {
                  claims: {
                    [claimsIndex]: {
                      vat: false,
                    },
                  },
                },
              });
            }
          }}
        >
          NON-VAT
        </Checkbox>
      </Form.Item>
    </>
  );
};

const ClaimTypeRender = ({ claimsIndex, timesheetIndex, editable }) => {
  const claimTypes = useMemo(
    () => [
      {
        label: "Medical",
        value: "Medical",
      },
      {
        label: "Travel",
        value: "Travel",
      },
      {
        label: "Stationary",
        value: "Stationary",
      },
      {
        label: "Food",
        value: "Food",
      },
      {
        label: "Other",
        value: "Other",
      },
      // {
      //   label: "Disbursement",
      //   value: "Disbursement",
      // },

      {
        label: "Subcontract",
        value: "Subcontract",
      },
      // {
      //   label: "Disbursement",
      //   value: "Disbursement",
      // },
      // {
      //   label: "DNV",
      //   value: "DNV",
      // },
    ],
    []
  );

  return (
    <Form.Item
      name={[timesheetIndex, "claims", claimsIndex, "type"]}
      rules={[
        {
          required: true,
          message: "Required",
        },
      ]}
    >
      <Select
        disabled={!editable}
        placeholder="Select"
        options={claimTypes}
        style={{ flex: 1, width: "100%" }}
      />
    </Form.Item>
  );
};

const ClaimCurrencyRender = ({ claimsIndex, timesheetIndex, currency }) => {
  const form = Form.useFormInstance();

  useEffect(() => {
    if (currency) {
      form.setFieldsValue({
        [timesheetIndex]: {
          claims: {
            [claimsIndex]: {
              currency: currency,
            },
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  return (
    <Form.Item
      name={[timesheetIndex, "claims", claimsIndex, "currency"]}
      rules={[
        {
          required: true,
          message: "Required",
        },
      ]}
    >
      <Input placeholder="Enter" className="start" disabled />
    </Form.Item>
  );
};

const ClaimAmountRender = ({ claimsIndex, timesheetIndex, editable }) => {
  return (
    <Form.Item
      name={[timesheetIndex, "claims", claimsIndex, "amount"]}
      rules={[
        {
          required: true,
          message: "Required",
        },
      ]}
    >
      <Input
        placeholder="0"
        onKeyDown={allowOnlyNumbers}
        disabled={!editable}
      />
    </Form.Item>
  );
};

const ClaimDateRender = ({ claimsIndex, timesheetIndex, editable }) => {
  return (
    <Form.Item
      name={[timesheetIndex, "claims", claimsIndex, "date"]}
      getValueProps={(value) => ({ value: value ? moment(value) : null })}
      rules={[
        {
          required: true,
          message: "Required",
        },
      ]}
    >
      <DatePicker
        placeholder="Select"
        style={{ flex: 1, width: "100%" }}
        format="DD-MM-YYYY"
        disabled={!editable}
      />
    </Form.Item>
  );
};

const AttachmentRender = ({ claimsIndex, timesheetIndex, editable }) => {
  const { key, url } = queryKeys.postClaimsAttachment;

  const { mutate: uploadAttachement } = useMutate(key, url);
  const form = Form.useFormInstance();

  const attachment = Form.useWatch(
    [timesheetIndex, "claims", claimsIndex, "attachment"],
    form
  );

  useEffect(() => {
    if (!attachment) {
      // Clear attachment fields if not populated
      form.setFieldsValue({
        [timesheetIndex]: {
          claims: {
            [claimsIndex]: {
              attachment: [],
            },
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment]);

  const handleUploadAttachement = async (file) => {
    const fileData = file.fileList[0].originFileObj;

    const base64Data = await fileToBase64(fileData);

    const base64DataSplit = base64Data?.split(",")?.[1];

    const data = {
      filename: fileData.name,
      filetype: fileData.type,
      content: base64DataSplit,
    };

    uploadAttachement(data, {
      onSuccess: (res) => {
        form.setFieldsValue({
          [timesheetIndex]: {
            claims: {
              [claimsIndex]: {
                attachment: [
                  {
                    url: res?.fileUrl,
                    name: fileData.name,
                  },
                ],
              },
            },
          },
        });

        notification.success({
          message: "Attachment uploaded successfully",
        });
      },
    });
  };

  const handlePreview = (fileObj) => {
    if (!fileObj.originFileObj) {
      notification.error({
        message: "No file to preview",
      });
      return;
    }

    const file = fileObj.originFileObj;
    const fileUrl = URL.createObjectURL(file);

    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(fileUrl);
  };

  return (
    <Form.Item
      name={[timesheetIndex, "claims", claimsIndex, "attachment"]}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      multiple={false}
    >
      <Upload
        beforeUpload={() => false}
        onChange={(file) => handleUploadAttachement(file)}
        maxCount={1}
        multiple={false}
        accept="image/*,.png,.jpg,.jpeg,.webp,.jpeg"
        showUploadList={{
          showRemoveIcon: false,
        }}
        onPreview={handlePreview}
        disabled={!editable}
      >
        <Button
          style={{
            width: "100%",
          }}
          icon={<UploadOutlined />}
          disabled={!editable}
        >
          Upload
        </Button>
      </Upload>
    </Form.Item>
  );
};

const ClaimRemarksRender = ({ claimsIndex, timesheetIndex, editable }) => {
  return (
    <Form.Item name={[timesheetIndex, "claims", claimsIndex, "remarks"]}>
      <Input placeholder="Enter" className="start" disabled={!editable} />
    </Form.Item>
  );
};

const ClaimActionsRender = ({
  timesheetIndex,
  claimsIndex,
  editable,
  handleClearClaimFn,
}) => {
  return (
    editable && (
      <div
        onClick={() => {
          handleClearClaimFn(timesheetIndex, claimsIndex);
        }}
        style={{
          marginTop: "-16px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ClearIcon />
      </div>
    )
  );
};

const ClaimsRender = ({
  editable,
  index: timesheetIndex,
  claims,
  extraActions,
}) => {
  const [showClaimModal, setShowClaimModal] = React.useState(false);

  const form = Form.useFormInstance();
  const {
    handleAddMoreClaim,
    handleClearClaim,
    handleResetClaim,
    handleRemoveClaim,
  } = extraActions || {};

  const engagementId = Form.useWatch([timesheetIndex, "engagement"], form);

  const { key, url } = queryKeys.getEngagementById(engagementId);

  const { isFetched } = useQueryState(key);

  const { data: engagementDataRes, isLoading: isEngagmentLoading } = useFetch(
    key,
    url,
    {
      enabled:
        !isFetched &&
        !!engagementId &&
        engagementId !== "nc" &&
        engagementId !== "ap",
    }
  );

  const engagementData = engagementDataRes?.data;

  const currency = engagementData?.financial_resourcePlan?.currency;

  const claimsArray =
    Array.isArray(claims) && typeof claims !== "number"
      ? claims
      : [initialClaim];

  const handleClearClaimFn = (timeSheetIndex, claimIndex) => {
    handleClearClaim(timeSheetIndex, claimIndex);

    if (claimIndex === 0) {
      handleRemoveClaim(timeSheetIndex);
      setShowClaimModal(false);
    }
  };

  const claimsData = claimsArray?.map((_, index) => {
    return {
      key: index,
      description: (
        <ClaimDescriptionRender
          claimsIndex={index}
          timesheetIndex={timesheetIndex}
          editable={editable}
        />
      ),
      vat: (
        <ClaimVatRender
          claimsIndex={index}
          timesheetIndex={timesheetIndex}
          editable={editable}
        />
      ),
      type: (
        <ClaimTypeRender
          claimsIndex={index}
          timesheetIndex={timesheetIndex}
          editable={editable}
        />
      ),
      currency: (
        <ClaimCurrencyRender
          claimsIndex={index}
          timesheetIndex={timesheetIndex}
          editable={editable}
          currency={currency}
        />
      ),
      amount: (
        <ClaimAmountRender
          claimsIndex={index}
          timesheetIndex={timesheetIndex}
          editable={editable}
        />
      ),
      date: (
        <ClaimDateRender
          claimsIndex={index}
          timesheetIndex={timesheetIndex}
          editable={editable}
        />
      ),
      attachment: (
        <AttachmentRender
          claimsIndex={index}
          timesheetIndex={timesheetIndex}
          editable={editable}
        />
      ),
      remarks: (
        <ClaimRemarksRender
          claimsIndex={index}
          timesheetIndex={timesheetIndex}
          editable={editable}
        />
      ),
      actions: (
        <ClaimActionsRender
          timesheetIndex={timesheetIndex}
          claimsIndex={index}
          editable={editable}
          handleClearClaimFn={handleClearClaimFn}
        />
      ),
    };
  });

  const hasClaims = claimsArray?.length > 0;

  const handleOpenClaimModal = () => {
    if (editable && !hasClaims) {
      handleAddMoreClaim(timesheetIndex);
    }
    setShowClaimModal(true);
  };

  const handleCloseClaimModal = () => {
    setShowClaimModal(false);
  };

  const handleAddMoreClaimClick = () => {
    handleAddMoreClaim(timesheetIndex);
  };

  const handleSaveClaims = async () => {
    try {
      let hasClaimsError = false;

      for (let index = 0; index < claimsArray.length; index++) {
        const fieldsToValidate = [
          [timesheetIndex, "claims", index, "description"],
          [timesheetIndex, "claims", index, "vat"],
          [timesheetIndex, "claims", index, "non_vat"],
          [timesheetIndex, "claims", index, "type"],
          [timesheetIndex, "claims", index, "currency"],
          [timesheetIndex, "claims", index, "amount"],
          [timesheetIndex, "claims", index, "date"],
        ];

        try {
          await form.validateFields(fieldsToValidate);
        } catch (error) {
          hasClaimsError = true;
          break;
        }
      }

      if (hasClaimsError) {
        return;
      }

      handleCloseClaimModal();
    } catch (error) {
      // Handle form-level validation errors if needed
    }
  };

  const handleResetClaimClick = () => {
    handleResetClaim(timesheetIndex);
  };

  return (
    <>
      <Button
        className="add-claims"
        onClick={handleOpenClaimModal}
        disabled={
          engagementId === "nc" ||
          engagementId === "ap" ||
          engagementId === null ||
          engagementId === undefined
        }
      >
        {!editable ? "View" : "Add"}
      </Button>

      <StyledModal
        open={showClaimModal}
        onCancel={handleCloseClaimModal}
        title={"Add Claims"}
        width={1200}
        footer={null}
        closable={true}
        maskClosable={false}
        forceRender={true}
      >
        <ClaimsTable
          columns={claimsColumns}
          dataSource={claimsData}
          pagination={false}
          bordered={true}
        />

        <div className="mt-2 d-flex justify-content-between align-items-center">
          {editable && (
            <Button variant="text" onClick={handleAddMoreClaimClick}>
              + Add More
            </Button>
          )}
          {editable && (
            <div className="d-flex align-items-center gap-2">
              <Button type="primary" onClick={handleResetClaimClick}>
                Reset
              </Button>
              <Button type="primary" onClick={handleSaveClaims}>
                Save
              </Button>
            </div>
          )}
        </div>
      </StyledModal>
    </>
  );
};

const RemarksRender = ({ editable, name }) => {
  return (
    <Form.Item name={name}>
      <Input
        placeholder="Enter Remarks"
        disabled={!editable}
        className="start"
      />
    </Form.Item>
  );
};

const NCClientRender = ({ editable, name, clientOptions, containerRef }) => {
  let nCClientOptions;

  const form = Form.useFormInstance();

  const client = Form.useWatch([name[0], "client"], form);

  if (client === "nc") {
    nCClientOptions = clientOptions;
  } else {
    nCClientOptions = [
      {
        label: "--",
        value: "-",
        isLoaded: true,
      },
    ];
  }

  const isChargeable = client !== "nc";

  return (
    <Form.Item name={name}>
      <Select
        placeholder="Select NC Client"
        allowClear={true}
        style={{ flex: 1, width: "100%" }}
        options={nCClientOptions?.map((client) => {
          return {
            label: client.isLoaded ? (
              client.label
            ) : (
              <>
                <Spin /> Loading{" "}
              </>
            ),
            value: client.value,
          };
        })}
        getPopupContainer={() => containerRef.current}
        disabled={!editable || isChargeable}
      ></Select>
    </Form.Item>
  );
};

const SubEngagementRender = ({ editable, name }) => {
  return (
    <Form.Item name={name} label="Sub Engagement">
      <Select
        placeholder="Select"
        allowClear={true}
        style={{ flex: 1, width: "100%" }}
        options={[
          {
            label: "--",
            value: "-",
          },
        ]}
        disabled={!editable}
      ></Select>
    </Form.Item>
  );
};

const SumRender = ({ name }) => {
  const form = Form.useFormInstance();

  useEffect(() => {
    const mon = form.getFieldValue([name[0], "mon"]);
    const tue = form.getFieldValue([name[0], "tue"]);
    const wed = form.getFieldValue([name[0], "wed"]);
    const thu = form.getFieldValue([name[0], "thu"]);
    const fri = form.getFieldValue([name[0], "fri"]);
    const sat = form.getFieldValue([name[0], "sat"]);
    const sun = form.getFieldValue([name[0], "sun"]);

    const sum =
      (parseInt(mon) || 0) +
      (parseInt(tue) || 0) +
      (parseInt(wed) || 0) +
      (parseInt(thu) || 0) +
      (parseInt(fri) || 0) +
      (parseInt(sat) || 0) +
      (parseInt(sun) || 0);

    form.setFieldsValue({
      [name[0]]: {
        sum: sum,
      },
    });
  }, [form, name]);

  return (
    <Form.Item name={name}>
      <Input disabled placeholder="0" type="number" />
    </Form.Item>
  );
};

const ExtraSectionRender = ({
  index,
  clientOptions,
  declined,
  editable,
  containerRef,
}) => {
  return (
    <>
      <Row gutter={16} className="mt-2">
        <Col span={7}>
          <TaskRender
            editable={editable}
            name={[index, "task"]}
            containerRef={containerRef}
          />
        </Col>

        <Col span={7}>
          <NCClientRender
            editable={editable}
            name={[index, "ncClient"]}
            clientOptions={clientOptions}
            containerRef={containerRef}
          />
        </Col>

        {/* <Col span={5}>
          <SubEngagementRender
            editable={editable}
            name={[index, "subEngagement"]}
          />
        </Col> */}

        <Col span={10}>
          <RemarksRender editable={editable} name={[index, "remarks"]} />
        </Col>
      </Row>
      {declined && (
        <Row gutter={16}>
          <Col span={24}>
            <div className="declined-reason">
              Note: {declined?.reason || "No reason provided"}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

const TypeRender = ({ index }) => {
  const form = Form.useFormInstance();

  const client = Form.useWatch([index, "client"], form);

  if (!client) {
    return <span></span>;
  } else if (client === "nc") {
    return <span>NC</span>;
  } else if (client === "ap") {
    return <span>AP</span>;
  } else {
    return <span>C</span>;
  }
};

const ActionRender = ({
  editable,
  status,
  index,
  approval,
  extraActions,
  personInCharge,
}) => {
  const userInfo = useSelector(getUserInfo);

  const userId = userInfo?.userid;

  const [showDeclineModal, setShowDeclineModal] = React.useState(false);

  const { handleClearTimesheet, handleSaveTimesheet } = extraActions || {};

  const handleSaveTimesheetClick = () => {
    //? No Validation Needed while saving the timesheet
    handleSaveTimesheet();
  };

  if (status === "declined" || status === "approved") {
    // Skip
  } else if (approval && personInCharge === userId) {
    return (
      <div className="action-buttons">
        <ApproveActionButton index={index} />
        <CloseIcon
          onClick={() => {
            setShowDeclineModal(true);
          }}
          title="Decline Timesheet"
        />

        <DeclineTimesheetModal
          open={showDeclineModal}
          setOpen={setShowDeclineModal}
          index={index}
        />
      </div>
    );
  }

  return editable ? (
    <div className="action">
      <span className="icon-btn" onClick={handleSaveTimesheetClick}>
        <SaveIcon />
      </span>
      <span className="icon-btn" onClick={() => handleClearTimesheet(index)}>
        <ClearIcon />
      </span>
    </div>
  ) : (
    <span>
      <TimeSheetStatus status={status} />
    </span>
  );
};

const TotalRender = ({ total, min, max, isSatOrSun = false }) => {
  const status =
    isSatOrSun && total === 0
      ? "normal"
      : total < min || total > max
      ? "error"
      : "good";

  return (
    <div className={`total ${status}`}>
      <span>{total}</span>
    </div>
  );
};

const useTimeSheetDetails = (
  data,
  editable,
  approval,
  clientOptions,
  engagements,
  extraActions,
  containerRef
) => {
  const currentWeekStartDate = data[0]?.currentWeek;

  const monday = moment(currentWeekStartDate, "YYYY-MM-DD");
  const tuesday = moment(currentWeekStartDate, "YYYY-MM-DD").add(1, "days");
  const wednesday = moment(currentWeekStartDate, "YYYY-MM-DD").add(2, "days");
  const thursday = moment(currentWeekStartDate, "YYYY-MM-DD").add(3, "days");
  const friday = moment(currentWeekStartDate, "YYYY-MM-DD").add(4, "days");
  const saturday = moment(currentWeekStartDate, "YYYY-MM-DD").add(5, "days");
  const sunday = moment(currentWeekStartDate, "YYYY-MM-DD").add(6, "days");

  const form = Form.useFormInstance();

  const values = Form.useWatch([], form) || {};

  const chargeableDays = Object.keys(values).map((key) => {
    return {
      mon: parseInt(values[key]?.mon) || 0,
      tue: parseInt(values[key]?.tue) || 0,
      wed: parseInt(values[key]?.wed) || 0,
      thu: parseInt(values[key]?.thu) || 0,
      fri: parseInt(values[key]?.fri) || 0,
      sat: parseInt(values[key]?.sat) || 0,
      sun: parseInt(values[key]?.sun) || 0,
    };
  });

  const allMondayTotal = sumBy(chargeableDays, (item) => item.mon);
  const allTuesdayTotal = sumBy(chargeableDays, (item) => item.tue);
  const allWednesdayTotal = sumBy(chargeableDays, (item) => item.wed);
  const allThursdayTotal = sumBy(chargeableDays, (item) => item.thu);
  const allFridayTotal = sumBy(chargeableDays, (item) => item.fri);
  const allSaturdayTotal = sumBy(chargeableDays, (item) => item.sat);
  const allSundayTotal = sumBy(chargeableDays, (item) => item.sun);

  const allTotal =
    (parseInt(allMondayTotal) || 0) +
    (parseInt(allTuesdayTotal) || 0) +
    (parseInt(allWednesdayTotal) || 0) +
    (parseInt(allThursdayTotal) || 0) +
    (parseInt(allFridayTotal) || 0) +
    (parseInt(allSaturdayTotal) || 0) +
    (parseInt(allSundayTotal) || 0);

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 50,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      children: [
        {
          title: "Client",
          dataIndex: "client",
          ellipsis: {
            showTitle: true,
          },
          key: "client",
          width: 160,
        },
        {
          title: (
            <div>
              <div>Job /</div>
              <div>Engagement</div>
            </div>
          ),
          dataIndex: "engagement",
          key: "engagement",
          width: 160,
        },
        {
          title: "Budget (hr)",
          dataIndex: "budget",
          key: "budget",
          width: 65,
        },
        {
          title: "Actual to date",
          dataIndex: "actual_to_date",
          key: "actual_to_date",
          width: 65,
        },
      ],
    },
    {
      title: (
        <TotalRender
          total={allMondayTotal}
          min={MIN_WORKING_HOURS}
          max={MAX_WORKING_HOURS}
        />
      ),
      dataIndex: "mondayTotal",
      align: "center",
      key: "mondayTotal",
      children: [
        {
          title: DayRender("Mon", monday.format("D"), monday.format("MMM")),
          dataIndex: "mon",
          key: "mon",
          width: 65,
        },
      ],
    },
    {
      title: (
        <TotalRender
          total={allTuesdayTotal}
          min={MIN_WORKING_HOURS}
          max={MAX_WORKING_HOURS}
        />
      ),
      dataIndex: "tuesdayTotal",
      key: "tuesdayTotal",
      children: [
        {
          title: DayRender("Tue", tuesday.format("D"), tuesday.format("MMM")),
          dataIndex: "tue",
          key: "tue",
          width: 65,
        },
      ],
    },
    {
      title: (
        <TotalRender
          total={allWednesdayTotal}
          min={MIN_WORKING_HOURS}
          max={MAX_WORKING_HOURS}
        />
      ),
      dataIndex: "wednesdayTotal",
      key: "wednesdayTotal",
      children: [
        {
          title: DayRender(
            "Wed",
            wednesday.format("D"),
            wednesday.format("MMM")
          ),
          dataIndex: "wed",
          key: "wed",
          width: 65,
        },
      ],
    },
    {
      title: (
        <TotalRender
          total={allThursdayTotal}
          min={MIN_WORKING_HOURS}
          max={MAX_WORKING_HOURS}
        />
      ),
      dataIndex: "thursdayTotal",
      key: "thursdayTotal",
      children: [
        {
          title: DayRender("Thu", thursday.format("D"), thursday.format("MMM")),
          dataIndex: "thu",
          key: "thu",
          width: 65,
        },
      ],
    },
    {
      title: (
        <TotalRender
          total={allFridayTotal}
          min={MIN_WORKING_HOURS}
          max={MAX_WORKING_HOURS}
        />
      ),
      dataIndex: "fridayTotal",
      key: "fridayTotal",
      children: [
        {
          title: DayRender("Fri", friday.format("D"), friday.format("MMM")),
          dataIndex: "fri",
          key: "fri",
          width: 65,
        },
      ],
    },
    {
      title: (
        <TotalRender
          total={allSaturdayTotal}
          min={MIN_WORKING_HOURS}
          max={MAX_WORKING_HOURS}
          isSatOrSun={true}
        />
      ),
      dataIndex: "saturdayTotal",
      key: "saturdayTotal",
      children: [
        {
          title: DayRender("Sat", saturday.format("D"), saturday.format("MMM")),
          dataIndex: "sat",
          key: "sat",
          width: 65,
        },
      ],
    },
    {
      title: (
        <TotalRender
          total={allSundayTotal}
          min={MIN_WORKING_HOURS}
          max={MAX_WORKING_HOURS}
          isSatOrSun={true}
        />
      ),
      dataIndex: "sundayTotal",
      key: "sundayTotal",
      children: [
        {
          title: DayRender("Sun", sunday.format("D"), sunday.format("MMM")),
          dataIndex: "sun",
          key: "sun",
          width: 65,
        },
      ],
    },
    {
      title: (
        <TotalRender
          total={allTotal}
          min={MIN_WORKING_HOURS * 5}
          max={MAX_WORKING_HOURS * 5}
        />
      ),
      dataIndex: "allTotal",
      key: "allTotal",
      children: [
        {
          title: "Sum",
          dataIndex: "sum",
          key: "sum",
          width: 70,
        },
      ],
    },
    {
      title: "Claims",
      dataIndex: "claims",
      key: "claims",
      width: 70,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 95,
    },
  ];

  const dataSource = data.map((item, index) => {
    const isEditable =
      (editable || item.status === WITHDRAWN || item.status === DRAFT) &&
      item.status !== "approved";

    return {
      key: index,
      type: <TypeRender index={index} />,
      client: (
        <ClientRender
          editable={isEditable}
          name={[index, "client"]}
          clientOptions={[
            {
              label: "NC",
              value: "nc",
              isLoaded: true,
            },
            {
              label: "AP",
              value: "ap",
              isLoaded: true,
            },
            ...clientOptions,
          ]}
          extraActions={extraActions}
          containerRef={containerRef}
        />
      ),
      engagement: (
        <EngagementRender
          editable={isEditable}
          name={[index, "engagement"]}
          engagements={engagements}
          containerRef={containerRef}
        />
      ),
      budget: <BudgetRender name={[index, "budget"]} />,
      actual_to_date: (
        <ActualToDateRender
          name={[index, "actual_to_date"]}
          extraActions={extraActions}
        />
      ),
      mon: <ChargeAbleRender editable={isEditable} name={[index, "mon"]} />,
      tue: <ChargeAbleRender editable={isEditable} name={[index, "tue"]} />,
      wed: <ChargeAbleRender editable={isEditable} name={[index, "wed"]} />,
      thu: <ChargeAbleRender editable={isEditable} name={[index, "thu"]} />,
      fri: <ChargeAbleRender editable={isEditable} name={[index, "fri"]} />,
      sat: <ChargeAbleRender editable={isEditable} name={[index, "sat"]} />,
      sun: <ChargeAbleRender editable={isEditable} name={[index, "sun"]} />,
      sum: <SumRender name={[index, "sum"]} />,
      claims: (
        <ClaimsRender
          editable={isEditable}
          index={index}
          claims={item?.claims}
          extraActions={extraActions}
        />
      ),
      action: (
        <ActionRender
          editable={isEditable}
          status={item.status}
          personInCharge={item.personInCharge}
          index={index}
          approval={approval}
          extraActions={extraActions}
        />
      ),
      extraSection: (
        <ExtraSectionRender
          index={index}
          clientOptions={clientOptions}
          declined={item.status === "declined" ? item.declined : false}
          editable={isEditable}
          containerRef={containerRef}
        />
      ),
    };
  });

  return { dataSource, columns };
};

const TimeSheetDetailsTable = styled(Table)`
  .ant-table-cell.nowrap {
    white-space: nowrap;
  }
  .ant-table-cell.border-right-none {
    border-right: none !important;
  }
  .ant-table-cell.border-bottom-none {
    border-bottom: none;
  }
  .ant-table-cell {
    padding: 6px 10px !important;
    font-size: 12px;
  }
  .ant-table-thead > tr > th {
    overflow-wrap: normal;
  }
  .extra-section {
    margin: 0px;
    padding-bottom: 4px;
  }
  .ant-table-cell:has(.extra-section) {
    border-bottom: 5px solid #f0f0f0;
  }
  .timesheet-day {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
  }
  .timesheet-date {
    font-size: 10px;
    font-weight: 500;
    color: #000000;
    opacity: 0.75;
  }
`;

const ClaimsTable = styled(Table)`
  th.ant-table-cell {
    background-color: #f5f5f5;
  }
  .ant-table-cell {
    padding: 6px 10px !important;
    font-size: 12px;
  }
  .ant-table-thead > tr > th {
    overflow-wrap: normal;
  }
  .ant-table-cell.nowrap {
    white-space: nowrap;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    .anticon-close.ant-modal-close-icon,
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .ant-modal-header {
    border-bottom: none;
    padding-top: 10px;
  }
  .ant-modal-title {
    font-weight: 500;
    text-align: left;
    color: #1b1b1b;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #26a4ff;
    color: #ffffff;
  }
  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #02b209 !important;
      border-color: #02b209 !important;
    }
  }
  .ant-upload-span {
    cursor: pointer;
  }
`;

const TimeSheetWrap = styled.div`
  table {
    box-shadow: 0px 3px 6px #00000015;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-input {
    border-radius: 5px;
    border-color: #d9d9d9;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
  }

  .ant-input.start {
    text-align: start;
    padding-left: 10px;
    padding-right: 10px;
  }

  .ant-form-item-label {
    padding-bottom: 4px !important;
    label {
      padding-bottom: 0px !important;
      font-size: 12px;
      color: #000000;
    }
  }

  .upload-button {
    border-radius: 5px;
    width: 100%;
  }

  .add-claims {
    background-color: ${COLORS.PRIMARY};
    border-radius: 5px;
    border-color: none;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.PRIMARY};
      box-shadow: 0px 3px 6px #00000015;
      border-color: none;
    }
    // disabled
    &:disabled {
      background-color: #d9d9d9;
      cursor: not-allowed;
    }
  }

  .client-name {
    font-size: 12px;
    padding: 0.5rem 1rem;
    margin-right: auto;
    font-weight: 600;
    color: #397ef4;
    cursor: pointer;
  }

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    .icon-btn {
      cursor: pointer;
      color: #000000;
      font-size: 16px;
      &:hover {
        color: #397ef3;
        box-shadow: 0px 3px 6px #00000015;
        border-radius: 50%;
      }
    }
  }

  .declined-reason {
    background-color: #ffe8e8;
    color: #ff4d4f;
    font-weight: 700px;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
  }

  .ant-form-item-explain-error {
    font-size: 10px;
  }

  .total {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    &.error {
      color: #ff4d4f;
    }
    &.good {
      color: #02b209;
    }
  }
`;
